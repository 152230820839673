import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import {
  Container,
  Nav,
  Navbar
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/fontStyles.css";

const FAQNavbar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  useEffect(() => {
    // alert(window.location.pathname);
  }, []);
  return (
    <Navbar
      // expand="lg"
      expand
      className="m-0 p-0 bg-body-light"
    >
      <Container fluid className=" p-0 m-0 px-5 mx-3 ">
        <Navbar.Toggle aria-controls="responsive-navbar-nav  " />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-auto"
            // activeKey={window.location.pathname}
          >
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to={"/faqs/smartvalvefaqs"}
              active={window.location.pathname === "/faqs/smartvalvefaqs"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              The Smart Valve<sup>TM</sup>
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/faqs/smartwatermonitorfaqs"
              active={window.location.pathname ==="/faqs/smartwatermonitorfaqs"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              The Smart Water Monitor<sup>TM</sup>
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/faqs/scalebusterfaqs"
              active={window.location.pathname === "/faqs/scalebusterfaqs"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              The Smart Scale Buster<sup>TM</sup>
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/faqs/watersavingfaqs"
              active={window.location.pathname === "/faqs/watersavingfaqs"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              The Smart Water Saving Program<sup>TM</sup>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default FAQNavbar;
