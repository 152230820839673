import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p64 from "../../assets/images/13. Case Studies - Hospitality - Multifamily .1366x768/64.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";

const Multifamily = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4 text">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "-12px" }
                : { marginLeft: "0px" }
            }
          >
            Multifamily Living & Senior Living Homes
          </h4>
          <Image
            fluid
            src={p64}
            alt="image"
            className={isTabletOrMobile && "p-0 m-0"}
          />
          <Container className={`m-0 p-0 ${isDesktopOrLaptop && "px-3"} `}>
            <Button
              variant="dark"
              size="sm"
              // style={{ marginLeft:isDesktopOrLaptop && "20px" }}
              className=" my-4   "
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Container>
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "-12px" }
                : { marginLeft: "0px" }
            }
          >
            MULTIFAMILY APARTMENT COMPLEXES – USING THE VALVE SMART TECHNOLOGY
          </h4>
          <p
            className="poppins-Regular"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "-12px" } : {}
            }
          >
            Water conservation in multifamily living and senior care facilities
            is an important and often overlooked aspect of sustainable
            healthcare operations. On average, these facilities consume around
            100 gallons of water per bed, in addition to 20 gallons per
            employee. However, this doesn't encompass the full spectrum of water
            use. Many facilities include common areas available for use by
            residents and staff, which adds to the overall consumption. With
            increasing environmental concerns and the rising costs of water and
            energy, senior care facilities have a tremendous opportunity to
            reduce their water usage and lower their operational costs.
          </p>
        </Row>

        <Row xs={"12"} className={"p-0 m-0 "}>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-3" : "p-0"
            }`}
          >
            <dl>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Plumbing Configurations - Introducing The Smart Valve
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                In apartment complexes that are high rise (2-3 stories high),
                relying on city water pressure without auxiliary pumps or
                holding tanks is common. The Valve offers an innovative solution
                to optimize water usage and air management in these setups,
                proving to be more efficient than traditional flow restrictors.
              </dd>

              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Conserving Water and Managing City Backpressure
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                The Smart Valve reduces the flow rate, leading to water
                conservation, while also effectively controlling city back in
                the water. By keeping air bubbles small, the amount of air
                purchased is reduced, which is particularly beneficial in such
                multi-story apartment complexes.
              </dd>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Extending High Zone and Equalizing Pressure
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Despite being installed on the owner's side of the meter, The
                Smart Valve extends the city's high-pressure zone beyond the
                city meter. This extension helps lessen the drop in pressure as
                water flows across the city meter, maintaining a consistent city
                pressure on both sides of the meter. This pressure equalization
                prevents the air in the water from expanding as it passes
                through the meter, effectively ensuring that apartment owners
                pay for water, not air.
              </dd>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                The Smart Valve's Intelligence
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                The name "Smart Valve" is justified by three key features.
                Firstly, it contains a spring that automatically adjusts to
                changes in pressure, adapting to moment-by-moment pressure
                fluctuations across the meter. Secondly, it is a "Really" Smart
                Valve because it can be easily adjusted by apartment managers or
                plumbers, allowing for swift responses to any changes in city
                water pressure. Lastly, The Smart Valve is installed outside the
                pipe, preventing any unnecessary constriction of flow caused by
                valves inside the pipe, which can be a limitation of other
                systems.
              </dd>
            </dl>
            <Button
              variant="dark"
              size="sm"
              // style={{ marginLeft:isDesktopOrLaptop && "20px" }}
              className=" my-2"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-3" : "p-0"
            }`}
          >
            <dl>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                An Additional Benefit - Mitigating Leaks
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Apart from its primary advantages, The Smart Valve can also aid
                in preventing costly water leaks. By controlling pressure
                surges, it reduces the likelihood of sudden bursts or failures
                in connections behind appliances, which could lead to
                significant damage. While it is challenging to quantify the
                exact number of potential leaks prevented, even eliminating just
                one leak can result in substantial savings for both apartment
                managers and tenants.
              </dd>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                UPC (Uniform Plumbing Code) Approved
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Rest assured, The Smart Valve is UPC (Uniform Plumbing Code)
                Approved, complying with industry standards to ensure
                reliability and compliance.
                <br />
                <br /> In conclusion, The Smart Valve offers a smarter and more
                efficient approach to water management in multi-story apartment
                complexes. By conserving water, managing air bubbles, equalizing
                pressure, and preventing potential leaks, it brings numerous
                benefits to both apartment owners and tenants. Its intelligence
                and flexibility make it a valuable addition to any plumbing
                configuration, ensuring optimal performance and savings for
                years to come.
              </dd>
            </dl>
          </Col>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default Multifamily;
