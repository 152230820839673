import React from "react";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import MasterLayout from "../../layouts/MasterLayout";
import { useEffect } from "react";

const SmartWaterMonitorFAQS = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <h1
          class="portfolioTitle poppins-SemiBold text-center"
          style={
            isTabletOrMobile
              ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
              : { marginLeft: "0px" }
          }
        >
          Frequently Asked Questions (FAQ)
        </h1>
        <Container fluid className={`m-0 p-0  mt-5 text ${isDesktopOrLaptop && 'px-5'}`}>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: What is the Smart Water Monitor TM with WaterEyeTM ?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The Smart Water Monitor TM with WaterEye TM is your single source
            for managing and monitoring all of your Water Technologies that are
            installed across your enterprise's plumbing ecosystem.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: What are the key features of the WaterEyeTM Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Get an instant snapshot of your connected plumbing's health on the
            main dashboard or drill deeper to analyze and download reports,
            location by location, product by product.
            <br />
            Customize your system alerts to align with your unique operational
            parameters and communication preferences.
            <br />
            Stay connected no matter where you are at, what you're doing or the
            time of day through our secure cloud-based system.
            <br />
            Invite your staff and external service contracting partners to the
            portal and authorize them to manage the connected products
            applicable to their role.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Who can access the Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Access to the Smart Water Monitor TM with WaterEye TM portal is by
            invitation only. Please contact the system administrator at your
            enterprise for an invitation to join.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: How do I create an account in the Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Once the system administrator at your enterprise has added you as an
            authorized user, the system will send you an invitation via email to
            begin the account creation process.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: What if I've forgotten my username and/or password?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Your username will always be the email address you used to create
            your account. To reset your password, simply click the "Forgot your
            password?" link on the login page.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Is the Smart Water Monitor TM with WaterEye TM secure?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Yes, we have ensured security for your peace of mind. The Smart
            Water Monitor TM with WaterEye TM is hosted securely with Microsoft
            Azure, which also manages the user authentication (Microsoft Azure
            B2C), and Amazon AWS.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Where is the data stored? Is it secure?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Data collected by The Smart Water Monitor TM with WaterEye TM are
            stored and analyzed on Water Solutions’ Technology cloud server,
            which is hosted securely by Microsoft Azure/Amazon AWS.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Can I access the Portal from anywhere?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Yes, you can access the Portal from any internet-capable device from
            any internet-available location.
          </p>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: How much does it cost for me to access the Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Subscription to Smart Water Monitor TM with WaterEye TM is managed
            at the enterprise level. Please contact your Water Solution's
            Technology representative for detailed pricing information.
          </p>
        </Container>
      </Container>
    </MasterLayout>
  );
};
export default SmartWaterMonitorFAQS;
