import React, { useState } from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";
import EventCard from "../../components/events/EventCard";

const Events = () => {
  const [show, setShow] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={`m-0 ${isDesktopOrLaptop && "pageContainer"} `}
      >
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile ? { fontSize: "22px", marginTop: "4%" } : {}
            }
          >
            EVENTS
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={isTabletOrMobile ? { fontSize: "16px" } : {}}
          >
            UPCOMING SOON
          </h3>
        </Row>

        <Row className={` m-0  ${isDesktopOrLaptop ? "px-5" : "px-4"}`}>
          <Col lg={4} md={6} sm={12} xs={12}>
            <EventCard
              title={"The Smart Valves Program for Plumbers"}
              to={"https://www.youtube.com/embed/tgbNymZ7vqY"}
            />
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <EventCard
              title={
                "The Property Owner/Manager Carbon Credit Strategy with Water Technologies"
              }
              to={"https://www.youtube.com/embed/szvt1vD0Uug"}
            />
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <EventCard
              title={" The Smart Valves Program for Plumbers"}
              to={"https://www.youtube.com/embed/tgbNymZ7vqY"}
            />
          </Col>
        </Row>
        <Container>
          <Button
            variant="dark"
            className="poppins-Regular my-5"
            onClick={() => {
              setShow(true);
            }}
          >
            REGISTER TO BE NOTIFIED
          </Button>
        </Container>
      </Container>
      <QuickConnect
        isVisible={show}
        hideModel={() => {
          setShow(false);
        }}
      />
    </MasterLayout>
  );
};

export default Events;
