import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p69 from "../../assets/images/15. Service Station _ Car Washes. 1366x768/69.png";
import p70 from "../../assets/images/15. Service Station _ Car Washes. 1366x768/70.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { caseStudiesAlert } from "../../helper/HelperFunctions";
import { Link } from "react-router-dom";

const ServiceStation = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Service Station | Car Washes
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p69}
              // className="img img-fluid"
              alt="image"
            />
            <Button
              variant="dark"
              // style={{ marginLeft: "40px" }}
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              It takes about, 22,444 gallons  of water to wash ~500 vehicles
              over 1 week, averaging around 43.8 gallons per vehicle. This water
              usage is significant and can be reduced by 25% using the smart
              water valve. In addition, it can save 30% on your monthly water
              bills by the flow management systems, by maintaining city back
              pressure upon water delivery (fragmentation).
            </p>

            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                {" "}
                Technology Adoption Benefits:
              </h3>

              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Motor vehicle manufacturing, car wash and service stations
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p70} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link mb-5"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Gasforal FL] Case Studies
            </Link>
          </Col>
        </Row>
        <Row
          xs={"12"}
          className={`m-0 p-0  ${isDesktopOrLaptop && "px-5"} text`}
        >
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The water usage at car washes can vary significantly depending on
            several factors, such as the type of car wash, the size of the
            facility, the number of vehicles washed, and the efficiency of the
            equipment used. As a result, it is challenging to provide an exact
            figure for the average water consumption at car washes on a daily
            basis.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            However, to provide a general estimate, traditional conveyor or
            tunnel car washes typically consume around 30 to 45 gallons of water
            per vehicle. In self-service car washes, where customers wash their
            own vehicles, the water usage can range from 10 to 25 gallons per
            vehicle.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Considering these figures, if we assume an average of 35 gallons of
            water per vehicle, a car wash that handles 100 vehicles per day
            would use approximately 3,500 gallons of water daily. However, it's
            important to note that this is just a rough estimate, and the actual
            water consumption can vary based on many factors, including the size
            and efficiency of the equipment, the length of the washing process,
            and the water conservation measures implemented by the car wash
            facility.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            It's worth mentioning that many modern car washes are adopting
            water-saving technologies, such as high-pressure nozzles, water
            recycling systems, and efficient wash processes, to minimize water
            consumption and improve sustainability. These technologies can
            significantly reduce water usage compared to traditional car wash
            methods
          </p>
          <div className="m-0 p-0">
            <Button
              variant="dark"
              // style={{ marginLeft: "40px" }}
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </div>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default ServiceStation;
