import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import colorlogo from "../../assets/images/color-logo.png";
import { VscThreeBars } from "react-icons/vsc";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";

const CaseStudiesNavbar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  useEffect(() => {
    // alert(window.location.pathname);
  }, []);
  return (
    <Navbar
      // expand="lg"
      expand
      className="m-0 p-0 bg-body-light"
      collapseOnSelect
    >
      <Container fluid className=" p-0 m-0 px-5 mx-3 " 
      >
        <Navbar.Toggle
          aria-controls="navbarScroll"
        />
        <Navbar.Collapse
          id="navbarScroll"
        >
          <Nav
            className="me-auto  "
            navbarScroll={true}
            // activeKey={window.location.pathname}
          >
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to={"/caseStudies/hospitality"}
              active={window.location.pathname === "/caseStudies/hospitality" ||window.location.pathname === "/caseStudies/multifamily"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              HOSPITALITY
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/manufacturing"
              active={window.location.pathname === "/caseStudies/manufacturing"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              MANUFACTURING
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/servicestation"
              active={
                window.location.pathname === "/caseStudies/servicestation"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              SERVICE STATIONS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/restaurants"
              active={window.location.pathname === "/caseStudies/restaurants"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              RESTAURANTS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/officebuilding"
              active={
                window.location.pathname === "/caseStudies/officebuilding"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              OFFICE BUILDINGS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/condominiums"
              active={window.location.pathname === "/caseStudies/condominiums"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              CONDOMINIUMS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/supermarket"
              active={window.location.pathname === "/caseStudies/supermarket"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              SUPERMARKETS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/golfcourse"
              active={window.location.pathname === "/caseStudies/golfcourse"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              GOLF COURSE
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/caseStudies/others"
              active={window.location.pathname === "/caseStudies/others"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              OTHERS
            </Nav.Link>
          
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default CaseStudiesNavbar;
