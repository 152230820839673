import React from "react";
import {
  Button,
  Col,
  Container,
  Figure,
  Image,
  Row,
  Stack,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p190 from "../../assets/images/32. Leveraging our Relationships to Drive Impact. 1366x768/190.png";
import agent from "../../assets/images/32. Leveraging our Relationships to Drive Impact. 1366x768/agent.png";
import MasterLayout from "../../layouts/MasterLayout";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

const Agent = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <Row xs={"12"} className="m-0 p-0 mt-4">
          <h4
            className="poppins-SemiBold portfolioTinyTitle text-uppercase "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "-12px" }
                : { marginLeft: "40px" }
            }
          >
            Water Solution Technology Welcome New Agent
          </h4>
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Leveraging Our Relationships to Drive impact
          </h4>
          <Col
            lg={5}
            sm={12}
            md={6}
            xs={12}
            className={`smallText d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5 " : "p-0"
            }`}
          >
            <Image fluid alt="171x180" src={agent} />
          </Col>
          <Col
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "py-5 px-3" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Collaborate with us to drive organizational impact. Our current
              agents excel in their roles, delivering top-tier service,
              nurturing robust connections, and priding themselves as trusted
              consultant. They genuinely enjoy linking their network with
              valuable resources. If this resonates with you and you're
              interested in establishing an additional revenue stream, let's
              connect, and get started.
              <br />
              <br />
              We aim to assist the organizations you're affiliated with by
              pairing them with solutions tailored to their objectives, needs,
              and challenges. This encompasses cost-saving measures, fresh
              revenue prospects, strategic alliances, as well as innovative and
              socially responsible practices.
              <br />
              <br />
              Determine if your contacts align with our solutions. Partner with
              us to cultivate a robust revenue stream while offering tangible
              value. Begin the process today by completing the quick submit
              form.
            </p>
            <Stack gap={5} direction="horizontal" className="my-4">
              <h6
                className="portfolioTinyTitle poppins-SemiBold"
                style={
                  isTabletOrMobile
                    ? {
                        fontSize: "12px",
                      }
                    : {}
                }
              >
                Quick Account setup
              </h6>
              <Button
                onClick={() => {
                  window.open(
                    "https://cmsws.alleimran.com/propertyOwner/signup"
                  );
                }}
                variant="dark"
                className="   align-self-start"
              >
                Quick Form (Agent)
              </Button>
            </Stack>
          </Col>
          <p
            className="poppins-Regular mx-5"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            <i>
              {" "}
              When we refer to an "agent," "partner," "representative," or
              similar terms, these individuals are independent contractors and
              do not possess such authority. to legally represent or obligate
              the company.
            </i>
          </p>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Agent;
