import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import colorlogo from "../../assets/images/color-logo.png";
import { VscThreeBars } from "react-icons/vsc";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";

const AboutusNavbar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  useEffect(() => {
    // alert(window.location.pathname);
  }, []);
  return (
    <Navbar
      // expand="lg"
      expand
      className="m-0 p-0 bg-body-light"
    >
      <Container
        fluid
        className=" p-0 m-0 px-5 mx-3 "
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav  " />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-auto"
            // activeKey={window.location.pathname}
          >
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to={"/aboutUs/location"}
              active={window.location.pathname === "/aboutUs/location"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              LOCATIONS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/aboutUs/resources"
              active={
                window.location.pathname === "/aboutUs/resources" ||
                "/aboutUs/resources/blogdetail1" ||
                "/aboutUs/resources/blogdetail2" ||
                "/aboutUs/resources/blogdetail3" ||
                "/aboutUs/resources/blogdetail4" ||
                "/aboutUs/resources/blogdetail5" ||
                "/aboutUs/resources/blogdetail6" ||
                "/aboutUs/resources/blogdetail7"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              RESOURCE & NEWS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/aboutUs/gallery"
              active={window.location.pathname === "/aboutUs/gallery"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              GALLERY
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/aboutUs/govservices"
              active={window.location.pathname === "/aboutUs/govservices"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              GOV SERVICES
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default AboutusNavbar;
