import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/waterTech.css";
import p23 from "../../assets/images/5. The Smart Integrated Approach. 1366x768/23.png";
import p24 from "../../assets/images/5. The Smart Integrated Approach. 1366x768/24.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

function EssentailProgram(props) {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });


  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer ">
        <Row className=" m-0 p-0 ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            THE SMART INTEGRATED APPROACH
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center my-3"
            style={
              isTabletOrMobile
                ? { fontSize: "18px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            The Complete Water Management Solution for Your Building
          </h3>
          <Image fluid src={p23} alt="Image" className="px-5" />
          <h3
            className="poppins-SemiBold portfolioSubTitle  mt-5"
            style={
              isTabletOrMobile ? { fontSize: "18px", marginLeft: "0px" } : {}
            }
          >
            How Does The Approach Works?
          </h3>
        </Row>
        <Row className={`m-0  text ${isDesktopOrLaptop && "px-5"} `}>
          <p
            className="poppins-Regular"
            style={
              isTabletOrMobile ? { fontSize: "14px" } : { marginLeft: "-0.5%" }
            }
          >
            The proposed approach is an integrated water building management
            solution aimed at reducing water consumption and related expenses.
            The process commences at the point of water entry, specifically at
            the water meter, and can extend to include the cooling towers and
            HVAC systems responsible for discharging water into the environment.
            The specific scope of this approach depends on individual
            preferences, the building's structural water systems, and available
            budget.
          </p>
          <p
            className="poppins-Regular"
            style={
              isTabletOrMobile ? { fontSize: "14px" } : { marginLeft: "-0.5%" }
            }
          >
            This comprehensive strategy takes an organic engineering approach,
            utilizing a series of methods that can be customized by employing
            one or all three of the following technologies: addressing meter
            accuracy issues, enhancing water quality, and providing real-time
            alerts via our secure network for immediate remote control in the
            event of leaks
          </p>
          <Container
            fluid
            className={`m-0 p-0 my-5 d-flex flex-row  justify-content-between ${
              isDesktopOrLaptop ? " px-5" : "px-2"
            }`}
          >
            <Button
              variant="dark"
              size="sm"
              className={isDesktopOrLaptop ? " px-5 " : "px-3"}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series1");
              }}
            >
              Series 1
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={isDesktopOrLaptop ? " px-5 " : "px-3"}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series2");
              }}
            >
              Series 2
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={isDesktopOrLaptop ? " px-5 " : "px-3"}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series3");
              }}
            >
              Series 3
            </Button>
          </Container>
        </Row>

        <Row className=" m-0 p-0 ">
          <h1
            class="portfolioTitle poppins-SemiBold my-5"
            style={
              isTabletOrMobile ? { fontSize: "22px", marginLeft: "0px" } : {}
            }
          >
            A Real-World Example of the Integrated Approach
          </h1>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Integrated Benefits and Results
            </h3>

            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                  className="mb-2"
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Short payback period (12-24 months)*
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Fast ROI ({">"}80%)
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                {">"}25% reduction in energy
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                85% reduction in chemical for scale treatment
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                25% Saving on energy fee (equipment specific*)
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                25% Saving on water fee
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                6% Saving on consumption
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  className="mb-2"
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Ability to claim 100% of available evaporation credits
              </p>
            </div>
            <h4
              className="poppins-SemiBold portfolioTinyTitle my-3 "
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              Customers can opt to use one technology or all, on the program
            </h4>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={` d-flex flex-column justify-content-center align-items-center m-0 ${
              "p-0"
            }`}
          >
            <Image fluid src={p24} alt="Image" />
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-5 text ">
          <h3
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "0px" } : {}
            }
          >
            Execution of the Approach
          </h3>
          <p
            className={`poppins-Regular  my-3 ${isDesktopOrLaptop && "mx-5"}`}
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            For effective water management in your facility, Please see Water
            Solutions Technology step-by-step Guide.{" "}
            <Link className="link poppins-SemiBold" onClick={() => {}}>
              READ MORE
            </Link>
          </p>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default EssentailProgram;
