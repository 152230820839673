import React, { useEffect, useState } from "react";
import { Badge, Button, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p88 from "../../assets/images/21. Others we serve. 1366x768/1. Schools and Universities. 1366x768/88.png";
import p89 from "../../assets/images/21. Others we serve. 1366x768/2. Medical Facilities. 1366x768/89.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { caseStudiesAlert } from "../../helper/HelperFunctions";

const Others = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0  ">
        {/* <Row xs={"12"} className=" m-0 p-0">
          <CaseStudiesNavbar />
        </Row> */}
        <Row
          xs={"12"}
          className={`m-0 p-0 mt-4 ${isDesktopOrLaptop && "px-5"} `}
        >
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "-12px" }
                : { marginLeft: "0px" }
            }
          >
            Others we serve
          </h4>
        </Row>
        <Row
          xs={"12"}
          className="m-0 mt-5 "
          style={{
            paddingLeft: isDesktopOrLaptop && "3%",
            paddingRight: isDesktopOrLaptop && "3%",
          }}
        >
          {/* <Col className="portfolio">
            <h4
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Healthcare
            </h4>
            <img
              src={P31}
              className="img img-fluid mb-4"
              style={{
                maxHeight: isTabletOrMobile && "40%",
                maxWidth: isTabletOrMobile && "50wh",
              }}
            />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The Supermarket water usage has been on the rise in recent months
              – purely to include services such a fast moving consumer goods or
              on demand meals. Furthermore, most supermarket has now adopted.
            </p>
          </Col>
          <Col className="portfolio">
            <h4
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              School
            </h4>
            <img
              src={P32}
              className="img img-fluid mb-4"
              style={{
                maxHeight: isTabletOrMobile && "40%",
                maxWidth: isTabletOrMobile && "50wh",
              }}
            />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The Supermarket water usage has been on the rise in recent months
              – purely to include services such a fast moving consumer goods or
              on demand meals. Furthermore, most supermarket has now adopted.
            </p>
          </Col>
          <Col className="portfolio">
            <h4
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Golf Courses
            </h4>
            <img
              src={P33}
              className="img img-fluid mb-4"
              style={{
                maxHeight: isTabletOrMobile && "40%",
                maxWidth: isTabletOrMobile && "50wh",
              }}
            />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The Supermarket water usage has been on the rise in recent months
              – purely to include services such a fast moving consumer goods or
              on demand meals. Furthermore, most supermarket has now adopted.
            </p>
          </Col> */}
          <Col lg={3} xs={12} className="m-0 p-0 text-center  ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: isDesktopOrLaptop ? "34%" : "39%",
                  paddingRight: isDesktopOrLaptop ? "34%" : "39%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                HOA’S
              </Badge>
            </h3>
          </Col>
          <Col lg={3} xs={12} className="  m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: isDesktopOrLaptop ? "15%" : "24%",
                  paddingRight: isDesktopOrLaptop ? "15%" : "24%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Commercial Laundries
              </Badge>
            </h3>
          </Col>
          <Col lg={3} xs={12} className="m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: isDesktopOrLaptop ? "18%" : "26%",
                  paddingRight: isDesktopOrLaptop ? "18%" : "26%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Single Family Homes
              </Badge>
            </h3>
          </Col>
          <Col lg={3} xs={12} className="m-0 p-0  text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: isDesktopOrLaptop ? "31%" : "36%",
                  paddingRight: isDesktopOrLaptop ? "31%" : "36%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Industrial
              </Badge>
            </h3>
          </Col>
          <Col
            lg={4}
            xs={12}
            className={`m-0 p-0 text-center ${isDesktopOrLaptop && "mt-2"}  `}
          >
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "14%",
                  paddingRight: "13%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Multifamily Apartment Complexes
              </Badge>
            </h3>
          </Col>
          <Col
            lg={4}
            xs={12}
            className={`m-0 p-0 text-center ${isDesktopOrLaptop && "mt-2"}  `}
          >
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "12%",
                  paddingRight: "12%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Home Builders And The Homeowner
              </Badge>
            </h3>
          </Col>
          <Col
            lg={4}
            xs={12}
            className={`m-0 p-0 text-center ${isDesktopOrLaptop && "mt-2"}  `}
          >
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "25%",
                  paddingRight: "24%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Fast Food Companies
              </Badge>
            </h3>
          </Col>
        </Row>
        <Row xs={"12"} className="m-0 p-0 mt-5 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Schools & Universities
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p88}
              // className="img img-fluid"
              alt="image"
            />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              In higher education, sustainability initiatives are gaining
              prominence. Of particular importance is the endeavour to optimize
              water usage, especially given the unique water consumption habits
              of US college students aged 17 to 24. Surprisingly, individuals in
              this demographic group consume more water per capita for personal
              use than both children and adults aged 25 and over.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              On average, college and university campuses collectively consume
              over 262,000,000 gallons of potable water annually. This places a
              considerable strain on resources, both natural and financial.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The financial implications of such water usage are substantial.
              The excess expense incurred by universities due to water
              consumption reaches a staggering $1,200,000.00. These financial
              burdens inhibit institutions from pursuing other crucial projects
              and investments.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Thankfully, there is a transformative solution at hand. By
              adopting the Water Solutions Technology smart water valve,
              universities can anticipate significant savings, ranging from 15%
              to 25%. These funds, exceeding $250,000.00, can then be redirected
              toward additional projects and initiatives, ensuring universities
              remain sustainable and financially robust.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Are you ready to embrace a path toward a more sustainable and
              economically efficient campus environment? Reach out to us to
              explore this solution further and initiate the journey.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              By embracing the Water Solutions Technology solution, universities
              can join the ranks of forward-thinking educational institutions
              striving for sustainability and cost-effectiveness while enhancing
              their campus environments.
            </p>
          </Col>
        </Row>
        <Row xs={"12"} className="m-0 p-0 mt-5 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Medical Facilities
          </h4>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Healthcare facilities, particularly hospitals, are major consumers
              of water, with each facility using roughly 100 gallons per bed and
              an additional 20 gallons per employee. These numbers do not even
              account for other water usage, such as in common areas. In
              response to growing water scarcity and environmental concerns,
              healthcare institutions are increasingly recognizing the
              importance of sustainability and efficient water management.
              <br />
              <br />
              Hospitals that have implemented water-saving programs have seen
              substantial reductions in water usage, often up to 25% or more.
              This translates into significant financial savings in utility
              costs. The return on investment (ROI) for water conservation
              projects in healthcare facilities typically ranges from 25% to
              40%, making them not only financially sensible but also in line
              with broader sustainability goals.
              <br />
              <br />
              Achieving water savings in healthcare facilities requires a
              systematic approach. Institutions can choose to use their internal
              resources or seek assistance from engineering firms with expertise
              in this field. External partners are particularly valuable when
              evaluating equipment and replacement projects, as they can help
              secure funding by presenting compelling data. These partnerships
              enhance the success of water-saving initiatives in healthcare
              institutions while promoting sustainability and responsible
              resource management.
            </p>
          </Col>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p89}
              // className="img img-fluid"
              alt="image"
            />
          </Col>
        </Row>
        <Button
          variant="dark"
          className={isDesktopOrLaptop ? "m-5" : "my-5"}
          onClick={() => {
            handleShow(true);
          }}
        >
          Quick Connect
        </Button>
        <Row
          className="m-0 "
          style={{ paddingLeft: isDesktopOrLaptop ? "40px" : "-12px" }}
        >
          <h3
            className="poppins-SemiBold portfolioSmallTitle my-3"
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            SELECTED CASE STUDIES
          </h3>
          <div
            className={`d-flex  m-0 mb-4 ${
              isDesktopOrLaptop ? "flex-row" : "flex-column"
            }`}
          >
            <Link
              onClick={caseStudiesAlert}
              className="poppins-Regular link "
              style={{
                fontSize: isTabletOrMobile ? "10px" : "16px",
                marginRight: "20px",
              }}
            >
              Studies: Denny's, FI
            </Link>
            <Link
              onClick={caseStudiesAlert}
              className="poppins-Regular link "
              style={{
                fontSize: isTabletOrMobile ? "10px" : "16px",
                marginRight: "20px",
              }}
            >
              Selected Case Studies
            </Link>
            <Link
              onClick={caseStudiesAlert}
              className="poppins-Regular link "
              style={{
                fontSize: isTabletOrMobile ? "10px" : "16px",
                marginRight: "20px",
              }}
            >
              Studies: Denny's, FI
            </Link>
            <Link
              onClick={caseStudiesAlert}
              className="poppins-Regular link "
              style={{
                fontSize: isTabletOrMobile ? "10px" : "16px",
                marginRight: "20px",
              }}
            >
              Studies: Denny's, Tx
            </Link>
          </div>
          <h3
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            NO FIT CASE STUDIES
          </h3>
          <Link
            onClick={caseStudiesAlert}
            className="poppins-Regular link my-2 "
            style={{
              fontSize: isTabletOrMobile ? "10px" : "16px",
              marginRight: "20px",
            }}
          >
            Studies: Condominiums, Hileah. FI
          </Link>
          <div className="d-flex justify-content-end ">
            <Button
              onClick={caseStudiesAlert}
              variant="dark"
              className={isDesktopOrLaptop ? "m-5" : "my-5"}
            >
              Request More Studies
            </Button>
          </div>
        </Row>
      </Container>
      <QuickConnect isVisible={show} hideModel={handleClose} />
    </MasterLayout>
  );
};

export default Others;
