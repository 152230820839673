import React, { useState } from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Badge, Button, Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";

import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import p90 from "../../assets/images/22. Work With Us - Consulting. 1366x768/90.png";
import p91 from "../../assets/images/22. Work With Us - Consulting. 1366x768/91.png";
import p92 from "../../assets/images/22. Work With Us - Consulting. 1366x768/92.png";
import p93 from "../../assets/images/22. Work With Us - Consulting. 1366x768/93.png";
import p94 from "../../assets/images/22. Work With Us - Consulting. 1366x768/94.png";
import p95 from "../../assets/images/22. Work With Us - Consulting. 1366x768/95.png";
import p96 from "../../assets/images/22. Work With Us - Consulting. 1366x768/96.png";
import watersolution from "../../assets/images/22. Work With Us - Consulting. 1366x768/watersolution.png";

const Consulting = () => {
  const navigate = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        {/* <Row xs={"12"} className="mt-5  m-0 p-0">
        <WorkwithusNavbar />
      </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-5 text ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile ? { fontSize: "22px", marginLeft: "-12px" } : {}
            }
          >
            WORK WITH US
          </h1>
          <Col xs={"12"} className={`m-0 p-0 ${isDesktopOrLaptop && "px-5"} `}>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Our objective is to work with you to reach your sustainable goals,
              reduce carbon footprint, and to protect your property, and save on
              operational expenses. We pride that these contributions may
              positively affect the environment, allow property users peace of
              mind, and save on operational and water fees – for profitability.
              Read more on our step-by-step process to onboard our technologies{" "}
              <Link className="link poppins-SemiBold" to={"/portfolio/history"}>
                (click here).
              </Link>
            </p>
          </Col>
          <h3
            className="poppins-SemiBold portfolioSmallTitle blue"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "-12px" } : {}
            }
          >
            Its simple: if you have a water meter on your property, you need to
            speak with us.
          </h3>
          <br />
          <br />
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Where We Add Value (Our Areas of Expertise)
          </h3>
        </Row>
        <Row className="m-0 p-0 px-4 mt-4  ">
          <Col lg={4} xs={12} className="m-0 p-0 text-center">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "30%",
                  paddingRight: "30%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Water Treatment
              </Badge>
            </h3>
          </Col>
          <Col lg={4} xs={12} className="  m-0 p-0 text-center">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "32%",
                  paddingRight: "32%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Water Savings
              </Badge>
            </h3>
          </Col>
          <Col lg={4} xs={12} className="m-0 p-0 text-center">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "28%",
                  paddingRight: "28%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Water Fee Savings
              </Badge>
            </h3>
          </Col>
          <Col lg={4} xs={12} className="m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "27%",
                  paddingRight: "27%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Chiller Management
              </Badge>
            </h3>
          </Col>
          <Col lg={4} xs={12} className=" m-0 p-0 text-center">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "29%",
                  paddingRight: "29%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Water Purification
              </Badge>
            </h3>
          </Col>
          <Col lg={4} xs={12} className="m-0 p-0 text-center">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "25.8%",
                  paddingRight: "25.8%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                Water Facility Design
              </Badge>
            </h3>
          </Col>
        </Row>
        <Container fluid className="m-0 p-0 d-flex justify-content-end  ">
          <Button onClick={handleShow} variant="dark" className="m-5">
            Quick Contact
          </Button>
        </Container>
        <Row className={`m-0 p-0 ${isDesktopOrLaptop && "px-5"} `}>
          <p
            className="poppins-Regular my-5 text-center"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Our product line has been patented and its designs are proprietary.
            The patent is open for investors to examine.
          </p>
          <Col
            lg={6}
            md={6}
            xs={12}
            className={`smallText d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              We are Seeking:
              <br />
              <br />
              Plumbers & Plumber Groups
              <br /> Property Management Companies
              <br /> Sustainable Groups
              <br /> Established Cost Savings Companies
              <br /> Consultancy Groups
              <br /> Agent
            </p>
          </Col>
          <Col
            lg={6}
            md={6}
            xs={12}
            className={`smallText d-flex flex-column align-items-start justify-content-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular  blue"
              style={{ color: "#31a6bf" }}
              onClick={() => {}}
            >
              Easy account setup
            </p>
            <Button
              variant="dark"
              size="md"
              className="poppins-Regular px-5"
              onClick={() => {
                navigate("/agent");
              }}
            >
              Sign up
            </Button>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={isTabletOrMobile ? { fontSize: "16px" } : {}}
          >
            OUR GROUP & PARTNERS{" "}
          </h3>
        </Row>
        <Row className={`m-0 p-0 mt-4 ${isDesktopOrLaptop && 'px-5'} `}>
          <Col className="m-0 p-0 " xs={6} lg={2}>
            <Image
              src={p90}
              fluid
              //  className="img img-fluid"
              style={{
                objectFit:'cover',
                width:'100%',
                height:isDesktopOrLaptop?'30vh':'',
              }}
            />
          </Col>
          <Col  className="m-0 p-0 "  xs={3} lg={2}>
            <Image src={p91} fluid style={{
                objectFit:'cover',
                width:'100%',
                height:isDesktopOrLaptop?'30vh':'',
              }} />
          </Col>
          <Col  className="m-0 p-0 "  xs={3} lg={2}>
            <Image src={watersolution} fluid  style={{
                objectFit:'cover',
                width:'100%',
                height:isDesktopOrLaptop?'30vh':'',
              }}/>
          </Col>
          <Col  className="m-0 p-0 "  xs={3} lg={2}>
            <Image src={p93} fluid style={{
                objectFit:'cover',
                width:'100%',
                height:isDesktopOrLaptop?'30vh':'',
              }}/>
          </Col>
          <Col  className="m-0 p-0 "  xs={3} lg={2}>
            <Image src={p94} fluid style={{
                objectFit:'cover',
                width:'100%',
                height:isDesktopOrLaptop?'30vh':'',
              }}/>
          </Col>
          
          <Col  className="m-0 p-0 "  xs={3} lg={2}>
            <Image src={p96}  style={{
                objectFit:'cover',
                width:'100%',
                height:isDesktopOrLaptop?'30vh':'',
              }}/>
          </Col>
          <Col  className="m-0 p-0 "  xs={3} lg={2}>
            <Image className="mt-3" src={p95} fluid style={{
                objectFit:'cover',
                width:'100wh',
                height:isDesktopOrLaptop?'30vh':'',
              }}/>
          </Col>
        </Row>
      </Container>
      <QuickConnect isVisible={show} hideModel={handleClose} />
    </MasterLayout>
  );
};

export default Consulting;
