import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p116 from "../../../assets/images/27. Latest In Property Water Management.1366x768/116.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail1 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              High traffic: the impact it will have on your water usage and
              associated utility fees
            </h3>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <b className="mediumBlack">Not Business as usual!</b> I wanted to
              share with you a small economic strategy that you can adopt for
              your properties – that will help with the most important upcoming
              “Post Pandemic” economic response: “WATER DEMAND”. Your properties
              water usages will increase and so too, the water utility fees.
              With the increase in public advocation asking for frequent washing
              of hands, faces, lengthy showers and other sanitary measure
              regimes, the water demand for your property will climb in response
              to these new “norms”. This calls for a strategic approach to a
              “new” operating budget. As you can tell,water is the most
              important player to effect these changes and its usage will
              increase phenomenally.
            </p>
            <Image
              fluid
              src={p116}
              alt="image"
            />
            <br />
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Place of “home” has now become the place of “work and home”.
              COVID-19 has led to a 20% rise in U.S. adults being able to work
              from home, and they are currently doing so according to an opinion
              poll conducted early April 2020, by
              <u className="blue"> Statista </u>. This number will continue to
              accelerate as most businesses in response to the outbreak are
              adjusting and shifting their workforce to work remotely and
              online. As a result, building activity such as Condominiums,
              Houses, and Hotels used for lodging will face a great challenge in
              water usage as most Americans is left to be working from home or
              remotely.
              <br /> The effect of a high demand for water is 3 fold: increase
              water usage and waste, increase in water fees, and increase in
              other utility fees such as energy – the more water is used, the
              more energy it takes to pump the water on your property.
              Therefore, you will not only see an increase in your property’s
              water fee but also an increase in your property’s energy
              consumption utility fees. Lowering your water usage therefore will
              be a critical element to address in the upcoming demand and
              lifestyle changes for water use.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How can you start saving and planning for water usage?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The following are a list of things that can be implemented to
              reduce your water fees:
            </p>

            <ol
              className="m-0 p-0"
              style={{ listStyle: "inside", listStyleType: "number" }}
            >
              <li
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Shorter shower time can be encouraged
              </li>
              <li
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Don’t have the shower running unnecessarily{" "}
              </li>
              <li
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Filling pools in the coolest hours of the day best between 2:00
                am -3:00 am. This prevent unnecessary evaporation and reduce the
                time spent to fill the pools – less on metering.{" "}
              </li>
              <li
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Use the most effective water Technologies that helps you save on
                water, water usage and reduce water fees – that is where{" "}
                <b> Water Solutions Technology </b> can help you on your monthly
                savings to recommend and install the best advance technologies.
              </li>
            </ol>

            <br />

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Our devices are newly patented hydro-technologies and it will be
              one of the best devices working in the background to reduce the
              strain that will be on your pandemic-response operating budget.
              The main flagship device can save as much as up to 40% on water
              fees – by reduction in air volume and reduces water usage by up to
              6% – delivering water at the right flow rates. You can
              <u className="blue">request</u> a free copy of our water research
              report that shows water usage studies based on a building’s
              primary activity – which would be helpful to see how your property
              can save using our technologies. Covered in this report are HOA –
              Condominiums, Hotels & Resorts, Restaurants, Hospitals, Shopping
              Malls, Golf Courses, Carwashes and Water Parks.
            </p>

            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Why don’t we <u className="blue"> connect</u> to discuss how you
              can you save thousands in the next coming months?
            </h3>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The process is simple by first identifying the volume of water
              costs per month and the location and number of water meters on the
              property, then conducting a site survey to evaluate, analyse, and
              make recommendations for effective water management technologies
              to immediate savings to your community or communities!.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail1;
