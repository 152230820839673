import React, { useState } from "react";
import { Form, Modal, Row, Col, Button, Alert } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import axios from "axios";

const CustomQuotes = ({ isVisible, hideModel }) => {
  const { baseUrl } = useContext(AppContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [formValidate, setFormValidate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [reason, setReason] = useState("");
  const [reasonsList, setReasonsList] = useState([
    { id: 1, label: "Need technical help", value: "Need technical help" },
    {
      id: 2,
      label: "Requesting a case study",
      value: "Requesting a case study",
    },
    {
      id: 3,
      label: "Need to get a proposal & a quote",
      value: "Need to get a proposal & a quote",
    },
  ]);
  const [interestedTechnology, setInterestedTechnology] = useState("");
  const [interestedTechnologyList, setInterestedTechnologyList] = useState([
    {
      id: 1,
      label: "The Smart Series 1:The Smart Water Systems valvs",
      value: "The Smart Series 1:The Smart Water Systems valvs",
    },
    {
      id: 2,
      label: "The Smart Series 2:The Smart Smart IoT",
      value: "The Smart Series 2:The Smart Smart IoT",
    },
    {
      id: 3,
      label: "The Smart Series 3:The Smart Scale Remover/Buster",
      value: "The Smart Series 3:The Smart Scale Remover/Buster",
    },
  ]);
  const clearModal = () => {
    setFirstName("");
    setLastName("");
    setTitle("");
    setEmail("");
    setNumber("");
    setMessage("");
    setCompany("");
    setInterestedTechnology("");
    setReason("");
  };
  const submitForm = () => {
    // alert("submit chala");
    console.log("////////////////////////////////////////////");
    console.log("////////////////////////////////////////////");
    console.log(
      firstName,
      lastName,
      email,
      company,
      title,
      number,
      message,
      reason,
      interestedTechnology
    );
    const formData = new FormData();
    formData.append("firstName", `${firstName}`);
    formData.append("lastName", `${lastName}`);
    formData.append("phoneNumber", `${number}`);
    formData.append("email", `${email}`);
    formData.append("title", `${title}`);
    formData.append("companyName", `${company}`);
    formData.append("contactedReason", `${reason}`);
    formData.append("interestedTechnology", `${interestedTechnology}`);
    formData.append("message", `${message}`);
    axios({
      method: "post",
      url: `${baseUrl}/auth/customquote/signup`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        // console.log(response.data.message);
        if (response.data.save) {
          console.log(response.data.newCustomQuote);
          // setTimeout(() => {
            setShowAlert(true);
          // }, 1000);
          setTimeout(() => {
            setShowAlert(false);
            hideModel();
          }, 3000);
        }
        clearModal();
      })
      .catch((error) => {
        console.log(error);
        hideModel();
      });
  };

  return (
    <Modal
      centered
      show={isVisible}
      onHide={hideModel}
      size={isDesktopOrLaptop ? "md" : "sm"}
      className="p-0 m-0"
    >
      <Modal.Header closeButton style={{ border: "none" }}></Modal.Header>
      {showAlert && (
        <Alert variant="success">Form submitted successfully!</Alert>
      )}
      <Modal.Body>
        <Form
          noValidate
          validated={formValidate}
          onSubmit={(event) => {
            const form = event.currentTarget;
            event.preventDefault();
            if (form.checkValidity() === false) {
              setFormValidate(true);
              // event.stopPropagation();
              return;
            }
            submitForm();
          }}
          className="m-0 p-0 px-2"
        >
          <Row xs={12} className="m-0 p-0">
            <Col lg={6} xs={12} className="px-1">
              <Form.Group
                className="mb-3 poppins-Regular"
                controlId="ControlInput1"
              >
                <Form.Control
                  className="placeholderStyle"
                  type="text"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12} className="px-1">
              <Form.Group
                className="mb-3 poppins-Regular"
                controlId="ControlInput2"
              >
                <Form.Control
                  className="placeholderStyle"
                  type="text"
                  placeholder="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12} className="px-1">
              <Form.Group
                className="mb-3 poppins-Regular"
                controlId="ControlInput3"
              >
                <Form.Control
                  className="placeholderStyle"
                  type="text"
                  placeholder="Company Name"
                  value={company}
                  onChange={(e) => setCompany(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12} className="px-1">
              <Form.Group
                className="mb-3 poppins-Regular"
                controlId="ControlInput4"
              >
                <Form.Control
                  className="placeholderStyle"
                  type="text"
                  placeholder="What is your Title?"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12} className="px-1">
              <Form.Group
                className="mb-3 poppins-Regular"
                controlId="ControlInput5"
              >
                <Form.Control
                  className="placeholderStyle"
                  type="email"
                  placeholder="Work Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
            <Col lg={6} xs={12} className="px-1">
              <Form.Group
                className="mb-3 poppins-Regular"
                controlId="ControlInput6"
              >
                <Form.Control
                  className="mb-2 placeholderStyle"
                  type="text"
                  placeholder="Best contact Number"
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                  required
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Text
            className=" blue poppins-SemiBold "
            style={{ fontSize: isDesktopOrLaptop ? "14px" : "12px" }}
          >
            What's your reason for contacting us?
          </Form.Text>
          {reasonsList.map((item) => {
            return (
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={item.id}
                label={item.label}
                value={item.value}
                checked={item.value == reason}
                style={{ fontSize: "14px" }}
                onChange={(event) => {
                  console.log(event.target.checked);
                  console.log(event.target.value);
                  setReason(event.target.value);
                }}
              />
            );
          })}
          <Form.Text
            className=" blue poppins-SemiBold "
            style={{ fontSize: isDesktopOrLaptop ? "14px" : "12px" }}
          >
            The Smart Series WaterEye technology interested in
          </Form.Text>
          {interestedTechnologyList.map((item) => {
            return (
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={item.id}
                label={item.label}
                value={item.value}
                checked={item.value == interestedTechnology}
                style={{ fontSize: "14px" }}
                onChange={(event) => {
                  console.log(event.target.checked);
                  console.log(event.target.value);
                  setInterestedTechnology(event.target.value);
                }}
              />
            );
          })}

          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="ControlInput7"
          >
            <Form.Control
              as="textarea"
              rows={3}
              className="mt-2 mb-2 placeholderStyle"
              type="text"
              placeholder="Enter your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Form.Group>
          <div className="d-grid mt-2 mb-3">
            <Button
              type="submit"
              variant="primary"
              size="md"
              onClick={() => {
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default CustomQuotes;
