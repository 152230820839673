import React from "react";
import { Card, Container, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";

const Blog = ({ date, title, imageUri, to }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <>
      <Card
        style={{ width:isDesktopOrLaptop? "18rem":'9rem', border:'none',backgroundColor:'rgba(255,0,0,0)' }}
        className=" d-flex flex-column text align-items-center p-2  "
      >
        <Card.Subtitle
          className={`${
            isDesktopOrLaptop ? "mx-3" : "mx-1"
          }  my-1 poppins-Regular text-muted align-self-start `}
          style={{ fontSize: isDesktopOrLaptop ? "14px" : "12px" }}
        >
          {date}
        </Card.Subtitle>
        <Card.Link as={Link} to={to}>
          <Card.Img
            src={imageUri}
            style={{
              height: isTabletOrMobile ? "80px" : "180px",
              width: isTabletOrMobile ? "120px" : "250px",
            }}
          />
        </Card.Link>
        <Card.Body className="p-0 m-0 my-1">
          <Card.Text
            className="poppins-SemiBold portfolioSmallTitle blogCardText"
            style={
              isTabletOrMobile
                ? {
                    fontSize: "14px",
                    marginLeft: "0px",
                    color: "#333333",
                    maxWidth: "120px",
                  }
                : {
                    marginLeft: "0px",
                    maxWidth: "250px",
                    color: "#333333",
                  }
            }
          >
            {title}
          </Card.Text>
        </Card.Body>
      </Card>

      {/* <div
        className="p-4 pt-0 pb-0 mt-2"
        style={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: "row",
        }}
      >
        <div>
          <Link to={to}>
            <img
              src={imageUri}
              style={{
                height: isTabletOrMobile ? "80px" : "200px",
                width: isTabletOrMobile ? "120px" : "300px",
              }}
            />
          </Link>
        </div>
        <div
          className="p-4 pt-3"
          style={{ display: "flex", flexDirection: "column" }}
        >
          <h4
            className="poppins-Light "
            style={{
              fontSize: isTabletOrMobile ? "6px" : "15px",
            }}
          >
            {date}
          </h4>
          <h1
            className="poppins-SemiBold "
            style={{
              fontSize: isTabletOrMobile ? "9px" : "22px",
            }}
          >
            {title}
          </h1>
        </div>
      </div> */}
    </>
  );
};
export default Blog;
