import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/waterTech.css";

import p39 from "../../assets/images/10. Range of our Patented  Products. 1366x768/39.png";
import p40 from "../../assets/images/10. Range of our Patented  Products. 1366x768/40.png";
import p41 from "../../assets/images/10. Range of our Patented  Products. 1366x768/41.png";
import p42 from "../../assets/images/10. Range of our Patented  Products. 1366x768/42.png";
import p43 from "../../assets/images/10. Range of our Patented  Products. 1366x768/43.png";
import p44 from "../../assets/images/10. Range of our Patented  Products. 1366x768/44.png";
import p45 from "../../assets/images/10. Range of our Patented  Products. 1366x768/45.png";
import p46 from "../../assets/images/10. Range of our Patented  Products. 1366x768/46.png";
import p47 from "../../assets/images/10. Range of our Patented  Products. 1366x768/47.png";
import p48 from "../../assets/images/10. Range of our Patented  Products. 1366x768/48.png";
import p49 from "../../assets/images/10. Range of our Patented  Products. 1366x768/49.png";
import p50 from "../../assets/images/10. Range of our Patented  Products. 1366x768/50.png";
import p51 from "../../assets/images/10. Range of our Patented  Products. 1366x768/51.png";
import p52 from "../../assets/images/10. Range of our Patented  Products. 1366x768/52.png";
import product1 from "../../assets/images/10. Range of our Patented  Products. 1366x768/product1.png";
import product2 from "../../assets/images/10. Range of our Patented  Products. 1366x768/product2.png";
import MasterLayout from "../../layouts/MasterLayout";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import CustomQuotes from "../../components/customQuotes/CustomQuotes";
import { scrollToTop } from "../../helper/HelperFunctions";

function ProductandSupplies(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);
  const [customModal, setCustomModal] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer ">
        <Row className=" m-0 p-0 ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            Range of our Patented Products
          </h1>
        </Row>
        <Row className="m-0 p-0 text justify-content-center align-items-center ">
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            For Water Meter Accuracy – Goes beyond 12”
          </h3>
          <p
            className="poppins-Regular text-center my-5"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Series 1: The SMART VALVES <sup>TM</sup> - for Water Meter Accuracy
            up to 12”
          </p>
          <Col xs={8} className="m-0 p-0  ">
            <Image
              fluid
              src={product1}
              alt="Image"
              style={{ width: "100%", objectFit: "cover" }}
            />
          </Col>
          <Container
            fluid
            className={`my-5 d-flex flex-row justify-content-between ${
              isDesktopOrLaptop && "px-5"
            } `}
          >
            <Link to={"/portfolio/history"} className="link">
              <h4
                className=" portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                How Does This Technology Compare
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
            <Link to={"/portfolio/history"} className="link">
              <h4
                className=" portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                History of the Technology
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
            <Link to={"/portfolio/essentailprogram/series1"} className="link">
              <h4
                className="  portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                READ MORE
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
          </Container>
          <Container
            fluid
            className={`my-2  d-flex   ${
              isDesktopOrLaptop
                ? "px-5 justify-content-between"
                : "p-0 flex-column"
            }`}
          >
            <Button
              variant="dark"
              size="sm"
              className=" mb-2 "
              onClick={() => {
                setCustomModal(true);
              }}
            >
              Get Competitive Custom Quote
            </Button>{" "}
            <Button
              onClick={handleShow}
              variant="dark"
              size="sm"
              className="mb-2 "
            >
              Submit Quick Contact
            </Button>
          </Container>
        </Row>
        <Row className="m-0 p-0 mt-5 text align-items-center justify-content-center ">
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            For Pipe’s, Cooling Tower Health & Water Recovery - Goes beyond 12”
          </h3>
          <p
            className="poppins-Regular text-center my-5"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The Smart Scale Remover ION<sup> TM </sup> : 25% Reduction in Energy
            Cost & Increase Water Upcycle
          </p>
          <Col
            xs={4}
            className="m-0 p-0 px-5 d-flex flex-column align-items-center  "
          >
            <div className="d-flex flex-column align-self-end ">
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                3/4”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                1”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                1.5”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                2”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                3”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                4”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                6”
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                8”
              </p>
            </div>

            <Link className="link " to={"/portfolio/essentailprogram/series2"}>
              <h4
                className="portfolioTinyTitle poppins-SemiBold "
                style={
                  isTabletOrMobile
                    ? { fontSize: "12px" }
                    : { marginTop: "200%" }
                }
              >
                READ MORE
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
          </Col>
          <Col
            xs={7}
            className={`m-0 p-0  ${isDesktopOrLaptop ? "px-5" : "px-3"} `}
          >
            <Image fluid src={product2} alt="Image" />
            <Container
              fluid
              className={`my-5  p-0  d-flex   ${
                isDesktopOrLaptop ? "justify-content-between" : "flex-column"
              }`}
            >
              <Button
                variant="dark"
                size="sm"
                className="mb-2  "
                onClick={() => {
                  setCustomModal(true);
                }}
              >
                Get Competitive Custom Quote
              </Button>{" "}
              <Button
                onClick={handleShow}
                variant="dark"
                size="sm"
                className="mb-2 "
              >
                Submit Quick Contact
              </Button>
            </Container>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-5 text align-items-center justify-content-center">
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            The Smart Water Monitor <sup>TM</sup> Kit - for Real-Time Management
          </h3>
          <p
            className="poppins-Regular text-center my-3"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Real-Time Detection of Leaks, Predictions and Shutoff
          </p>
            <Image fluid src={p48} alt="Image" style={{width:'80%'}} />
          <Col xs={5} className="m-0 p-0  ">
            <Container
              fluid
              className={`m-0 p-0 my-5 d-flex   ${
                isDesktopOrLaptop ? " justify-content-between" : "flex-column  "
              }`}
            >
              <Button
                variant="dark"
                size="sm"
                className=" mb-2 "
                onClick={() => {
                  setCustomModal(true);
                }}
              >
                Get Competitive Custom Quote
              </Button>{" "}
              <Button
                onClick={handleShow}
                variant="dark"
                size="sm"
                className="mb-2 "
              >
                Submit Quick Contact
              </Button>
            </Container>
          </Col>
        </Row>
        <Row className="m-0 p-0 mt-5 text ">
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Government Services Products
          </h3>
          <p
            className="poppins-Regular text-center my-5"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            We carry a range of products and services to supply the Government
            with. Please learn more of these services and products.
            <Link
              className="link poppins-SemiBold"
              to={"/aboutUs/govservices"}
              onClick={() => {}}
            >
              READ MORE
            </Link>
          </p>
        </Row>
      </Container>
      <QuickConnect isVisible={show} hideModel={handleClose} />
      <CustomQuotes
        isVisible={customModal}
        hideModel={() => {
          setCustomModal(false);
        }}
      />
    </MasterLayout>
  );
}

export default ProductandSupplies;
