import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p126 from "../../../assets/images/27. Latest In Property Water Management.1366x768/126.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail7 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Cost Benefit Analysis: Water-Saving Plumbing Fixtures for Food
              Manufacturing Facilities
            </h3>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              A fundamental computation important to all production facilities
            </p>
            <Image
              fluid
              src={p126}
              alt="image"
            />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Introduction:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water conservation is a pressing concern, and food manufacturing
              facilities can significantly contribute to sustainability efforts
              by implementing water-saving plumbing fixtures. In this cost
              benefit analysis, we will examine the advantages of such fixtures
              in terms of a 30% reduction in water utility fees and a 6%
              decrease in water usage. Let's explore the potential cost savings
              and the overall financial benefits for food manufacturing
              facilities.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Cost Benefit Analysis:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              To understand the financial impact of implementing water-saving
              plumbing fixtures, we will consider a hypothetical food
              manufacturing facility that currently spends $10,000 per month on
              water utility fees and consumes 100,000 gallons of water monthly.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              1. Water Utility Fee Savings:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              With a 30% reduction in water utility fees, the facility can save
              30% of the monthly expenditure. Here's the breakdown:
              <br />
              <br />
              Monthly water utility fee: $10,000
              <br />
              Savings: 30% of $10,000 = $3,000
              <br />
              New monthly water utility fee: $10,000 - $3,000 = $7,000
              <br />
              <br />
              By implementing water-saving fixtures, the facility would achieve
              monthly cost savings of $3,000 on water utility fees alone.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              2. Water Usage Reduction:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Assuming a 6% decrease in water usage, the facility would consume
              6% less water each month. Let's calculate the water usage
              reduction and the associated cost savings:
              <br />
              <br />
              Monthly water usage: 100,000 gallons
              <br />
              Water usage reduction: 6% of 100,000 gallons = 6,000 gallons
              <br />
              New monthly water usage: 100,000 gallons - 6,000 gallons = 94,000
              gallons
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              3. Cost Savings per Gallon:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              To determine the cost savings per gallon, we need to consider the
              cost per gallon of water:
              <br />
              <br />
              Cost per gallon: Monthly water utility fee / Monthly water usage
              <br />
              Cost per gallon: $7,000 / 94,000 gallons ≈ $0.074 per gallon
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              4. Total Cost Savings:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Now, let's calculate the overall cost savings by combining the
              water utility fee savings and the cost savings per gallon:
              <br />
              <br />
              Total monthly cost savings:
              <br />
              Water utility fee savings + (Water usage reduction × Cost savings
              per gallon) $3,000 + (6,000 gallons × $0.074 per gallon)
              <br />
              Total monthly cost savings: $3,000 + $444 = $3,444
              <br />
              <br />
              The food manufacturing facility would achieve a total monthly cost
              savings of $3,444 by implementing water-saving plumbing fixtures.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Conclusion:
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Implementing water-saving plumbing fixtures in a food
              manufacturing facility can yield significant cost savings. With a
              30% reduction in water utility fees and a 6% decrease in water
              usage, our hypothetical facility would save $3,000 on water
              utility fees alone, along with an additional $444 through reduced
              water usage. The total monthly cost savings amount to $3,444.
              <br />
              <br />
              Apart from the financial benefits, embracing water conservation
              measures showcases environmental responsibility, aligns with
              sustainability goals, and enhances the facility's reputation. This
              cost benefit analysis demonstrates that investing in water-saving
              plumbing fixtures is not only a wise financial decision but also a
              crucial step towards a more sustainable future.
              <br />
              <br />
              By implementing these fixtures, food manufacturing facilities can
              make a positive impact on their bottom line while contributing to
              global water conservation efforts. Let's embrace water-saving
              practices and pave the way for a more sustainable and profitable
              future.
              <br />
              <br />
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail7;
