import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Image, Figure, Button } from "react-bootstrap";
import "../../assets/css/waterTech.css";
import { Link, useNavigate } from "react-router-dom";

import p30 from "../../assets/images/7. The Smart Integrated Approach.Series 2.1366x768/30.png";
import p31 from "../../assets/images/7. The Smart Integrated Approach.Series 2.1366x768/31.png";
import p32 from "../../assets/images/7. The Smart Integrated Approach.Series 2.1366x768/32.png";

import { useMediaQuery } from "react-responsive";
import PortfolioNavbar from "../../components/navbars/PortfolioNavbar";
import { GiCheckMark } from "react-icons/gi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

function ProgramSeries2(props) {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            THE SMART INTEGRATED APPROACH
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle blue"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Series 2: Water Treatment & Water Recovery
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Can I remove scales inside my pipeline without using high levels
              of chemicals?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Scale and corrosion are found wherever there is heat, turbulence,
              or a change in pH in the water. These conditions cause scale and
              corrosion to form on equipment surfaces and restrict water flow,
              clog systems and reduce efficiency resulting in costly equipment
              down time and increased energy and maintenance. Removing these
              scales traditionally requires a significant amount of chemical and
              energy. Using little or no chemical is a more sustainable approach
              to clear clogged pipes.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-4" : "p-0"
            }`}
          >
            <Image fluid src={p30} alt="Image" />
            <Link
              to={"/portfolio/product&supplies"}
              className="link align-self-start my-2"
            >
              <h4
                className=" portfolioTinyTitle poppins-SemiBold  mx-3"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                VISIT OUR FULL RANGE
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            The Technology
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p31}
              alt="Image"
              // style={{width:'100%',objectFit:'cover'}}
            />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5 py-0" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How does the physical treatment technology work?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              In contrast to systems that rely on aggressive chemicals to
              dissolve scale and corrosion, which can introduce harmful
              pollutants into groundwater, the Smart Scale Remover TM (Scale
              Buster TM) operates without the need for chemicals. Instead, it
              utilizes an eco-friendly electrostatic process. This approach
              lowers energy and water consumption, and in some instances,
              completely eliminates the release of harmful water pollutants.
              <br />
              <br />
              The unique design of its PTFE (Teflon®) turbulence chambers
              disrupts the existing static equilibrium within the device. This
              results in the neutralization of charged particles, leading them
              to combine and create larger particle clusters that take longer to
              disperse in the water.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              This technology is easily installed in the water system. It has no
              moving parts and requires no maintenance. It comes with a 10-year
              warranty but in most cases the device should last in excess of 20
              years or longer.
            </p>
          </Col>
        </Row>
        {/* <Row className="m-0 p-0">
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start"
            style={isTabletOrMobile ? { fontSize: "16px",marginLeft:'-12px' } : {}}
          >
            The Smart Flow Management <sup>TM</sup> Valve Technology
          </h3>
        </Row> */}
        {/* <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Figure>
              <Figure.Image fluid alt="171x180" src={P29} />
              <Figure.Caption className="poppins-Regular  my-3">
                Most Customers Experience 15-25% in Monthly Savings
              </Figure.Caption>
            </Figure>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How does the air control technology work?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The device maintains city backpressure, restricting air expansion
              (Venturi Designed), keeping its volume constricted before it
              reaches the water meter register —the meter register no longer
              measures this air. As a result, you pay less for the same “true
              water usage” . The Externally adjustable feature allows you to
              fine tune the setting perfectly to your facility for maximum
              performance all year round
            </p>
            <Link
              className="link poppins-SemiBold align-self-start "
              onClick={() => {}}
              // style={{ textDecoration: "underline" }}
            >
              VISIT OUR FULL RANGE
            </Link>
            <div className="smallText align-self-start justify-content-center align-items-start  mt-2">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                5 Fundamental Customer Benefits
              </h3>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                  className="mb-2"
                  style={{ marginRight: "15px",  }}
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Reduction in water fees (10-30%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                  className="mb-2"
                  style={{ marginRight: "15px", }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Reduction in sewer fees (10-30%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                  className="mb-2"
                  size={"14px"}
                    style={{ marginRight: "15px", }}
                  />
                </div>
                <p
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Reduction in water consumption (3-6%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                  className="mb-2"
                  size={"14px"}
                    style={{ marginRight: "15px",}}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Regulate turbulence and overflow due to city pressure
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                  className="mb-2"
                  style={{ marginRight: "15px",  }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Act as a backflow preventer
                </p>
              </div>
            </div>
          </Col>
        </Row> */}
        <Row className="m-0 p-0 text">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              5 Operational Uses
            </h3>
            <div className="smallText align-self-start justify-content-center align-items-start  mt-2">
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Hard water regions
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Hot water uses such as:
                <ul style={{ listStyle: "none" }} className="mt-2">
                  <li
                    className="poppins-Regular "
                    style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                  >
                    <GiCheckMark
                      color="orange"
                      style={{ marginRight: "15px" }}
                    />
                    Water heaters – traditional and tankless
                  </li>
                  <li
                    className="poppins-Regular "
                    style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                  >
                    <GiCheckMark
                      color="orange"
                      style={{ marginRight: "15px" }}
                    />
                    Dishwashers
                  </li>
                  <li
                    className="poppins-Regular "
                    style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                  >
                    <GiCheckMark
                      color="orange"
                      style={{ marginRight: "15px" }}
                    />
                    Washing machines
                  </li>
                  <li
                    className="poppins-Regular "
                    style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                  >
                    <GiCheckMark
                      color="orange"
                      style={{ marginRight: "15px" }}
                    />
                    Boilers
                  </li>
                  <li
                    className="poppins-Regular "
                    style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                  >
                    <GiCheckMark
                      color="orange"
                      style={{ marginRight: "15px" }}
                    />
                    Industrial and processing
                  </li>
                  <li
                    className="poppins-Regular "
                    style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                  >
                    <GiCheckMark
                      color="orange"
                      style={{ marginRight: "15px" }}
                    />
                    Car Washes
                  </li>
                </ul>
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Landscape irrigation
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Wherever water is chemically treated (Cooler/Chillers
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Wherever scale and corrosion occurs
              </p>
            </div>
            <Image fluid src={p32} alt="image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              15 Customer Benefits
            </h3>

            <div className="smallText align-self-start justify-content-center align-items-start  mt-2">
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Scale and corrosion control
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Reduced water & sewer costs
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Reduction in maintenance costs
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Reduction in energy use
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Improvement of water quality
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Reduction in water spots
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Reduction in soap usage
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Benefits of soft water without needing softeners or salt
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Increased equipment lifespan
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Reduction in downtime
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Elimination of chemical and salt handling and storage
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Removal of existing hard water scale deposits
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Protects entire plumbing system
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Keep irrigation nozzles clean
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Increases irrigation water efficiency
              </p>
            </div>
          </Col>
        </Row>
        <Row xs={12} className="m-0 p-0 text">
          <h3
            className="poppins-SemiBold portfolioSmallTitle my-5"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "-12px" } : {}
            }
          >
            Customers in the United States (selected) that uses this Smart Scale
            Buster TM Technology
          </h3>
          <Col xs={6} className="m-0 p-0">
            <ol className={` ${isDesktopOrLaptop && "px-5 mx-4"}  my-3`}>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                NIH National Institute of Health Maryland
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Naval Medical Facilities USA
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                VA Hospitals across USA
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Valley Medical Center (CA USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                North Star Foods (MN USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                PG&E (multiple facilities USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Spirit AeroSystems – Nacelle (KS USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                King’s Daughters’ Hospital (IN USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Abbott Labs (CA USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                CV Therapeutics (CA USA){" "}
              </li>
            </ol>
          </Col>
          <Col xs={6} className="m-0 p-0">
            <ol
              start="11"
              className={` ${isDesktopOrLaptop && "px-5 mx-4"}  my-3`}
            >
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Kaiser Foundation Hospital (CA USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Court Houses (USA)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                US Federal Correction Facilities (prisons)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Police Departments (USA, UK)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Police Training Facilities (USA, UK)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                CFIA (Canadian Food Inspection Agency)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                CSA (Canadian Space Agency)
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                NASA (several facilities USA)
              </li>
            </ol>
          </Col>
          <p
            className="poppins-Regular blue text-center my-1"
            style={
              isTabletOrMobile
                ? { fontSize: "14px", color: "#31a6bf" }
                : { color: "#31a6bf" }
            }
          >
            <i>
              Out of respect for the client, please do not attempt to make
              contact without obtaining permission
            </i>
          </p>

          <Container
            fluid
            className={`m-0 p-0 my-5 d-flex flex-row  justify-content-between ${
              isDesktopOrLaptop ? " px-5" : "px-2"
            }`}
          >
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series1");
              }}
            >
              Series 1
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series2");
              }}
              style={{
                backgroundColor: "#555",
                color: "white",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              Series 2
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series3");
              }}
            >
              Series 3
            </Button>
          </Container>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default ProgramSeries2;
