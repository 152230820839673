import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p65 from "../../assets/images/14. Manufacturing. 1366x768/65.png";
import p66 from "../../assets/images/14. Manufacturing. 1366x768/66.png";
import p67 from "../../assets/images/14. Manufacturing. 1366x768/67.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { caseStudiesAlert } from "../../helper/HelperFunctions";
import { Link } from "react-router-dom";

const Manufacturing = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <Row xs={"12"} className="m-0 p-0 mt-4 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Manufacturing
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image fluid src={p65} alt="image" />
            <Button
              variant="dark"
              // style={{ marginLeft: "40px" }}
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              On average, each one of us 'eats' 3496 litres of water every day;
              that is 3496 litres of an increasingly scarce resource are used in
              the production and manufacturing of our daily foods. The
              percentage of water used in food manufacturing can vary depending
              on the specific processes and products involved. However, on
              average, water usage in the food manufacturing industry ranges
              from 70% to 90% of the total water consumption within the
              facility.
            </p>

            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Technology Adoption Benefits and Applicable to:
              </h3>

              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Food manufacturing
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Paper and pulp manufacturing
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Motor vehicle manufacturing
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Ability to claim 100% of available evaporation credits
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
        </Row>
        <Row className={`m-0 p-0 my-5 ${isDesktopOrLaptop && "px-5 "}`}>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p66} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Panna to Go] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p67} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Sandals] Case Studies
            </Link>
          </Col>
        </Row>
        <Row xs={"12"} className={`m-0 text ${isDesktopOrLaptop && "px-5 "}`}>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Water usage in manufacturing can vary depending on the type of
            industry and the specific manufacturing processes involved.
            Generally, manufacturing operations require water for various
            purposes, including:
          </p>
          <ol
            className="text  "
            style={{ listStyle: "outside", listStyleType: "alpha" }}
          >
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Process Water: Many manufacturing processes require water as a raw
              material or as a coolant, lubricant, or solvent. For example, in
              industries like food and beverage processing, chemicals
              manufacturing, and textiles, water is used for mixing ingredients,
              cleaning, and rinsing.
            </li>
            <br />
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Equipment Cooling: Manufacturing facilities often utilize water
              for cooling equipment and machinery. Water is circulated through
              cooling systems to maintain optimal temperatures and prevent
              overheating of equipment such as compressors, engines, and
              generators.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Cleaning and Sanitation: Water is crucial for maintaining
              cleanliness and hygiene in manufacturing facilities. It is used
              for washing equipment, tools, and work surfaces to prevent
              contamination and ensure product quality and safety.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Steam Generation: Some manufacturing processes require steam,
              which is generated by heating water. Industries such as power
              generation, paper manufacturing, and chemical production utilize
              steam for various applications like heating, drying, and
              sterilization.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Wastewater Treatment: Manufacturing operations generate wastewater
              that may contain pollutants and contaminants. Proper wastewater
              treatment systems are necessary to ensure compliance with
              environmental regulations and minimize the impact on water sources
              and ecosystems.
            </li>
          </ol>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The water usage in manufacturing can have significant implications
            for both the environment and operating costs. Excessive water
            consumption can strain local water resources, increase operational
            expenses, and potentially lead to environmental pollution.
            Therefore, many manufacturing companies are implementing water
            conservation measures and adopting water-efficient technologies to
            reduce water usage, recycle water where possible, and improve
            overall sustainability.
          </p>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            It's important to note that the specific water usage patterns and
            conservation efforts may vary across different industries and
            manufacturing processes. Individual companies should assess their
            water needs, identify areas for improvement, and implement tailored
            strategies to optimize water usage while minimizing waste and
            environmental impact.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Food manufacturing requires water for a wide range of purposes,
            including cleaning and sanitation, ingredient processing, mixing and
            blending, cooking, cooling, and packaging. Water is used extensively
            for cleaning equipment, utensils, and production areas to maintain
            hygiene and food safety standards. It is also an essential component
            in various food processing operations, such as rehydration,
            blanching, and steam cooking. The high water usage in the food
            manufacturing industry is primarily due to the need for cleanliness,
            quality control, and adherence to strict hygiene standards. However,
            it is important to note that water management and conservation
            practices are gaining increased attention within the industry. Many
            food manufacturers are implementing water-saving technologies,
            recycling systems, and efficient water management strategies to
            minimize water consumption, reduce costs, and improve
            sustainability.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            It's worth mentioning that the specific water usage percentages can
            vary depending on factors such as the type of food product,
            manufacturing processes, facility size, and production volumes.
            Additionally, regional factors, such as water availability and local
            regulations, may influence water usage practices in food
            manufacturing.
          </p>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default Manufacturing;
