import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Image } from "react-bootstrap";
import "../../assets/css/waterTech.css";
import { Link } from "react-router-dom";
import p183 from "../../assets/images/29. Gov Services. 1366x768/183.png";
import p184 from "../../assets/images/29. Gov Services. 1366x768/184.png";
import p185 from "../../assets/images/29. Gov Services. 1366x768/185.png";
import broward1 from "../../assets/images/29. Gov Services. 1366x768/broward1.png";
import broward2 from "../../assets/images/29. Gov Services. 1366x768/broward2.png";
import { useMediaQuery } from "react-responsive";
import AboutusNavbar from "../../components/navbars/AboutusNavbar";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

const GovServices = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  
 
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        {/* <Row xs={"12"} className="mt-5  m-0 p-0">
          <AboutusNavbar />
        </Row> */}
        <Row className="m-0 p-0 text">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile ? { fontSize: "22px", marginTop: "4%",marginLeft:'-12px' } : {}
            }
          >
            Gov Services
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={isTabletOrMobile ? { fontSize: "16px",marginLeft:'-12px' } : {}}
          >
            Purchase, Supply, Delivery & Installation Services
          </h3>
          <p
            className={`poppins-Regular ${isDesktopOrLaptop && 'px-5'} `}
            style={
              isTabletOrMobile ? { fontSize: "14px",marginLeft:'-12px' } : { marginLeft: "0.5%" }
            }
          >
            Water Solutions Technology has the hands-on experience and knowledge
            needed to work with procurement professionals, department
            supervisors, and purchasing professionals to meet their specific
            department requirements and project needs.
          </p>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`smallText d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "px-5 py-0" : "p-0"
            }`}
          >
           
            <Image src={p183} alt="Gov Services" fluid />
            <p
              className="poppins-Regular  my-2"
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "300px" }
                  : { maxWidth: "520px" }
              }
            >
              Give us a call today at{" "}
              <span className="red"> 1-954-508-3877 </span> or email
              <span className="blue"> acc@watersolutech.com </span> or{" "}
              <span className="blue"> acc@thesmartvalves.com </span> and let us
              help you with your purchasing needs.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0  ${
              isDesktopOrLaptop ? "px-5 py-1" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Here are Some of Our Localized Certifications:
            </h3>
            <ul className="smallText align-self-start">
              <li className="poppins-Regular">Certified CBE/SBE by Broward County</li>
              <li className="poppins-Regular">
                Broward County Public Schools Certified MBE Business & Vendor
              </li>
              <li className="poppins-Regular">
                Certified by the State of Florida as a Minority Owned Business
              </li>
            </ul>
            <h3
              className="poppins-SemiBold portfolioSmallTitle align-self-start my-4"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The NAICS Codes we Cover: 423720, 423840, 532490{" "}
            </h3>
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              This Is How We Help
            </h3>
            <p
              className="poppins-Regular"
              style={
                isTabletOrMobile ? { fontSize: "14px" } : { marginLeft: "-7px" }
              }
            >
              We provide a tailored method for procuring building materials and
              industrial supplies, eliminating the need for customers to contact
              multiple vendors in search of specific supplies. Instead, by
              simply sharing your project’s building plumbing fixtures and other
              supply list with Water Solutions Technology, we take on the
              responsibility of sourcing the required materials, negotiating
              prices,and organizing the logistics and storage of your order. Our
              primary aim is to simplify and enhance your workflow. We achieve
              this by optimizing the purchasing process for building supplies,
              making your job easier and more efficient.
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col className="m-0 p-0" xs={12} md={12} lg={6}>
            <Image src={broward1} fluid alt="image"  />
          </Col>
          <Col className="m-0 p-0" xs={12} md={12} lg={6}>
            <Image src={broward2} fluid alt="image"/>
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default GovServices;
