import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import "../../assets/css/waterTech.css";
import p33 from "../../assets/images/8. The Smart Integrated Approach.Series 3.1366x768/33.png";
import p34 from "../../assets/images/8. The Smart Integrated Approach.Series 3.1366x768/34.png";
import p35 from "../../assets/images/8. The Smart Integrated Approach.Series 3.1366x768/35.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

function ProgramSeries3(props) {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
 
  useEffect(() => {
    scrollToTop()
   }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            THE SMART INTEGRATED APPROACH
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle blue"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Series 3: The Smart Water Monitor<sup> TM </sup> with Water Eye{" "}
            <sup> TM </sup>
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              What are the risks for not having real-time detection?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Less manual, more control – WaterEye <sup> TM </sup> is your
              single source for managing and monitoring your connected plumbing
              ecosystem. You and your team can keep an eye on your assets’
              operational statuses, analyse performance trends, and ensure
              everything is under control.
              <br />
              <br />
              It is important to detect a leak, but it is even better to
              "pinpoint" where the leaks are occurring, and shut it off
              immediately... This is where we a superior in property water
              management services and technologies.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-3" : "p-4"
            }`}
          >
            <Image fluid src={p33} alt="First Image" />
          </Col>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <Image fluid src={p34} alt="First Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Not being able to have an eye on leaks, booster pump pressures,
              cooling towers & chiller temperatures, has made it difficult for
              building operation and maintenance. Faulty pipe leaks, and faulty
              water meters has resulted in the loss of thousands of dollars in
              water fees as well as item damages due to water soaking. Clogged
              cooling towers / chillers have also caused excess water to be
              drawn and consume more energy than is needed
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start text"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Key Customer Benefits
          </h3>
        </Row>
        <Row className="m-0  p-0">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`smallText d-flex flex-column  align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSmallTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              Empower Your Team with Live Data
            </h3>
            <ul style={{ listStyle: "inside", listStyleType: "square" }}>
              <li className="poppins-Regular">
                Prevent flooding, leaks, or major repairs send alerts in
                real-time
              </li>
              <li className="poppins-Regular">
                Plan ahead with automated maintenance calendar
              </li>
              <li className="poppins-Regular">
                Manage performance better through analytics
              </li>
            </ul>

            <h3
              className="poppins-SemiBold portfolioSmallTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              Apply Metrics To Minimize And Optimize
            </h3>
            <ul style={{ listStyle: "inside", listStyleType: "square" }}>
              <li className="poppins-Regular">Dive deeper into asset trends</li>
              <li className="poppins-Regular">Analyze user patterns</li>
              <li className="poppins-Regular">
                Cut back on water, manual tasks, and costs
              </li>
              <li className="poppins-Regular">
                Monitor <span className="blue"> cooling towers </span>
                (temperature, flow rates and leaks) Send in customised way and
                schedules
              </li>
              <li className="poppins-Regular">
                Monitor pressure of booster pumps and send real-time alters in
                pressure surges or drops
              </li>
            </ul>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`smallText d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSmallTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              Keep Users Flowing Through Their Routine
            </h3>

            <ul style={{ listStyle: "inside", listStyleType: "square" }}>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                Pinpoint peak hours for scheduling
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                Identify demand usage for future projects
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                Maintain product performance, every day
              </li>
              <li
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                Easily generate LEED reports for recertification
              </li>
            </ul>
          </Col>
        </Row>
        <Row className="m-0 p-0 text">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Impact of our Smart Water Monitor <sup>TM </sup> technologies have
            on your building – at the management level
          </h3>
          <h3
            className="poppins-SemiBold portfolioSmallTitle my-3"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "-12px" } : {}
            }
          >
            Building Automation & Management
          </h3>
          <div className={`m-0 p-0 ${isDesktopOrLaptop && "px-5"}  `}>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Integrate Building Automation Systems and Building Management
                Systems to improved overall maintenance and efficiency - real
                time water management –IoT
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                LEED Recertification - generate reports showing water
                conservation
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Track water project savings within the building to simplify and
                analyse water consumption to observe seasonal trends{" "}
              </p>
            </div>
          </div>
        </Row>
        <Row xs={"12"} className="m-0 p-0 mt-4">
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-0" : "p-4"
            }`}
          >
            <Image fluid src={p35} alt="image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle  "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Cooling Towers and Chillers 
            </h3>
            <div className="d-flex flex-row m-0 p-0 ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Cooling towers represent up to 30% of a building’s overall
                energy budget, and 50% of the water used. The Tower solution
                helps commercial, institutional and industrial facilities
                significantly reduce their cooling costs, through:
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0 ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Estimate water lost due to evaporation, drift and blowdown
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Easily determine if your cycles of concentration are maximised
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Project pay back periods (ROI) for facility upgrades and
                maintenance fixtures.
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Minimizing environmental impacts of cooling towers
              </p>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 text">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            How you can benefit, from implementing our smart water monitor ?
          </h3>

          <div className={`m-0 p-0 ${isDesktopOrLaptop && "px-5"} `}>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                  className="mb-2"
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Give grounds for evaporations credits with the local
                municipality
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                  className="mb-2"

                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                On average, the evaporation credit can be upwards to $14,000
                annually per cooling tower
              </p>
            </div>
          </div>
          <Container
            fluid
            className={`m-0 p-0 my-5 d-flex flex-row  justify-content-between ${
              isDesktopOrLaptop ? " px-5" : "px-2"
            }`}
          >
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series1");
              }}
            >
              Series 1
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series2");
              }}
            >
              Series 2
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series3");
              }}
              style={{
                backgroundColor: "#555",
                color: "white",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              Series 3
            </Button>
          </Container>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default ProgramSeries3;
