import React from "react";
import { Col, Container, Figure, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p191 from "../../assets/images/33. The Founders Script/191.png";
import p192 from "../../assets/images/33. The Founders Script/192.png";
import MasterLayout from "../../layouts/MasterLayout";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

const Founder = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
 
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <Row xs={"12"} className="m-0 p-0 mt-4">
          <h4
            className="poppins-SemiBold portfolioTinyTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "-12px" }
                : { marginLeft: "40px" }
            }
          >
            A Founders Script from Clifford
          </h4>
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            A Passion for Water and , a Passion to Share
          </h4>
          <Col
            lg={5}
            sm={12}
            md={6}
            xs={12}
            className={`smallText d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Figure>
              <Figure.Image fluid alt="image" src={p192} />
              <Figure.Caption
                className="poppins-Regular my-2 text-center"
                style={{ fontSize: "12px" }}
              >
                Mr John , Doe (AP. DAP)
              </Figure.Caption>
            </Figure>
            <p className="poppins-Regular blue">
              <i>
                "Beware of little expenses - a small leak will sink a great
                ship" -<span className="orange">Benjamin Franklin</span>."
              </i>
            </p>
          </Col>
          <Col
            lg={7}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Driven by the passion to understand water in all its form, and to
              share those experiences, I found myself wanting to pass on those
              “platinum nuggets” through stewardship. I have become your
              servant, your advocate, your link and champion, adding value to
              every engagement, assisting groups, small medium and large, in
              surmounting obstacles and achieving their objectives related to
              water and energy uses.
              <br />
              <br />
              My stewardship through my scientific and engineering background
              has been effective, shepherding strategic solutions that are,
              expense reduction and savings (OPEX and CAPEX), through innovative
              and sustainable approaches.
              <br />
              <br />
              I have tested that this is how we have collective success: every
              engagement is built on transparency, team effort, due diligence
              and going the extra mile for our clients. In our unwavering
              commitment to our client-centric approach, It's imperative that
              our partners and agents comprehend where our loyalty lies - with
              our clients. We have embraced a wholeheartedly client-centric
              philosophy. We work as a team, adopting a shared mindset to
              guarantee that our clients achieve the most advantageous results.
              <br />
              <br />
              At Water Solutions Technology, we've verified that our path to
              collective success relies on specific principles, based on
              collective transparency. Each of our engagements is meticulously
              crafted on the foundation of transparency, cooperative efforts,
              thorough due diligence, and again, an unwavering dedication to our
              clients.
              <br />
              <br />
              Our approach to compensation is straightforward and equitable.
              Every remuneration earned is disseminated among our partners and
              agents. This ensures that success is experienced collectively and
              that everyone involved reaps the rewards of our endeavours.
              <br />
              <br />
              Utilizing my extensive network of thoroughly assessed experts and
              suppliers, I facilitate access to well-thought-out solutions and
              collaborations that offer advantages to organizations across
              various sectors and sizes.
              <br />
              <br />
              What are you water management pain points or obstacles do you
              intend to surmount?<b className="blue"> Reach out to us today </b>, and we can discuss how
              you might reduce expenses, find new methods to generate revenue,
              and elevate your commitment to your ESG responsibility.
              <br />
              <br />
            </p>
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Founder;
