import React, { useEffect } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";

import p97 from "../../assets/images/23. Speciality Groups - Plumbers. 1366x768/97.png";
import p98 from "../../assets/images/23. Speciality Groups - Plumbers. 1366x768/98.png";
import plumber from "../../assets/images/23. Speciality Groups - Plumbers. 1366x768/plumber.png";
import MasterLayout from "../../layouts/MasterLayout";
import { scrollToTop } from "../../helper/HelperFunctions";

const Plumber = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
 
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            SPECIALITY GROUPS
          </h1>
          <h3
            class="portfolioSubTitle poppins-Regular blue"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "-12px" }
                : { marginLeft: "35px" }
            }
          >
            WATER SOLUTION TECHNOLOGY , WELCOME ALL PLUMBER
          </h3>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start  m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              class="portfolioSubTitle poppins-Regular"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Advantages for the plumber, Partnering with us
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              You can now bring more value to your existing clients using our
              Smart Valves and Smart Program by partnering with us.
              <br />
              <br />
              But it is not only your clients that will benefit, but also you.
              You will reap the benefits of discounts on the Smart Technologies,
              installation referrals and perks, such as waived freight cost and
              <span className="blue">AI generated proposals</span>, in addition
              to having access to our expertise in the Smart technologies. To
              learn more in setting up an account with us, get in touch below!
              Once the form is completed, we will schedule a call to give you
              greater details of the opportunity.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-4" : "p-0"
            }`}
          >
            <Image fluid src={p97} alt="Image" />
          </Col>
        </Row>
        <Row xs={"12"} className="m-0 p-0 mt-4">
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "px-5 py-3" : "p-0"
            }`}
          >
            <Image
              fluid
              src={plumber}
              // className="img img-fluid"
              alt="image"
            />
            <h3
              className="poppins-SemiBold portfolioTinyTitle my-5 align-self-start px-5"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              WATCH OUR WEBINAR SERIES (THE PLUMBER’S BENEFITS)
            </h3>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"20px "}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Additional Services: Installing and maintaining smart water
                valves provides plumbers with a new service to offer to their
                clients. This diversification of services can be financially
                beneficial and attract more customers.
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"20px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Competitive Edge: Plumbing companies that offer our smart
                technology installation and maintenance services gain a
                competitive edge. In a market where customers increasingly seek
                water-saving solutions, being able to offer these services sets
                a plumber apart from the competition.
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"20px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Diverse Clientele: Our smart technologies have applications in
                various settings, from residential homes to commercial and
                industrial properties. This diversity allows plumbers to serve a
                broader clientele.
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"20px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Economic Savings for Clients: Plumbing professionals can
                highlight the long-term economic benefits of smart technology
                for clients. Reduced water bills can offset installation costs
                over time, making it an attractive proposition{" "}
              </p>
            </div>
            <div className="d-flex flex-row m-0 p-0">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"20px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Cutting-Edge Reputation: By adopting and promoting our smart
                technologies, plumbers can position themselves as
                forward-thinking professionals who keep up with the latest
                advancements in the industry.
              </p>
            </div>

            <Container
              fluid
              className={`my-4 d-flex  ${
                isDesktopOrLaptop
                  ? "flex-row justify-content-between"
                  : "flex-column"
              } `}
            >
              <Button
                onClick={() => {
                  window.open(
                    "https://cmsws.alleimran.com/propertyOwner/signup"
                  );
                }}
                variant="dark"
                size="sm"
                className="mb-2 px-4"
              >
                Sign up
              </Button>
              <Button
                variant="dark"
                size="sm"
                className="mb-2 px-4"
                onClick={() => {}}
              >
                Quick Submit Form (Plumbers)
              </Button>
            </Container>
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Plumber;
