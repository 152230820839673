import React, { useState } from "react";
import { Form, Modal, Row, Col, Button, Alert } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import AppContext from "../../context/AppContext";
import { useContext } from "react";
import axios from "axios";

const QuickApply = ({ isVisible, hideModel }) => {
  const { baseUrl } = useContext(AppContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [formValidate, setFormValidate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [role, setRole] = useState("");
  const [cvFile, setCvFile] = useState("");
  const [rolesList, setRolesList] = useState([
    { id: 1, label: "Part-time Data Analyst", value: "Part-time Data Analyst" },
    { id: 2, label: "Part-time Draftsman", value: "Part-time Draftsman" },
    {
      id: 3,
      label: "Contracted Portfolio Sales-rep",
      value: "Contracted Portfolio Sales-rep",
    },
  ]);
  const clearModal=()=>{
    setName('')
    setEmail('')
    setRole('')
    setNumber('')
    setCvFile('')
   
  }
  const submitForm = () => {
    // alert(`${name} ${number} ${email} ${cvFile} ${role}`);
    const formData = new FormData();
    formData.append("role", `${role}`);
    formData.append("name", `${name}`);
    formData.append("phoneNumber", `${number}`);
    formData.append("email", `${email}`);
    formData.append("cv", cvFile);
    axios({
      method: "post",
      url: `${baseUrl}/auth/employee/signup`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data.save) {
          console.log(response.data.newEmployee);
          // setTimeout(() => {
            setShowAlert(true);
          // }, 1000);
          setTimeout(() => {
            setShowAlert(false);
            hideModel();
          }, 3000);
        }
        clearModal()
      })
      .catch((error) => {
        console.log(error);
        hideModel();
      });
  };

  return (
    <Modal
      centered
      show={isVisible}
      onHide={hideModel}
      size={isDesktopOrLaptop ? "md" : "sm"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Present your CV/RESUMÉ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Modal.Title
          className="mb-3"
          style={{ fontSize: isDesktopOrLaptop ? "16px" : "14px" }}
        >
          What role are you applying ?
        </Modal.Title>
        {showAlert && (
          <Alert variant="success">
            Thank you for your application! A member from our team will get back
            to you shortly.
          </Alert>
        )}
        <Form
          noValidate
          validated={formValidate}
          onSubmit={(event) => {
            const form = event.currentTarget;
            event.preventDefault();
            if (form.checkValidity() === false) {
              setFormValidate(true);
              //   event.stopPropagation();
              return;
            }
            submitForm();
          }}
        >
          {rolesList.map((item) => {
            return (
              <Form.Check
                // required
                className="poppins-Regular"
                type={"checkbox"}
                id={item.id}
                label={item.label}
                value={item.value}
                checked={role === item.value}
                style={{ fontSize: "14px" }}
                onChange={(event) => {
                  console.log(event.target.checked);
                  console.log(event.target.value);
                  setRole(event.target.value);
                }}
              />
            );
          })}

          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Label htmlFor="cvFile" className="d-block">
              Attach your CV/Resume
            </Form.Label>
            <Form.Control
              required
              className="placeholderStyle mb-2"
              type="file"
              onChange={(event) => {
                console.log("????????????????????????????????");
                console.log(event.target.files[0]);
                console.log(event.target.files[0].name);
                setCvFile(event.target.files[0]);
              }}
            />
          </Form.Group>
          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput2"
          >
            <Form.Control
              className="placeholderStyle"
              type="text"
              placeholder="Enter your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter name
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput3"
          >
            <Form.Control
              className="mt-2 mb-2 placeholderStyle"
              type="text"
              placeholder="Enter your Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter your phone number.
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput4"
          >
            <Form.Control
              className="placeholderStyle"
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Form.Control.Feedback type="invalid">
              Please enter a valid email address.
            </Form.Control.Feedback>
          </Form.Group>
          <div className="d-grid mt-2 mb-3">
            <Button type="submit" variant="primary" size="md">
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default QuickApply;
