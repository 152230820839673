import React from "react";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import MasterLayout from "../../layouts/MasterLayout";
import { useEffect } from "react";

const SmartValveFAQS = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <h1
          class="portfolioTitle poppins-SemiBold text-center"
          style={
            isTabletOrMobile
              ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
              : { marginLeft: "0px" }
          }
        >
          Frequently Asked Questions (FAQ)
        </h1>
        <Container fluid className={`m-0 p-0  mt-5 text ${isDesktopOrLaptop && 'px-5'}`}>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. Am I really paying for air?!
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Certainly! In fact, air does flow through your water line, and most
            water meters (approximately 99%) operate using the Positive
            Displacement method. In this method, the meter measures the total
            volume of both air and water passing through the line. But because
            air fluctuates, it's challenging to determine how much you're
            actually paying for the air.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. How does the Smart Valve™ measure water?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The Smart Valve™ doesn't remove air from the water. Instead, it
            compresses the air before it reaches your meter. This means the air
            isn't recorded as water volume, ensuring a more accurate
            measurement.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. What's the difference between an adjustable and a non-adjustable
            valve?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            A non-adjustable valve is calibrated before installation, while an
            adjustable valve is calibrated after installation is completed. To
            maximize your savings, any water valve should be calibrated to your
            specific system. Calibrating the Smart Valve™ afterward allows for
            precise calibration, achieving maximum savings without negative
            effects.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. Is the Smart Valve™ custom made?{" "}
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Given the uniqueness of each water system, every Smart Valve™ is
            custom-built to ensure proper calibration with your specific water
            system. This customization ensures optimal performance under your
            location's specific conditions.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. How much money will I save?{" "}
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            On average, our customers experience approximately 20% in savings.
            However, some users have seen savings as high as 35%. The remarkable
            aspect is that once the Smart Valve™ is installed, it works round
            the clock, and you'll continue to realize these savings without
            further effort. It consistently saves you money for as long as it
            remains in your water line.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. How reliable is the Smart Valve™?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The Smart Valve™ is constructed from durable materials like Acetron
            GP and stainless steel, making it flexible and impact-resistant.
            This design ensures it operates reliably for an extended period.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. Will a local plumber know how to install it?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Yes, any licensed plumber can easily install the Smart Valve™ since
            it's designed to fit standard pipe sizes. While the exact savings
            depend on various factors, the average customer achieves around 20%
            savings after installing the Smart Valve™.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. What is the Smart Valve™ guarantee?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Every Smart Valve™ comes with a 90-Day No Questions Asked
            Satisfaction Guarantee.
          </p>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. What about warranty?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Each Smart Valve™ is backed by our 10-Year Manufacturer’s Limited
            Warranty.
          </p>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q. How do I know if Smart Valve™ is right for me?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Reach out to us, and we'll help you determine which smart water
            control system is best for your property. We've assisted thousands
            in saving significantly on their bills, much like our friends at
            PES.
            <br />
            <br />
            Eliminate Water Meter Inaccuracies: The Smart Valve™, as part of the
            Water Solutions Technology ™ system, can rectify inaccuracies in
            water meters through the SMART VALVE’s™ Variable Flow Control
            process, effectively eliminating inaccuracies and excessive water
            consumption. For examples of projects before and after Smart Valve™
            installation, visit here (Projects).
            <br />
            <br />
            Water Solutions Technology Savings Solutions The Water Solutions
            Technology ™ offers numerous water conservation solutions that don't
            necessitate major changes to your units. Learn more about the
            considerable savings potential with the Smart Valve™ and other Smart
            Water Technologies.”
          </p>
        </Container>
      </Container>
    </MasterLayout>
  );
};
export default SmartValveFAQS;
