import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p81 from "../../assets/images/19. Supermarkets & Grocery Stores. 1366x768/81.png";
import p84 from "../../assets/images/19. Supermarkets & Grocery Stores. 1366x768/fri.png";
import p83 from "../../assets/images/19. Supermarkets & Grocery Stores. 1366x768/kro.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { Link } from "react-router-dom";
import { caseStudiesAlert } from "../../helper/HelperFunctions";

const SuperMarket = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Supermarkets & Grocery Stores
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p81}
              // className="img img-fluid"
              alt="image"
            />
            <Button
              variant="dark"
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The exact amount of water used in a supermarket can vary based on
              factors such as the store size, number of customers, and the
              efficiency of water management systems. While it is challenging to
              provide precise daily water usage figures as it can vary widely,
              estimates suggest that supermarkets can use anywhere from 50 to
              100 gallons of water per 1,000 square feet of sales area per day
            </p>

            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Technology Adoption Benefits :
              </h3>

              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Ability to claim 100% of available evaporation credits
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
        </Row>
        <Row className={`m-0 p-0 my-5 ${isDesktopOrLaptop && "px-5 "}`}>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={4}
          >
            <Image fluid src={p83} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Kroger ,Portland Oregon] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={4}
          >
            <Image fluid src={p84} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Fred Meyer Pacific Northwest] Case Studies
            </Link>
          </Col>
        </Row>
        <Row xs={"12"} className={`${isDesktopOrLaptop && "px-5"}  m-0 text`}>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Water usage in supermarkets can vary based on the size of the store,
            the number of customers served, and the specific operations
            involved. Here are some areas where water is commonly used in
            supermarkets:
          </p>
          <ol className="text px-4  ">
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Cooling Systems: Supermarkets have refrigeration systems to keep
              perishable items fresh. These systems require water for cooling
              purposes, such as in condensers and evaporative coolers.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Produce and Floral Department: Water is used for misting or
              spraying produce and flowers to keep them hydrated and maintain
              their freshness and appearance.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Deli and Meat Departments: Water is used for cleaning equipment,
              such as meat slicers, and for maintaining hygiene standards in
              food preparation areas.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Bakery Department: Water is used in baking processes, such as
              dough mixing, dough sheeting, and cleaning baking equipment and
              utensils.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Cleaning and Sanitation: Supermarkets require water for general
              cleaning of floors, shelves, checkout counters, and restrooms.
              Water is also used for sanitizing surfaces and equipment to ensure
              food safet
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Restrooms: Water is used for flushing toilets, handwashing sinks,
              and maintaining restroom cleanliness.
            </li>
          </ol>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The exact amount of water used in a supermarket can vary based on
            factors such as the store size, number of customers, and the
            efficiency of water management systems. While it is challenging to
            provide precise daily water usage figures as it can vary widely,
            estimates suggest that supermarkets can use anywhere from 50 to 100
            gallons of water per 1,000 square feet of sales area per day.
          </p>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Water conservation measures can help reduce water usage in
            supermarkets. Some practices include the use of water-efficient
            fixtures and appliances, regular maintenance to detect and repair
            leaks, and employee training on water-saving techniques.
            Implementing water-recycling systems, such as capturing and reusing
            condensate water from refrigeration units, can further contribute to
            water conservation efforts
          </p>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Supermarkets can also participate in water-saving programs,
            implement smart irrigation systems for outdoor landscaping, and
            promote awareness among employees and customers about the importance
            of water conservation.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            By adopting water-saving practices, supermarkets can reduce their
            environmental impact, conserve water resources, and contribute to
            sustainability in the retail sector. The Supermarket water usage has
            been on the rise in recent months – purely to include services such
            a fast-moving consumer goods or on demand meals. Furthermore, most
            supermarket has now adopted the continuous water system, to keep
            micro-greens and other vegetables fresh through water mist blowing
            process. This has driven the water demand for supermarkets.
          </p>
          <div className="m-0 p-0">
            <Button
              variant="dark"
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </div>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default SuperMarket;
