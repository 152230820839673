import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p119 from "../../../assets/images/27. Latest In Property Water Management.1366x768/119.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail2 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How Can Hoteliers Benefit by Making Smart Technologies Part Of
              Their Corporate Water Management Strategy
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              As a property owner or manager, how many times have you look to
              chop your utility fees to start crunching back on your net
              operating income (NOI)? Countless times…
            </p>
            <Image fluid src={p119} alt="image" />
            <br />
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Hotel’s Expense and Budgets
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Utility cost accounts for one of the largest annual expenses faced
              by an hotel’s budget, with water and sewer accounting for nearly
              25% of the total utility costs, a report generated by PKF
              Hospitality Research (A CBRE company). It has been agreed among
              hotel General Managers that the cost associated with water and
              sewer per hotel is “substantial and should be controlled”.
              Consequently, new solutions and technologies are needed to address
              water consumption and usage to help operators and owners of
              hospitality /assets.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Hotel Guests
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Controlling water usage and water expenses for individual
              properties are currently practiced by three “old” strategies,
              either repairing old plumbing fixtures, replacing them with
              products that slow water flow, or discouraging the daily perk of
              cleaning the linen in the guest room. The results for these
              practices are very clear, such as less water coming out of the
              shower heads and faucets, passing on poor guest experiences.
              Unfortunately, hotels are further forced to pass on the rising
              cost of water fees to their guests, through increased room rates,
              because of the elevated amounts coming from city municipalities
              annually (2%-6%).
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Water Management Approach That Always Wins (TheWaterEyeTM)
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Innovations that provide more precise water-related data, tools
              and strategies are helping hotels implement better water
              management initiatives. The integrated approach program helps to
              minimize this problem at the point of entry (POE) just at the
              water flow meter and consist of these processes:
              <br />
              1. The Series 1: smart technology (Accurate water metering™)
              <br />
              2. The Series 2: smart technology (Real-time alert and control™)
              <br />
              3.The Series 3: smart technology (Pipe health & water recovery™)
              <br />
            </p>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              This unique program may utilize one, two or all three technologies
              depending on the building requirements and management goals.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              With the installation of the smart water valve system downstream,
              close to the city meter, water will enter the property with
              entrained air, until it passes through the Series 1: smart
              technology. This prevents the meter from reading the air, that
              would have otherwise expanded, leading to meter inaccuracies
              (learn about meter inaccuracies and water billing statements). The
              end result is two fold, better guest experience and significant
              savings on the Hotel’s water fees. Customers have seen 10-40%
              savings on monthly water fees.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Installation of the The Series 2: smart technology (Smart IoTTM)
              can be done at predefined building set points, such as at the
              water flow meter (POE), at your booster pumps all they way to the
              exit (post Chillers/Cooling Towers). Once installed, water usage
              can be tracked and leaks detected immediately with reports
              delivered -hourly, -daily, -weekly, monthly and annually. Alerts
              can be received, via SMS, e-mails, or by simply logging into
              portal.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              If your building suffers from clogging due to hard water, then
              installing The Series 3: smart technology (Non-invasive pipe
              health and water recovery) will improve your pipelines by removing
              clogging due to crystallisation of minerals as well as the
              minerals that will cause new clogging. This technology improves
              your pipe’s health, improve cooling efficiencies and reduce water
              usage by up to 40%. Learn more about pipe health and water
              recovery benefits.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Where Does Good Hotel Water Management Start?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              When good water management is being hindered by slowly detected
              leaks, pipe breakages, regular changes of plumbing fixtures and
              rising water fees that seem uncontrollable, begin by seeking a
              <u>property water management company </u> that specialising in
              achieving sustainable water goals. One that takes the time to
              identify the challenges and present reasonable and achievable
              solutions. The best <u>property water management company </u> will
              have a team of devoted experts in water and building automation
              that communicates clearly who take your team of engineers by the
              hand to ensure the technology is right and ensure your corporate
              water management goals are realised.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              To find out how we can help your Hotel’s water management
              initiatives and savings, contact<u> Water Solutions Technology</u>{" "}
              or request a meeting to demonstrate the technologies.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail2;
