import React from "react";
import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
import MasterLayout from "../../layouts/MasterLayout";
import { useMediaQuery } from "react-responsive";
import P34 from "../../assets/images/P34.png";
import P35 from "../../assets/images/P35.png";
import P36 from "../../assets/images/P36.png";
import P31 from "../../assets/images/P31.png";
import { Link } from "react-router-dom";
import WorkwithusNavbar from "../../components/navbars/WorkwithusNavbar";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";
import CustomQuotes from "../../components/customQuotes/CustomQuotes";
import { useState } from "react";

const OurProducts = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [customModal,setCustomModal]=useState(false)
 
  useEffect(()=>{
    scrollToTop()
  },[])

  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        {/* <Row xs={"12"} className="  m-0 p-0">
        <WorkwithusNavbar />
      </Row> */}
        <Row xs={"12"} className="  m-0 p-0  ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            OUR PRODUCTS & IMPACT{" "}
          </h1>
        </Row>
        <Row className={`m-0 p-0 ${isDesktopOrLaptop && " px-5"} `}>
          <Col
            xs={12}
            md={4}
            lg={4}
            className={`m-0 p-0  d-flex flex-column align-items-center justify-content-center smallText   `}
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 m-0 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSubTitle"
                style={
                  isTabletOrMobile
                    ? { fontSize: "16px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                THE SMART VALVES<sup>TM</sup>
              </Card.Title>
              <Card.Img
                alt="image"
                src={P34}
                className="img img-fluid my-5 "
                // style={{
                //   maxHeight: "48%",
                //   maxWidth: "60wh",
                // }}
              />
              <Card.Body className="p-0 m-0  text d-flex flex-column align-self-center ">
                <Card.Subtitle
                  className=" poppins-SemiBold portfolioTinyTitle  text-center mt-2"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                 30% Reduction in Water Fees
                </Card.Subtitle>
                <Button
              variant="dark"
              size="sm"
              className="mt-4 "
              onClick={()=>{
                setCustomModal(true)
              }}
              // style={{ marginLeft: "10%", marginRight: "10%" }}
            >
              Get Custom Quote
            </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className={`m-0 p-0  d-flex flex-column align-items-center justify-content-center smallText   `}
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 m-0 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSubTitle"
                style={
                  isTabletOrMobile
                    ? { fontSize: "16px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                THE SMART VALVES<sup>TM</sup>
              </Card.Title>
              <Card.Img
                alt="image"
                src={P35}
                className="img img-fluid "
                // style={{
                //   maxHeight: "48%",
                //   maxWidth: "60wh",
                // }}
              />
              <Card.Body className="p-0 m-0  text d-flex flex-column align-self-center " >
                <Card.Subtitle
                  className=" poppins-SemiBold portfolioTinyTitle  text-center"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                 25 % Reduction in Energy Cost & Increase Water Upcycle
                </Card.Subtitle>
                <Button
              variant="dark"
              size="sm"
              className="mt-4"
              style={{ marginLeft: "19%", marginRight: "19%" }}
              onClick={()=>{
                setCustomModal(true)
              }}
            >
              Get Custom Quote
            </Button>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className={`m-0 p-0  d-flex flex-column align-items-center justify-content-center smallText   `}
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 m-0 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSubTitle"
                style={
                  isTabletOrMobile
                    ? { fontSize: "16px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                THE SMART VALVES<sup>TM</sup>
              </Card.Title>
              <Card.Img
                alt="image"
                src={P36}
                className="img img-fluid "
                // style={{
                //   maxHeight: "48%",
                //   maxWidth: "60wh",
                // }}
              />
              <Card.Body className="p-0 m-0  text d-flex flex-column align-self-center ">
                <Card.Subtitle
                  className=" poppins-SemiBold portfolioTinyTitle  text-center"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                 Real Time Detection of Leaks and Shutoff
                </Card.Subtitle>
                <Button
              variant="dark"
              size="sm"
              className="mt-4"
              style={{ marginLeft: "16%", marginRight: "16%" }}
              onClick={()=>{
                setCustomModal(true)
              }}
            >
              Get Custom Quote
            </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-5">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={isTabletOrMobile ? { fontSize: "16px",marginLeft:'-12px' } : {}}
          >
            Impact our technologies have on your building – at the property
            level
          </h3>

          <Col
            lg={6}
            md={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Building Automation & Management
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Integrate Building Automation Systems and Building Management
              Systems to improved overall maintenance and efficiency - real time
              water management –IoT LEED Recertification - generate reports
              showing water conservation Track water project savings within the
              building to simplify and analyse water consumption to observe
              seasonal trends.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Cooling Towers and Chillers
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Cooling towers represent up to 30% of a building’s overall energy
              budget, and 50% of the water used. The Tower solution helps
              commercial, institutional and industrial facilities significantly
              reduce their cooling costs, through: Estimate water lost due to
              evaporation, drift and blowdown Easily determine if your cycles of
              concentration are maximised Project pay back periods (ROI) for
              facility upgrades and maintenance fixtures. Minimizing
              environmental impacts of cooling towers.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How you can benefit, from the smart wireless sensor even more $?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Justify evaporations credits with the local municipality On
              average, the evaporation credit can be upwards to $14,000 annually
              per cooling tower.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}          >
            <Image fluid
              src={P31}
              alt="image"
              // className="img img-fluid"
              // style={{
              //   maxHeight: isTabletOrMobile && "40%",
              //   maxWidth: isTabletOrMobile && "50wh",
              // }}
            />
          </Col>
        </Row>
      </Container>
      <CustomQuotes isVisible={customModal} hideModel={()=>{
        setCustomModal(false)
      }} />
    </MasterLayout>
  );
};

export default OurProducts;
