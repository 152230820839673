import React, { useState } from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import { Link } from "react-router-dom";
import P64 from "../../assets/images/P64.jpg";
import QuickApply from "../../components/quickApply/QuickApply";
import { useEffect } from "react";

const DataAnalyst = () => {
  const [show,setShow]=useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : { marginLeft: "0px" }
            }
          >
            PART-TIME DATA ANALYST
          </h1>
        </Row>
        <Row className={`m-0 p-0 ${isDesktopOrLaptop && 'px-5'}  text`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Duties and responsibilities
          </h3>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Data Analysts collect data, such as sales numbers or market
            research, to help companies make the right decisions. This work
            encompasses several duties and responsibilities, including:
          </p>
          <ul className={`${isDesktopOrLaptop && 'px-5'} text`}>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Work with executives and other business leaders to identify
              opportunities for improvement
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Create reports for internal teams and/or external clients
            </li>

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Collaborate with team members to collect and analyze data{" "}
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Use graphs, infographics and other methods to visualize data{" "}
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Establish KPIs to measure the effectiveness of business decisions{" "}
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Structure large data sets to find usable information{" "}
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Work with a team of analysts and other associates to process
              information{" "}
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Create presentations and reports based on recommendations and
              findings
            </li>
          </ul>
        </Row>
        <Button
          variant="dark"
          size="sm"
          className="poppins-Regular "
          style={{ marginLeft: "7%" }}
          onClick={()=>{
            setShow(true)
          }}
        >
          Quick Apply
        </Button>
      </Container>

      <QuickApply isVisible={show} hideModel={()=>{
        setShow(false)
      }} />
    </MasterLayout>
  );
};

export default DataAnalyst;