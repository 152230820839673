import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import colorlogo from "../../assets/images/color-logo.png";
import { VscThreeBars } from "react-icons/vsc";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";

const WorkwithusNavbar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  useEffect(() => {
    // alert(window.location.pathname);
  }, []);
  return (
    <Navbar
      // expand="lg"
      expand
      className="m-0 p-0 bg-body-light"
    >
      <Container
        fluid
        className=" p-0 m-0 px-5 mx-3 "
      >
        <Navbar.Toggle aria-controls="responsive-navbar-nav  " />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="me-auto"
            // activeKey={window.location.pathname}
          >
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to={"/workWithus/consulting"}
              active={window.location.pathname === "/workWithus/consulting"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              CONSULTING
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/workWithus/ourproducts"
              active={
                window.location.pathname === "/workWithus/ourproducts"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              OUR PRODUCTS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/workWithus/plumber"
              active={window.location.pathname === "/workWithus/plumber"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              PLUMBERS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/workWithus/propertyowners"
              active={
                window.location.pathname === "/workWithus/propertyowners"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              PROPERTY OWNERS/MANAGERS
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/workWithus/matters"
              active={
                window.location.pathname === "/workWithus/matters"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              SUSTAINABILITY MATTERS
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default WorkwithusNavbar;
