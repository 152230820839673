import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p106 from "../../assets/images/27. Latest In Property Water Management.1366x768/106.png";
import p107 from "../../assets/images/27. Latest In Property Water Management.1366x768/107.png";
import p108 from "../../assets/images/27. Latest In Property Water Management.1366x768/108.png";
import p109 from "../../assets/images/27. Latest In Property Water Management.1366x768/109.png";
import p110 from "../../assets/images/27. Latest In Property Water Management.1366x768/110.png";
import p111 from "../../assets/images/27. Latest In Property Water Management.1366x768/111.png";
import p112 from "../../assets/images/27. Latest In Property Water Management.1366x768/112.png";
import p113 from "../../assets/images/27. Latest In Property Water Management.1366x768/113.png";
import p114 from "../../assets/images/27. Latest In Property Water Management.1366x768/114.png";
import p115 from "../../assets/images/27. Latest In Property Water Management.1366x768/115.png";
import p120 from "../../assets/images/27. Latest In Property Water Management.1366x768/120.png";
import Blog from "../../components/blogs/Blog";
import MasterLayout from "../../layouts/MasterLayout";

const Resources = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        {/* <Row xs={"12"} className="  m-0 p-0">
          <AboutusNavbar />
        </Row> */}
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row
          xs={12}
          className={`m-0 p-0  d-flex align-items-center ${
            isDesktopOrLaptop ? "px-4" : "px-3"
          }`}
        >
          <Col className="m-0  p-3 p-lg-0" xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail1"}
              date={"SEPTEMBER 21, 2020"}
              title={
                "High Traffic: the impact it will have on your water usage and associated utility fees"
              }
              imageUri={p106}
            />
          </Col>
          <Col className="m-0 p-3 p-lg-0" xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail2"}
              date={"SEPTEMBER 21, 2017"}
              title={
                "How Can Hoteliers Benefit by Making Smart Technologies Part Of Their Corporate Water Management Strategy"
              }
              imageUri={p107}
            />
          </Col>
          <Col className="m-0  p-3 p-lg-0" xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail3"}
              date={"June 21, 2020"}
              title={
                "How Water Meter Inaccuracies, Increase Your Property Water Fees Significantly."
              }
              imageUri={p108}
            />
          </Col>
          <Col className="m-0  p-3 p-lg-0" xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail5"}
              date={"SEPTEMBER 21, 2017"}
              title={
                "How unhealthy pipelines lead to an increase and unplanned in operational spending"
              }
              imageUri={p109}
            />
          </Col>
          <Col className={`m-0  p-3 p-lg-0`} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail8"}
              date={"SEPTEMBER 21, 2019"}
              title={
                // "Why The Cost To Install The Intelligent Water Valve Propel NOI, and Is Won Back On ROI ?"
                "The Cap Rate Influencer : Lowering Water Costs Increases Asset Value"
              }
              imageUri={p110}
            />
          </Col>
          <Col className={`m-0  p-3 p-lg-0`} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail6"}
              date={"SEPTEMBER 21, 2022"}
              title={
                "Water-Saving Plumbing Fixtures: A Recipe for Success in Food Manufacturing"
              }
              imageUri={p120}
            />
          </Col>
          <Col className={`m-0 p-3 p-lg-0 `} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail7"}
              date={"SEPTEMBER 21, 2019"}
              title={
                "Cost Benefit Analysis: Water-Saving Plumbing Fixtures for Food Manufacturing Facilities"
              }
              imageUri={p111}
            />
          </Col>
          <Col className={`m-0 p-3 p-lg-0 `} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail4"}
              date={"SEPTEMBER 21, 2019"}
              title={
                // "The Cap Rate Influencer : Lowering Water Costs Increases Asset Value"
                "Why The Cost To Install The Intelligent Water Valve Propel NOI, and Is Won Back On ROI ?"
              }
              imageUri={p112}
            />
          </Col>
          <Col className={`m-0 p-3 p-lg-0 `} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail9"}
              date={"SEPTEMBER 21, 2019"}
              title={
                "Why Environmental Stewardship: with Smart Water Solutions leads to Greener Campuses"
              }
              imageUri={p113}
            />
          </Col>
          <Col className={`m-0 p-3 p-lg-0 `} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail10"}
              date={"SEPTEMBER 21, 2023"}
              title={
                "10 Steps to Advance Water Efficiency: Property Management"
              }
              imageUri={p114}
            />
          </Col>
          <Col className={`m-0 p-3 p-lg-0 `} xs={6} md={4} lg={3}>
            <Blog
              to={"/aboutUs/resources/blogdetail11"}
              date={"OCTOBER 21, 2023 (UPDATED)"}
              title={
                "Understanding How Your Water Flow Meter Works: Property Management"
              }
              imageUri={p115}
            />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Resources;
