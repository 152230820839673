import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Button, Col, Container, Figure, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import P39 from "../../assets/images/P39.png";
import p100 from "../../assets/images/24. The Property Owner  and Property  Manager. 1366x768/100.png";
import p99 from "../../assets/images/24. The Property Owner  and Property  Manager. 1366x768/99.png";
import "../../assets/css/waterTech.css";
import WorkwithusNavbar from "../../components/navbars/WorkwithusNavbar";
import { GiCheckMark } from "react-icons/gi";
import { Link } from "react-router-dom";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import { useState } from "react";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

const PropertyOwners = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
     
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            SPECIALITY GROUPS
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "py-0 px-5 " : "p-0"
            }`}
          >
            <h3
              class="portfolioSubTitle poppins-SemiBold align-self-start"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Property Owner and Property Manager
            </h3>
            <Image fluid src={p99} alt="Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start  m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSmallTitle blue"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Unlocking new revenue and sustainable opportunities – at no cost
              to you.
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              You can now bring simultaneous value to your properties and
              existing building users using our Water Technologies TM, Smart
              Valve TM technology by joining our program – and it's free. At no
              cost to you – we will work together to find new revenue
              opportunities. This is ideal and beneficial for
              individuals/companies that manages properties at all scales.
              <br />
              <br />
              The cost-saving benefits extend further to economic resilience. By
              reducing water expenses, property owners are better equipped to
              weather economic downturns or unexpected crises. Water-saving
              technologies contribute to the long-term stability and financial
              health of properties. This can be particularly vital for
              multi-unit residential buildings, commercial complexes, and
              industrial facilities. During times of economic volatility,
              properties that have adopted these technologies will find
              themselves better positioned to navigate challenges and maintain
              profitability.
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0 align-items-center smallText">
          <h3
            class="portfolioSubTitle poppins-SemiBold text-center my-4 "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            As a Property Owner and Manager - What Is Your Water Use Intensity ?
          </h3>
          <Figure>
            <Figure.Image
              height={window.innerHeight}
              width={window.innerWidth}
              alt="image"
              src={P39}
            />
            <Figure.Caption className="poppins-Regular text-center my-3">
              U.S. National Water Use Intensity (WUI) Reference Values for
              Select Portfolio Manager Property Types{" "}
            </Figure.Caption>
          </Figure>
        </Row>
        <Row xs={"12"} className="m-0 p-0 mt-4">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Why Property Owners and Property Managers has Confidence in Our
              Proven Technologies?
            </h3>

            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Over 20,000 installed and counting{" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                US Patented and Engineered{" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Zero Maintenance for all our Equipment{" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Short Payback Period, with Fast ROI (12-24 months){" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                90-Day Guaranteed – all your money back *
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Green Building Enhancement (Asset Value)
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                NSF Approved{" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Independent Studies{" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                10 Year Manufacturing Warranty{" "}
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Revenue Generator
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center justify-content-center m-0  ${
              isDesktopOrLaptop ? "px-0 py-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p100}
              alt="image"
            />
            <h3
              className="poppins-SemiBold portfolioTinyTitle m-3 align-self-start px-5 "
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              Property owners
            </h3>
          </Col>
        </Row>
        <Row className={`m-0 p-0  ${isDesktopOrLaptop && "px-5"} text`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Not enough dedicated capital to do a portfolio roll out?
          </h3>
          <p
            className="poppins-Regular "
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            With our smart water savings programme, you don’t have to stand the
            upfront cost of the technologies (Smart Water Valves TM, Scale
            Remover TM, Smart Water Monitor TM Kits). Instead, we will set up a
            program that benefits you, for years to come. We are proud when
            owners and managers make smarter decisions, on using the smart
            technologies.
            <br />
            <br />
            To learn more about this smart savings program,{" "}
            <Link className="link poppins-SemiBold" to={"/costsaving"}>
              READ MORE
            </Link>
          </p>
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            What solutions are you interested in ?
          </h3>
          <div className="m-0 p-0 px-3">
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                New Revenue Opportunities
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Strategic Partnerships
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Sustainability Solutions
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Cost-Savings and reduce Operating Income
              </p>
            </div>
          </div>
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Don’t worry about the upfront costs!
          </h3>
        </Row>

        <Row className="m-0 p-0  mt-5">
          <Col
            lg={4}
            md={12}
            sm={12}
            className="p-0 m-0 text-center smallText  "
            style={{}}
          >
            <Button variant="dark" className="">
              Quick Form (Plumber)
            </Button>
            <p
              className="poppins-Regular mx-5 my-2"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              If you have read and understood and ready to move forward, sign up
              with this link
            </p>
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            className="p-0 m-0 text-center smallText "
            style={{}}
          >
            <Button
              variant="dark"
              className=""
              onClick={() => {
                setShow(true);
              }}
            >
              Quick Contact
            </Button>
            <p
              className="poppins-Regular mx-5 my-2"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              If you would like us to get in touch with you, please submit this
              form
            </p>
          </Col>
          <Col
            lg={4}
            md={12}
            sm={12}
            className="p-0 m-0 text-center smallText "
            style={{}}
          >
            <Button
              onClick={() => {
                window.open("https://cmsws.alleimran.com/propertyOwner/signup");
              }}
              variant="dark"
              className=""
            >
              Quick Form (Owner/Manger)
            </Button>
            <p
              className="poppins-Regular mx-5 my-2"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              If you have read and understood and ready to move forward, sign up
              with this link
            </p>
          </Col>
        </Row>
      </Container>
      <QuickConnect
        isVisible={show}
        hideModel={() => {
          setShow(false);
        }}
      />
    </MasterLayout>
  );
};

export default PropertyOwners;
