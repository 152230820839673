import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import {
  Container,
  Form,
  InputGroup,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";
import { FaUser, FaLock } from "react-icons/fa";
import "../../assets/css/waterTech.css";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useEffect } from "react";

function Login(props) {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [rememberMe, setRememberMe] = React.useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="loginContainer">
        <Row className=" d-flex flex-row justify-content-center mb-5 pb-5 pt-4">
          <h3
            className="poppins-Light portfolioSubTitle text-white text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Welcome to the Water Solution’s Technology ResourceSpace. Please log
            in.
          </h3>
          <Container fluid className="m-0 p-0  smallText">
          <p 
            className="poppins-Regular text-center text-white mt-4"
            style={isTabletOrMobile ? { fontSize: "12px" } : {marginLeft:'22%',marginRight:'22%'}}
          >
            If you have signed up and have not yet receive a confirmation. This
            means your application is still pending and we ask to remain patient
            as we reach back out to you with your account details.
          </p>
          </Container>
          <Col lg={3} md={6} sm={6} xs={8} className="mt-2">
            <Form className="mt-5">
              <InputGroup
                size={isDesktopOrLaptop ? "md" : "sm"}
                className="mb-3 mt-5"
              >
                <InputGroup.Text id="basic-addon1">
                  <FaUser color="GrayText" />
                </InputGroup.Text>
                <Form.Control
                  className="placeholderStyle poppins-Light"
                  placeholder="Username"
                  aria-label="Username"
                  aria-describedby="basic-addon1"
                  size="md"
                />
              </InputGroup>

              <InputGroup
                size={isDesktopOrLaptop ? "md" : "sm"}
                className="mb-3"
              >
                <InputGroup.Text id="basic-addon2">
                  <FaLock color="GrayText" />
                </InputGroup.Text>
                <Form.Control
                  className="placeholderStyle poppins-Light"
                  type="password"
                  placeholder="Password"
                  aria-label="Username"
                  aria-describedby="basic-addon2"
                  size="md"
                />
              </InputGroup>
              <div className="d-grid mt-5 mb-3 ">
                <Button
                  variant="primary"
                  size={isDesktopOrLaptop ? "md" : "sm"}
                >
                  LOGIN
                </Button>
              </div>

              <Row className="m-0 p-0 mt-5 mb-4 ">
                <Col
                  xs={12}
                  lg={6}
                  className="m-0 p-0 d-flex align-items-start"
                >
                  <Form.Check
                    className="poppins-Regular "
                    reverse
                    type="checkbox"
                    id="autoSizingCheck"
                    label="Remember me"
                    onClick={() => {
                      console.log("Checkbox Clicked");
                      setRememberMe(!rememberMe);
                    }}
                    style={{
                      color: "white",
                      fontSize: isTabletOrMobile ? "12px" : "",
                    }}
                  />
                </Col>
                <Col
                  xs={12}
                  lg={6}
                  className={`m-0 p-0 d-flex ${
                    isDesktopOrLaptop
                      ? "justify-content-end"
                      : "align-items-start mt-3"
                  }  `}
                >
                  <Link
                    // to={'/forgotPassword'}>
                    className="link poppins-Regular "
                    style={{
                      color: "white",
                      fontSize: isTabletOrMobile && "12px",
                    }}
                  >
                    Forgot password?
                  </Link>
                </Col>
              </Row>

              <Form.Text
                className="poppins-Regular d-flex flex-row justify-content-center"
                style={{ color: "white", fontSize: isTabletOrMobile && "12px" }}
              >
                <FaUser color="white" size={isDesktopOrLaptop ? "18" : "14"} />+
                Don't have an account ?{"  "}
                <Link
                  className="link poppins-SemiBold"
                  style={{ color: "white" }}
                >
                  Sign up
                </Link>
              </Form.Text>
            </Form>
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
}
export default Login;
