import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p133 from "../../../assets/images/27. Latest In Property Water Management.1366x768/113.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail9 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Why Environmental Stewardship: with Smart Water Solutions leads to
              Greener Campuses
            </h3>
            <br />

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              In today's fast-evolving educational landscape, schools and
              universities are continually seeking ways to optimize their
              resources. Every dollar saved can be redirected into improving the
              learning environment for students. This is where smart water
              technologies come into play. They offer an ingenious solution to
              reduce operational costs and promote environmental sustainability,
              ultimately improving the financial health of educational
              institutions.
            </p>
            <Image
              src={p133}
              alt="image"
              style={{ height: "15%", width: "95%", objectFit: "fill" }}
            />
            <p
              className="poppins-Regular mt-3"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Environmental stewardship is no longer a distant ideal; it's a
              present-day imperative, especially for educational institutions.
              Schools and universities are taking proactive steps to minimize
              their impact on the environment and lead the way in
              sustainability. An essential component of this journey is the
              integration of smart water solutions to create greener campuses.
              <br />
              <br />
              The statistics are staggering. On average, educational
              institutions consume over 262,000,000 gallons of potable water
              annually. This not only places a significant strain on the water
              supply but also incurs substantial expenses. With the rising focus
              on sustainability, these excess costs are ripe for reduction, and
              smart water solutions provide the answer.
              <br />
              <br />
              Smart water technologies are revolutionizing how campuses manage
              their water resources. These technologies encompass a range of
              innovations, including state-of-the-art metering systems and
              intelligent valves. By implementing these solutions, educational
              institutions can significantly reduce their water consumption,
              allowing them to align with sustainability goals.
              <br />
              <br />
              It's not just about cost savings; it's also about creating greener
              and more eco-friendly campuses. Responsible water management helps
              schools reduce their environmental footprint. Students, faculty,
              and staff become part of an institution that cares about the
              planet and actively works towards reducing its impact. This, in
              turn, fosters a culture of environmental awareness and
              responsibility among the campus community.
              <br />
              <br />
              In summary, smart water solutions are a fundamental component of
              campus sustainability initiatives. They contribute to greener
              campuses, reduced operational costs, and a more sustainable
              future, setting an example for future generations.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail9;
