import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
  Table,
} from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { IoWarning } from "react-icons/io5";
import { PiSealCheckFill } from "react-icons/pi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import P14 from "../../assets/images/P14.png";
import P34 from "../../assets/images/P34.png";
import p53 from "../../assets/images/11. History & Comparison Of The Valve Technology. 1366x768/53.png";
import p54 from "../../assets/images/11. History & Comparison Of The Valve Technology. 1366x768/54.png";
import p55 from "../../assets/images/11. History & Comparison Of The Valve Technology. 1366x768/55.png";
import p56 from "../../assets/images/11. History & Comparison Of The Valve Technology. 1366x768/56.png";
import p57 from "../../assets/images/11. History & Comparison Of The Valve Technology. 1366x768/57.png";
import MasterLayout from "../../layouts/MasterLayout";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import { scrollToTop } from "../../helper/HelperFunctions";

function History(props) {
  const [showSecondImage, setShowSecondImage] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer ">
        <Row className=" m-0 p-0 ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            History & Comparison Of The Valve Technology <sup>TM</sup>
          </h1>
        </Row>
        <Row xs={"12"} className="m-0 p-0 ">
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            The Evolution of the Smart Water Valve <sup>TM</sup>
          </h3>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-0" : "p-4"
            }`}
          >
            <Image fluid src={p54} style={{}} />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle  orange"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Precision Engineered | Patent # 11,016,512
            </h3>

            <div className="smallText align-self-start justify-content-center align-items-start  px-4 py-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Construction Materials
              </h3>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Stainless Steel{" "}
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Acetron GP
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                FDA approved internal O-rings
              </p>
            </div>
            <div className="smallText align-self-start justify-content-center align-items-start  px-4 ">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Certifying Laboratory: IAPMO R&
              </h3>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                NSF 61 (potable water)
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                NSF 372 (lead free)
              </p>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
                Texas A&M Independent Studies
              </p>
            </div>
          </Col>
        </Row>
        <Row xs={12} className="m-0 p-0 px-5 ">
          <Col
            xs={12}
            md={4}
            lg={4}
            className={`m-0 p-0  d-flex flex-column align-items-center justify-content-center smallText   `}
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSmallTitle blue"
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                1<sup>st</sup> Generation <IoWarning color="red" size={"26"} />
              </Card.Title>
              <Card.Img alt="image" src={p55} className="img img-fluid " />
              <Card.Body className="p-0 m-0  text d-flex flex-column align-self-center ">
                <Card.Subtitle
                  className=" poppins-SemiBold portfolioTinyTitle  text-center"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  2014
                </Card.Subtitle>
                <Card.Text
                  className="poppins-Regular "
                  style={
                    isDesktopOrLaptop
                      ? { fontSize: "16px" }
                      : { fontSize: "14px" }
                  }
                >
                  First Patent Issued (8,707,981)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className={`m-0 p-0  d-flex flex-column align-items-center justify-content-center smallText  `}
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSmallTitle blue"
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                2<sup>nd</sup> Generation{" "}
                <PiSealCheckFill color="green" size={"26"} />
              </Card.Title>
              <Card.Img alt="image" src={p56} className="img img-fluid" />
              <Card.Body className="p-0 m-0  text d-flex flex-column align-self-center ">
                <Card.Subtitle
                  className=" poppins-SemiBold portfolioTinyTitle  text-center"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  2020
                </Card.Subtitle>
                <Card.Text
                  className="poppins-Regular "
                  style={
                    isDesktopOrLaptop
                      ? { fontSize: "16px" }
                      : { fontSize: "14px" }
                  }
                >
                  Patent issued (10,544,569)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className={`m-0 p-0  d-flex flex-column align-items-center justify-content-center smallText  `}
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSmallTitle blue"
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                2-Stage Design <PiSealCheckFill color="orange" size={"26"} />
              </Card.Title>
              <Card.Img alt="image" src={p57} className="img img-fluid" />
              <Card.Body className="p-0 m-0  text d-flex flex-column align-self-center ">
                <Card.Subtitle
                  className=" poppins-SemiBold portfolioTinyTitle  text-center"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  2021
                </Card.Subtitle>
                <Card.Text
                  className="poppins-Regular "
                  style={
                    isDesktopOrLaptop
                      ? { fontSize: "16px" }
                      : { fontSize: "14px" }
                  }
                >
                  Patent issued (11,016,512)
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className={`m-0 p-0 text ${isDesktopOrLaptop && " px-5"}`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle orange"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            IMPORTANT DISCLSIMER:
          </h3>
          <p
            className="poppins-Regular mt-2"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Please be aware that we no longer carry nor recommend using our
            first-generation design as we consider it to be obsoleted. If you
            are offered any similar product, please contact us immediately! at{" "}
            <u>acc@watersolutech.com</u> or{" "}
            <u className="blue">acc@thesmartvalves.com </u> or
            jeff@thesmartvalve.com
          </p>
        </Row>

        <Row className={`m-0 p-0 ${isDesktopOrLaptop && "px-5 py-4"}  `}>
          <Table
            responsive
            bordered
            hover
            variant="light"
            size={isDesktopOrLaptop ? "lg" : "sm"}
            className="smallText"
          >
            <thead>
              <tr className="table-dark">
                <th className="poppins-SemiBold">Feature & Benefits</th>
                <th className="poppins-SemiBold">
                  1 <sup>st</sup> Generation
                </th>
                <th className="poppins-SemiBold">
                  2 <sup>nd</sup> Generation
                </th>
                <th className="poppins-SemiBold">
                  3 <sup>rd</sup> Generation
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="poppins-Regular">Placement</td>
                <td className="poppins-Regular">
                  Goes directly into pipe system - bad for replacement or
                  adjustments
                </td>
                <td className="poppins-Regular">Flanged and Threaded Models</td>
                <td className="poppins-Regular">Flanged and Threaded Models</td>
              </tr>
              <tr>
                <td className="poppins-Regular">Adjustments</td>
                <td className="poppins-Regular">
                  NO external adjustments once inserted into main line
                </td>
                <td className="poppins-Regular">Externaly:Depends on size</td>
                <td className="poppins-Regular">Yes very easy</td>
              </tr>
              <tr>
                <td className="poppins-Regular">Range of Settings</td>
                <td className="poppins-Regular">None- one per Spring</td>
                <td className="poppins-Regular">Good </td>
                <td className="poppins-Regular">Excellent</td>
              </tr>
              <tr>
                <td className="poppins-Regular">Flow Capacity</td>
                <td className="poppins-Regular">Poor</td>
                <td className="poppins-Regular">Good </td>
                <td className="poppins-Regular">Excellent</td>
              </tr>
              <tr>
                <td className="poppins-Regular">Downstream PSI control</td>
                <td className="poppins-Regular">Poor</td>
                <td className="poppins-Regular">Good </td>
                <td className="poppins-Regular">Excellent</td>
              </tr>
            </tbody>
          </Table>
        </Row>
        <Container
          fluid
          className="m-0 p-0 px-5 my-5  d-flex  justify-content-center"
        >
          <Button
            onClick={handleShow}
            variant="dark"
            size="md"
            className={`${isDesktopOrLaptop ? " m-3" : "my-4"}   `}
          >
            Submit Quick Connect
          </Button>
        </Container>
      </Container>
      <QuickConnect isVisible={show} hideModel={handleClose} />
    </MasterLayout>
  );
}

export default History;
