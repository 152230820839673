import React, { useState } from "react";
const AppContext = React.createContext();
export const AppProvider = ({ children }) => {
  const baseUrl = "https://wslive.alleimran.com";
  // const baseUrl = "http://192.168.8.161:8000";

  //States (getters)
  const [theme, setTheme] = useState("light");

  //Functions (Setters)
  const storeTheme = (value) => {
    setTheme(value);
  };

  return (
    <AppContext.Provider
      value={{
        baseUrl,
        //States (getters)
        theme,

        //Functions (Setters)
        storeTheme,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
export default AppContext;
