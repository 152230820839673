import React from "react";
import { Card, Container, Image, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { IoCaretForwardCircle } from "react-icons/io5";

const WebinarCard = ({ title, to, image }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <Card
      className="m-0 p-0"
      style={{
        border: "none",
        borderRadius: "0px",
        backgroundColor: "rgba(255,0,0,0)",
      }}
    >
      <Card.Body className="p-0 m-0 " style={{ backgroundColor: "black" }}>
        <Stack
          className="p-2"
          style={{
            justifyContent: "space-between",
          }}
          direction="horizontal"
        >
          <div
            className="d-flex flex-row mx-3 "
            style={{
              borderRadius: "22px",
              backgroundColor: "rgba(225,231,238,0.5)",
              marginTop: "30%",
            }}
          >
            <Card.Link
              as={Link}
              to={to}
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoCaretForwardCircle style={{ color: "red" }} size={"32"} />
            </Card.Link>
            <h6
              className="portfolioTinyTitle poppins-SemiBold my-2 mx-2 text-white"
              style={
                isTabletOrMobile
                  ? {
                      fontSize: "12px",
                    }
                  : {fontSize: "12px",}
              }
            >
              STREAM
            </h6>
          </div>
          <Image
            style={{ width: "50%", height: "210px", objectFit: "cover" }}
            src={image}
            alt="image"
          />
        </Stack>
      </Card.Body>
      <Card.Title
        className="poppins-SemiBold portfolioTinyTitle mt-1"
        style={isTabletOrMobile ? { fontSize: "12px" } : {}}
      >
        {title}
      </Card.Title>
    </Card>
  );
};
export default WebinarCard;
