import React, { useEffect, useState } from "react";
import { Button, Col, Container, Figure, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/waterTech.css";
import p25 from "../../assets/images/6. The Smart Integrated Approach.Series 1.1366x768/25.png";
import p26 from "../../assets/images/6. The Smart Integrated Approach.Series 1.1366x768/26.png";
import p27 from "../../assets/images/6. The Smart Integrated Approach.Series 1.1366x768/27.png";
import p28 from "../../assets/images/6. The Smart Integrated Approach.Series 1.1366x768/28.png";
import p29 from "../../assets/images/6. The Smart Integrated Approach.Series 1.1366x768/29.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

function ProgramSeries1(props) {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            THE SMART INTEGRATED APPROACH
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle blue"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Series 1: Water Meter Accuracy Impact
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Is there air in my water line and am I paying for it?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              In the volume of water passing through your water meter, there is
              also a volume of air, and your water meter will not tell the
              difference. To measure water consumption, the most common water
              meters use a method known as Positive Displacement. This method is
              not limited to water: it measures the total volume of both water
              and air – so you are also paying for air.
              <br />
              <br />
              Each property possesses its own distinct characteristics, and
              conditions can vary significantly. The positioning of water meters
              may be more favorable in some cases compared to others within
              their respective utility lines. However, due to the inherent
              nature of water transported through pipes, turbulence is common,
              leading to the separation of air.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-0" : "p-4"
            }`}
          >
            <Image fluid src={p25} alt="Image" />
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Reducing Volume, Pressure Laws
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <Image fluid src={p26} alt="Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSmallTitle blue"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              A Little High School Physics
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              When gas is subjected to pressure, its volume decreases. The
              greater the pressure, the more compact the volume becomes. By
              introducing pressure into your water line, you can effectively
              minimize the amount of air passing through your meter registering.
              It's a straightforward process.
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <h3
            className="poppins-SemiBold portfolioSubTitle align-self-start"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            The Smart Flow Management <sup>TM</sup> Valve Technology
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <Figure>
              <Figure.Image
                fluid
                alt="171x180"
                src={isHovered ? p28 : p27}
                onMouseEnter={() => {
                  setIsHovered(true);
                }}
                onMouseLeave={() => {
                  setIsHovered(false);
                }}
              />
              <Figure.Caption className="poppins-Regular  my-3">
                Most Customers Experience 15-25% in Monthly Savings
              </Figure.Caption>
            </Figure>
            <Container
              fluid
              className={`my-3 d-flex flex-row justify-content-between ${
                isDesktopOrLaptop && "px-2"
              } `}
            >
              <Link to={"/workWithus/consulting"} className="link">
                <h4
                  className=" portfolioTinyTitle poppins-SemiBold"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Work with us
                  <IoArrowForwardCircleOutline size={"30"} color="#333333" />
                </h4>
              </Link>
              <Link to={"/faqs/smartvalvefaqs"} className="link">
                <h4
                  className=" portfolioTinyTitle poppins-SemiBold"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  FAQS on the Smart Valve <sup>TM</sup>
                  <IoArrowForwardCircleOutline size={"30"} color="#333333" />
                </h4>
              </Link>
            </Container>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How does the air control technology work?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The device maintains city backpressure, restricting air expansion
              (Venturi Designed), keeping its volume constricted before it
              reaches the water meter register —the meter register no longer
              measures this air. As a result, you pay less for the same “true
              water usage” . The Externally adjustable feature allows you to
              fine tune the setting perfectly to your facility for maximum
              performance all year round
            </p>
            <Link
              to={"/portfolio/product&supplies"}
              className="link poppins-SemiBold align-self-start "
              onClick={() => {}}
              // style={{ textDecoration: "underline" }}
            >
              VISIT OUR FULL RANGE
            </Link>
            <div className="smallText align-self-start justify-content-center align-items-start  mt-2">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                5 Fundamental Customer Benefits
              </h3>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Reduction in water fees (10-30%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Reduction in sewer fees (10-30%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    className="mb-2"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Reduction in water consumption (3-6%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    className="mb-2"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Regulate turbulence and overflow due to city pressure
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    className="mb-2"
                    style={{ marginRight: "15px" }}
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Act as a backflow preventer
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 text">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "0px" } : {}
            }
          >
            Tracking the Data : Using Time Series Plots
          </h3>
          <p
            className={`poppins-Regular  my-1 ${isDesktopOrLaptop && "px-5"} `}
            style={
              isTabletOrMobile
                ? { fontSize: "14px", color: "#31a6bf" }
                : { color: "#31a6bf", marginLeft: "0.5%" }
            }
          >
            Our service includes a month-to-month comparison and a running total
            of consumption, enabling you to visualize your cost savings
          </p>
          <Image fluid src={p29} alt="image" className="mx-5 my-0" />
          <Container
            fluid
            className={`m-0 d-flex flex-row justify-content-between ${
              isDesktopOrLaptop && "px-5"
            } `}
          >
            <Link to={"/portfolio/history"} className="link">
              <h4
                className=" portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                How Does This Technology Compare
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
            <Link to={"/portfolio/history"} className="link">
              <h4
                className=" portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                History of the Technology
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
            <Link
              to={"/portfolio/essentailprogram/series1"}
              onClick={() => {
                scrollToTop();
              }}
              className="link"
            >
              <h4
                className="  portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                READ MORE
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
          </Container>
          <Container
            fluid
            className={`m-0 p-0 my-5 d-flex flex-row  justify-content-between ${
              isDesktopOrLaptop ? " px-5" : "px-2"
            }`}
          >
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series1");
              }}
              style={{
                backgroundColor: "#555",
                color: "white",
                transition: "background-color 0.3s, color 0.3s",
              }}
            >
              Series 1
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series2");
              }}
            >
              Series 2
            </Button>
            <Button
              variant="dark"
              size="sm"
              className={`poppins-Regular ${
                isDesktopOrLaptop ? " px-5 " : "px-3"
              }`}
              onClick={() => {
                navigate("/portfolio/essentailprogram/series3");
              }}
            >
              Series 3
            </Button>
          </Container>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default ProgramSeries1;
