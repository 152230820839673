import React from "react";
import p106 from "../../assets/images/27. Latest In Property Water Management.1366x768/106.png";
import p107 from "../../assets/images/27. Latest In Property Water Management.1366x768/107.png";
import p108 from "../../assets/images/27. Latest In Property Water Management.1366x768/108.png";
import p109 from "../../assets/images/27. Latest In Property Water Management.1366x768/109.png";
import p110 from "../../assets/images/27. Latest In Property Water Management.1366x768/110.png";
import p111 from "../../assets/images/27. Latest In Property Water Management.1366x768/111.png";
import p112 from "../../assets/images/27. Latest In Property Water Management.1366x768/112.png";
import p120 from "../../assets/images/27. Latest In Property Water Management.1366x768/120.png";
import BlogCard from "./BlogCard";

const SideBlogComponent = () => {
  return (
    <>
      <BlogCard
        to={"/aboutUs/resources/blogdetail1"}
        date={"SEPTEMBER 21, 2020"}
        title={
          "High Traffic: the impact it will have on your water usage and associated utility fees"
        }
        imageUri={p106}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail2"}
        date={"SEPTEMBER 21, 2017"}
        title={
          "How Can Hoteliers Benefit by Making Smart Technologies Part Of Their Corporate Water Management Strategy"
        }
        imageUri={p107}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail3"}
        date={"June 21, 2020"}
        title={
          "How Water Meter Inaccuracies, Increase Your Property Water Fees Significantly."
        }
        imageUri={p108}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail5"}
        date={"SEPTEMBER 21, 2019"}
        title={
          "How unhealthy pipelines lead to an increase and unplanned in operational spending"
        }
        imageUri={p109}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail8"}
        date={"SEPTEMBER 21, 2017"}
        title={
          "The Cap Rate Influencer : Lowering Water Costs Increases Asset Value"
        }
        imageUri={p110}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail6"}
        date={"SEPTEMBER 21, 2019"}
        title={
          "Water-Saving Plumbing Fixtures: A Recipe for Success in Food Manufacturing"
        }
        imageUri={p120}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail7"}
        date={"SEPTEMBER 21, 2019"}
        title={
          "Cost Benefit Analysis: Water-Saving Plumbing Fixtures for Food Manufacturing Facilities"
        }
        imageUri={p111}
      />
      <BlogCard
        to={"/aboutUs/resources/blogdetail4"}
        date={"SEPTEMBER 21, 2019"}
        title={
          "Why The Cost To Install The Intelligent Water Valve Propel NOI, and Is Won Back On ROI ?"
        }
        imageUri={p112}
      />
    </>
  );
};

export default SideBlogComponent;
