import React ,{useState} from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import { Link } from "react-router-dom";
import P64 from "../../assets/images/P64.jpg";
import QuickApply from "../../components/quickApply/QuickApply";
import { useEffect } from "react";

const Draftsman = () => {
  const [show,setShow]=useState(false)

  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : { marginLeft: "0px" }
            }
          >
            PART-TIME DRAFTSMAN
          </h1>
        </Row>
        <Row className={`m-0 p-0 ${isDesktopOrLaptop && 'px-5'}  text`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            DRAFTER DUTIES
          </h3>

          <ul className=" text">
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Manage and supervise other draftsmen.
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Draft 2D and 3D mechanical, electrical and plumbing designs in
              Revit and AutoCAD base on MEP's drafting standards.
            </li>

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Position include adding a new finish edge sketch to existing
              product and DXF new drawing out to AutoCAD for CNC machining.
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Create SolidWorks 3D drawings of customer files.
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Prepare 3-D drawings to be insert into PowerPoint presentations.
            </li>
          </ul>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            DRAFTER REQUIREMENTS
          </h3>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Drafter requirements can be divided into basic requirements and
            required soft skills. The lists below show the most common
            requirements included in drafter job postings.
          </p>
          <h5
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : {
                    marginLeft: "0px",
                  }
            }
          >
            Requirements
          </h5>
          <ul>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Extensive technical drafting experience
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Proficient in AutoCAD and other software
            </li>

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Knowledge of engineering and manufacturing principles
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Understanding of industry-specific drafting standards
            </li>
          </ul>
          <h5  className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : {
                    marginLeft: "0px",
                  }
            }>
            Required Soft Skills
          </h5>
          <ul className="text">
            <li  className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}>Excellent communication skills</li>
            <li  className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}>Organizational abilities</li>
            <li  className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}>Attention to detail</li>
            <li  className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}>Problem-solving attitude</li>
            <li  className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}>Ability to work in a team</li>
          </ul>
         
        </Row>
        <Button
          variant="dark"
          size="sm"
          className="poppins-Regular mx-5 my-3"
          // style={{ marginLeft: "7%" }}
          onClick={()=>{
            setShow(true)
          }}
        >
          Quick Apply
        </Button>
      </Container>
      
      <QuickApply isVisible={show} hideModel={()=>{
        setShow(false)
      }} />
    </MasterLayout>
  );
};

export default Draftsman;
