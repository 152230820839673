import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p85 from "../../assets/images/20. Golf Courses & Irrigation Requirements. 1366x768/85.png";
import p87 from "../../assets/images/20. Golf Courses & Irrigation Requirements. 1366x768/87.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { Link } from "react-router-dom";
import { caseStudiesAlert } from "../../helper/HelperFunctions";

const GolfCourse = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Golf Courses & Irrigation Requirements
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p85}
              // className="img img-fluid"
              alt="image"
            />
            <Button
              variant="dark"
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Golf courses have grappled with the mounting challenges posed by
              escalating water costs and resource-intensive maintenance
              practices for years. At the forefront of sustainable solutions,
              the United States Golf Association (USGA) has embarked on an
              extensive journey to address these issues since 1983. Their quest
              for cost-effective water management is now more critical than
              ever, with annual bills averaging $600,000 and a continual 11%
              increase. But there's hope
            </p>

            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Technology Adoption Benefits :
              </h3>

              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Ability to claim 100% of available evaporation credits
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
        </Row>
        <Row className={`m-0 p-0 my-5 ${isDesktopOrLaptop && "px-5 "}`}>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p87} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link mb-5"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Sandals] Case Studies
            </Link>
          </Col>
        </Row>
        <Row xs={"12"} className={`${isDesktopOrLaptop && "px-5"}  m-0 text`}>
          <h3
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Golf Course Sustainability: A Solution for Reducing Water Costs{" "}
          </h3>
          <br />
          <br />
          <dl>
            <dt
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The USGA's Initiatives
            </dt>
            <dd
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              - Inception: The USGA initiated a substantial investment of $50
              million in 1983. This extensive research delved into the study of
              grass varieties that demand less water. This breakthrough approach
              led to an annual reduction of 22% in water bills.
            </dd>
            <br />
            <dt
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Ongoing Challenges
            </dt>
            <dd
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              - Pressing Issues: Despite these significant accomplishments, the
              challenge of reducing expenses and water consumption still looms
              large
            </dd>
            <dd
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              - Collaboration: In November 2015, the USGA joined forces with the
              University of Minnesota to explore more innovative strategies. The
              objective is not only to benefit the golfing community but also to
              contribute to the broader sports industry's sustainability efforts
            </dd>
            <br />

            <dt
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The Current State
            </dt>
            <dd
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              - Annual Costs: At present, the average annual water expenditure
              for golf courses stands at $600,000, with an annual 11% increase
            </dd>
            <dd
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              - Water Usage: In the summer months, typical golf courses consume
              a staggering 100,000 to 1,000,000 gallons of water per week.
            </dd>
            <br />

            <dt
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The Solution
            </dt>
            <dd
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              -Introducing Water Solutions Technology: By incorporating Water
              Solutions Technology's smart water-saving valve, tailored
              specifically for golf course irrigation, the USGA can make headway
              in its pursuit of cost reduction, efficient course maintenance,
              and preservation of employment opportunities in the sector
            </dd>
          </dl>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Ready to take the next step in making your golf course more
            sustainable and cost-effective? Reach out to us today for an
            evaluation of your golf course. Join us in reshaping the future of
            golf course maintenance through sustainable water management.
          </p>
          <div className="m-0 p-0">
            <Button
              variant="dark"
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </div>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default GolfCourse;
