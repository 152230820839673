import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import P48 from "../../../assets/images/P48.png";
import p122 from "../../../assets/images/27. Latest In Property Water Management.1366x768/122.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail5 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How unhealthy pipelines lead to an increase and unplanned in
              operational spending
            </h3>
            <br />

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Spending unusually high prices on energy cost, what if this is
              related to water usages?
            </p>
            <Image
              fluid
              src={p122}
              alt="image"
              // style={{ height: "15%", width: "95%", objectFit: "cover" }}
            />
            <p
              className="poppins-Regular mt-3"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Unhealthy pipelines, characterized by issues such as scale
              build-up, can contribute to an increase in unplanned operational
              spending. Here's a description:
              <br />
              <br />
              When pipelines are not maintained properly and develop issues like
              scale build-up, it can have detrimental effects on operational
              efficiency and financial resources. Scale build-up occurs when
              mineral deposits, such as calcium or iron, accumulate on the inner
              surfaces of the pipes over time. This build-up restricts the flow
              of water or other fluids, leading to reduced pipeline capacity and
              increased operational challenges.
              <br />
              <br />
              One consequence of unhealthy pipelines is the decrease in flow
              rate, which results in decreased efficiency and increased energy
              consumption. As the scale build-up restricts the pipe's diameter,
              the system requires more energy to push the same volume of fluid
              through the pipeline. This inefficiency not only leads to
              increased energy costs but also negatively impacts the overall
              performance of the system.
              <br />
              <br />
              Moreover, unhealthy pipelines are prone to frequent blockages and
              reduced water flow, which can disrupt operations and require
              immediate attention. Addressing these issues often involves
              emergency repairs, maintenance downtime, and additional labor
              costs. The unplanned expenses associated with such disruptions can
              be substantial, putting a strain on operational budgets.
              <br />
              <br />
              Furthermore, neglected pipelines are at a higher risk of
              experiencing pipe failures, leaks, or bursts. These incidents not
              only lead to significant financial losses due to wasted resources
              but also require emergency repairs, service disruptions, and
              potential damage to surrounding infrastructure. The cost of
              repairing or replacing damaged pipelines can be extensive and may
              even result in legal or regulatory consequences.
              <br />
              <br />
              In summary, unhealthy pipelines, including those affected by scale
              build-up, contribute to increased and unplanned operational
              spending. The reduced flow capacity, energy inefficiency, frequent
              blockages, and potential pipe failures all incur additional costs
              in terms of energy consumption, emergency repairs, maintenance
              downtime, and potential legal repercussions. Regular maintenance,
              inspection, and proactive measures are essential to mitigate these
              risks and minimize the financial impact on operational spending.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail5;
