import React, { useEffect, useState, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import colorlogo from "../../assets/images/color-logo.png";
import { VscThreeBars } from "react-icons/vsc";
import { Navbar, Container, Col, Row, NavDropdown, Nav } from "react-bootstrap";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import { useMediaQuery } from "react-responsive";
import AppContext from "../../context/AppContext";
import PortfolioNavbar from "../navbars/PortfolioNavbar";
import CaseStudiesNavbar from "../navbars/CaseStudiesNavbar";
import WorkwithusNavbar from "../navbars/WorkwithusNavbar";
import AboutusNavbar from "../navbars/AboutusNavbar";
import FAQNavbar from "../navbars/FAQNavbar";

function Header(props) {
  const [isScrolled, setIsScrolled] = useState(false);
  const [logoSrc, setLogoSrc] = React.useState(logo);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdown1Open, setIsDropdown1Open] = useState(false);
  const [isDropdown2Open, setIsDropdown2Open] = useState(false);
  const [isDropdown3Open, setIsDropdown3Open] = useState(false);
  const [isDropdown4Open, setIsDropdown4Open] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });
  const isRetina = useMediaQuery({ query: "(min-resolution: 2dppx)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    // alert(`width ${window.innerWidth}  height ${window.innerHeight}`);
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Navbar
      fixed="top"
      expand="lg"
      className="bg-body-light m-0 p-0 d-flex flex-column bg-white "
      style={{
        boxShadow: " 0 5px 15px rgba(0, 0, 0, 0.06)",
        // boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.04)",
        // dropdown box  shadow '0px 8px 16px 0px rgba(0,0,0,0.2)'
      }}
    >
      <Container
        fluid
        className="align-items-center m-0 p-0  "
        style={{ height: isScrolled ? "" : "85px" }}
      >
        <Col xs={3} className="p-0 m-0 ">
          <NavLink
            onClick={() => {
              scrollToTop();
              setIsMenuOpen(!isMenuOpen);
            }}
            className={"d-flex justify-content-start align-items-center "}
            style={{
              textDecoration: "none",
              marginLeft: isTabletOrMobile ? "20px" : "70px",
            }}
          >
            <VscThreeBars
              size={isTabletOrMobile ? "16px" : "28px"}
              color={"#222222"}
            />
            <Navbar.Text
              className="navbarMenu telugu-MN"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "5px" }
                  : { marginLeft: "12px" }
              }
            >
              MENU
            </Navbar.Text>
          </NavLink>
        </Col>
        <Col xs={6} className="p-0 m-0 text-center d-flex flex-column">
          <Navbar.Text
            className="navbarTitle "
            style={isTabletOrMobile ? { fontSize: "15px" } : {}}
          >
            WATER SOLUTIONS TECHNOLOGY
          </Navbar.Text>
          <Navbar.Text
            className="navbarSubtitle calibiri-light"
            style={isTabletOrMobile ? { fontSize: "8px" } : {}}
          >
            Smart Water Management Solutions
          </Navbar.Text>
        </Col>
        <Col xs={3} className="p-0 m-0 d-flex justify-content-end ">
          <Navbar.Brand as={Link} to={"/"}>
            <img
              alt="Logo"
              src={logoSrc}
              onMouseOver={() => {
                setLogoSrc(colorlogo);
              }}
              onMouseOut={() => {
                setLogoSrc(logo);
              }}
              style={{
                marginRight: isDesktopOrLaptop ? "55px" : "10px",
                height: isDesktopOrLaptop ? "60px" : "35px",
                width: isDesktopOrLaptop ? "70px" : "45px",
              }}
            />
          </Navbar.Brand>
        </Col>
      </Container>
      {isMenuOpen && (
        <Container
          fluid
          style={
            {
              // height:'55px',
            }
          }
          className={
            isDesktopOrLaptop
              ? " m-0 p-0 d-flex flex-column bg-white"
              : " m-0 p-0 bg-white "
          }
        >
          <hr
            className="mx-5"
            style={{ width: "90%", margin: "0 auto", alignSelf: "center" }}
          />
          <Navbar expand="lg" className="bg-body-light m-0 p-0 ">
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="me-auto text-start">
                <NavDropdown
                  className={`poppins-Regular  navbaritem `}
                  title="PORTFOLIO"
                  id="responsive-navbar-nav"
                  show={isDropdown1Open}
                  onMouseEnter={() => {
                    setIsDropdown1Open(true);
                    console.log("mouse  enter");
                  }}
                  onMouseLeave={() => {
                    setIsDropdown1Open(false);
                    console.log("mouse  leave");
                  }}
                >
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/watertreatment"
                  >
                    WATER TREATMENT
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/waterconsumption"
                  >
                    WATER CONSUMPTION
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/watermonitoring"
                  >
                    WATER MONITORING
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/essentailprogram"
                  >
                    SMART APPROACH PROGRAMME
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/product&supplies"
                  >
                    PRODUCT & SUPPLIES
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/history"
                  >
                    HISTORY OF VALVES
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/portfolio/waterstewardship"
                  >
                    WATER STEWARDSHIP
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/faqs/smartvalvefaqs"
                  >
                    FAQ
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  className="poppins-Regular navbaritem"
                  title="CASE STUDIES"
                  id="collapsible-nav-dropdown2"
                  show={isDropdown2Open}
                  onMouseEnter={() => {
                    setIsDropdown2Open(true);
                    console.log("mouse  enter");
                  }}
                  onMouseLeave={() => {
                    setIsDropdown2Open(false);
                    console.log("mouse  leave");
                  }}
                >
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/hospitality"
                  >
                    HOSPITALITY
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/manufacturing"
                  >
                    MANUFACTURING
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/servicestation"
                  >
                    SERVICE STATIONS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/restaurants"
                  >
                    RESTAURANTS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/officebuilding"
                  >
                    OFFICE BUILDINGS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/condominiums"
                  >
                    CONDOMINIUMS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/supermarket"
                  >
                    SUPERMARKETS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/golfcourse"
                  >
                    GOLF COURSE
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/caseStudies/others"
                  >
                    OTHERS
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  className="poppins-Regular navbaritem"
                  title="WORK WITH US"
                  id="collapsible-nav-dropdown3"
                  show={isDropdown3Open}
                  onMouseEnter={() => {
                    setIsDropdown3Open(true);
                    console.log("mouse  enter");
                  }}
                  onMouseLeave={() => {
                    setIsDropdown3Open(false);
                    console.log("mouse  leave");
                  }}
                >
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/workWithus/consulting"
                  >
                    CONSULTING
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/workWithus/ourproducts"
                  >
                    OUR PRODUCTS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/workWithus/plumber"
                  >
                    PLUMBERS (SPECIALITY GROUP 1)
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/workWithus/propertyowners"
                  >
                    PROPERTY OWNERS/MANAGERS (SPECIALITY GROUP 2)
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/workWithus/matters"
                  >
                    SUSTAINABILITY MATTERS
                  </NavDropdown.Item>
                </NavDropdown>
                <Nav.Link
                  className="poppins-Regular navbaritem"
                  style={{ padding: "14px" }}
                  as={Link}
                  to="/"
                >
                  TECHNOLOGIES
                </Nav.Link>
                <Nav.Link
                  className="poppins-Regular navbaritem "
                  style={{ padding: "14px" }}
                  as={Link}
                  to="/careers"
                >
                  CAREERS
                </Nav.Link>
                <NavDropdown
                  className="poppins-Regular navbaritem"
                  title="ABOUT US"
                  id="collapsible-nav-dropdown4"
                  show={isDropdown4Open}
                  onMouseEnter={() => {
                    setIsDropdown4Open(true);
                    console.log("mouse  enter");
                  }}
                  onMouseLeave={() => {
                    setIsDropdown4Open(false);
                    console.log("mouse  leave");
                  }}
                >
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/aboutUs/location"
                  >
                    LOCATIONS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/aboutUs/resources"
                  >
                    RESOURCE & NEWS
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/aboutUs/gallery"
                  >
                    GALLERY
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    className="poppins-Regular dropdownItem"
                    as={Link}
                    to="/aboutUs/govservices"
                  >
                    GOV SERVICES
                  </NavDropdown.Item>
                </NavDropdown>
                {/* <Nav.Link
                    className="poppins-Regular navbaritem"
                    style={{ padding: "14px" }}
                    as={Link}
                    to={"/aboutUs/govservices"}
                  >
                    GOV SERVICES
                  </Nav.Link> */}
                <Nav.Link
                  className="poppins-Regular navbaritem"
                  style={{ padding: "14px" }}
                  as={Link}
                  to={"/webinars"}
                >
                  WEBINARS
                </Nav.Link>
                <Nav.Link
                  className="poppins-Regular navbaritem "
                  style={{ padding: "14px" }}
                  as={Link}
                  to={"/events"}
                >
                  EVENTS
                </Nav.Link>
                {/* <Nav.Link
                  className="poppins-Regular navbaritem"
                  style={{ padding: "14px" }}
                  as={Link}
                  to="/login"
                >
                  LOGIN
                </Nav.Link> */}
                <Nav.Link
                    className="poppins-Regular navbaritem"
                    style={{ padding: "14px" }}
                    as={Link}
                    to="/agent"
                  >
                    BECOME AN AGENT
                  </Nav.Link>
                  <Nav.Link
                    className="poppins-Regular navbaritem"
                    style={{ padding: "14px" }}
                    as={Link}
                    to="/faqs/smartvalvefaqs"
                  >
                    FAQs
                  </Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      )}
      {window.location.pathname.includes("/portfolio/") && (
        <Container
          fluid
          className={`m-0 p-0  d-flex flex-column ${
            isDesktopOrLaptop ? "align-items-end" : "align-items-center"
          } `}
          style={{ display: "flex", whiteSpace: "nowrap", overflowX: "auto" }}
        >
          <hr
            className={`p-0 m-0  pb-1 ${isDesktopOrLaptop && " mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "900px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
          <PortfolioNavbar />
          <hr
            className={`m-0 p-0  mt-1 ${isDesktopOrLaptop && "mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "900px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
        </Container>
      )}

      {window.location.pathname.includes("/caseStudies/") && (
        <Container
          fluid
          className={`m-0 p-0  d-flex flex-column ${
            isDesktopOrLaptop ? "align-items-end" : "align-items-center"
          } `}
          style={{ display: "flex", whiteSpace: "nowrap", overflowX: "auto" }}
        >
          <hr
            className={`p-0 m-0  pb-1 ${isDesktopOrLaptop && " mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "1000px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />

          <CaseStudiesNavbar />
          <hr
            className={`m-0 p-0  mt-1 ${isDesktopOrLaptop && "mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "1000px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
        </Container>
      )}
      {window.location.pathname.includes("/workWithus/") && (
        <Container
          fluid
          className={`m-0 p-0  d-flex flex-column ${
            isDesktopOrLaptop ? "align-items-end" : "align-items-center"
          } `}
          style={{ display: "flex", whiteSpace: "nowrap", overflowX: "auto" }}
        >
          <hr
            className={`p-0 m-0  pb-1 ${isDesktopOrLaptop && " mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "750px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
          <WorkwithusNavbar />
          <hr
            className={`m-0 p-0  mt-1 ${isDesktopOrLaptop && "mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "750px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
        </Container>
      )}
      {window.location.pathname.includes("/aboutUs/") && (
        <Container fluid
          className={`m-0 p-0  d-flex flex-column ${
            isDesktopOrLaptop ? "align-items-end" : "align-items-center"
          } `}
          style={{ display: "flex", whiteSpace: "nowrap", overflowX: "auto" }}
        >
          <hr
            className={`p-0 m-0  pb-1 ${isDesktopOrLaptop && " mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "500px",
              margin: "0%",
              alignSelf: "center",
            }}
          />
          <AboutusNavbar />
          <hr
            className={`m-0 p-0  mt-1 ${isDesktopOrLaptop && "mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "500px",
              margin: "0%",
              alignSelf: "center",
            }}
          />
        </Container>
      )}
      {window.location.pathname.includes("/faqs/") && (
        <Container
          fluid
          className={`m-0 p-0  d-flex flex-column ${
            isDesktopOrLaptop ? "align-items-end" : "align-items-center"
          } `}
          style={{ display: "flex", whiteSpace: "nowrap", overflowX: "auto" }}
        >
          <hr
            className={`p-0 m-0  pb-1 ${isDesktopOrLaptop && " mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "750px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
          <FAQNavbar />
          <hr
            className={`m-0 p-0  mt-1 ${isDesktopOrLaptop && "mx-5"}`}
            style={{
              width: isDesktopOrLaptop ? "90%" : "750px",
              margin: "0 auto",
              alignSelf: "center",
            }}
          />
        </Container>
      )}
    </Navbar>
  );
}

export default Header;
