import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p20 from "../../assets/images/4. Water Monitoring in Real-time. 1366x768/20.png";
import p21 from "../../assets/images/4. Water Monitoring in Real-time. 1366x768/21.png";
import p22 from "../../assets/images/4. Water Monitoring in Real-time. 1366x768/22.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

function WaterMonitoring(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0 ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            Water Monitoring in Real-time
          </h1>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "-60px" }
              }
            >
              The Problem with “unmonitored water ”
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              It’s bad, it is that simple. The problem with unmonitored water is
              multifaceted and can have significant consequences for both
              businesses and the environment. For ex., water leaks can have a
              significant and often costly impact on real estate property and
              assets. Asset damage, increased water fees, and extreme loss of
              productivity.
              <br />
              <br />
              Assets owners can face a legal liability, where property owners
              can be held legally responsible for water damage to neighbouring
              properties if the leak is traced back to their negligence. This
              can result in legal disputes and financial liabilities. Other
              keyways in which water leaks can affect real estate include;
              tenant dissatisfaction, increase maintenance costs, increased
              insurance premiums, and reduction in property market value.
            </p>
            <Link
              className="link text poppins-SemiBold align-self-start m-2"
              onClick={scrollToTop}
            >
              READ MORE
            </Link>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${"p-0"}`}
          >
            <Image fluid src={p20} alt="First Image" />
          </Col>
        </Row>
        <Row xs={12} className="m-0 p-0 ">
          <h3
            className={`poppins-SemiBold portfolioSubTitle my-3  `}
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "0px" } : {}
            }
          >
            Solution for Severe Leak Prevention
          </h3>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-2" : "p-0"
            }`}
          >
            <Image
              fluid
              src={p21}
              alt="Image"
              style={{ width: "100%", height: "400px", objectFit: "cover" }}
            />
            <Link
              className={`link  poppins-SemiBold align-self-start  my-2 ${
                isDesktopOrLaptop && "mx-5 px-2"
              }`}
              onClick={() => {
                console.log("read more");
              }}
            >
              READ MORE
            </Link>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start  m-0 ${
              isDesktopOrLaptop ? "p-4" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Less manual, more control – WaterEye TM is your single source for
              managing and monitoring your connected plumbing ecosystem. You and
              your team can keep an eye on your water solutions technologies’,
              operational statuses, analyze performance trends, and ensure
              everything is under control.
            </p>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Without water monitoring, it's challenging to make informed
              decisions about water management. Data on usage patterns and
              inefficiencies are crucial for optimizing water systems.
            </p>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              While leak detection is at the centre of monitoring, setting daily
              targets and usages can help with building reaching its water
              conservation goals. The IoT technologies offers critical data on
              water operational conditions, as well as improving maintenance
              efficiency. Remotely measure water levels, pressure, temperature,
              and flow to prevent overflows.
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Results
            </h3>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                In time detection and valve shutoff
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Planned water usage
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Cooling tower evaporation credits
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Reduce maintenance costs
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Remote monitoring
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Better communication among actors
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Interactive reports
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Simplify ESG requirements to keep regulators happy
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px" }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Sustainability, Protection, Stewardship,
              </p>
            </div>
            <p
              className="poppins-Regular  my-2 "
              style={
                isTabletOrMobile ? { fontSize: "14px" } : { color: "#333333" }
              }
            >
              These meter reading technologies provide utility management with
              greater visibility, control and optimized information
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column justify-content-center align-items-center m-0 ${"p-0"}`}
          >
            <Image fluid src={p22} alt="First Image" />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default WaterMonitoring;
