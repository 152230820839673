import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import P52 from "../../assets/images/P52.png";
import P53 from "../../assets/images/P53.png";
import P54C from "../../assets/images/P54 copy.png";
import P55 from "../../assets/images/P55.png";
import P56 from "../../assets/images/P56.png";
import P57 from "../../assets/images/P57.png";
import P58 from "../../assets/images/P58.png";
import P59 from "../../assets/images/P59.png";
import P60 from "../../assets/images/P60.png";
import P61 from "../../assets/images/P61.png";
import P62 from "../../assets/images/P62.png";
import MasterLayout from "../../layouts/MasterLayout";

const Gallery = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const galleryImages = [
    require("../../assets/images/28. Gallery.1366x768/131.png"),
    require("../../assets/images/28. Gallery.1366x768/132.png"),
    require("../../assets/images/28. Gallery.1366x768/133.png"),
    require("../../assets/images/28. Gallery.1366x768/134.png"),
    require("../../assets/images/28. Gallery.1366x768/135.png"),
    require("../../assets/images/28. Gallery.1366x768/136.png"),
    require("../../assets/images/28. Gallery.1366x768/137.png"),
    require("../../assets/images/28. Gallery.1366x768/138.png"),
    require("../../assets/images/28. Gallery.1366x768/139.png"),
    require("../../assets/images/28. Gallery.1366x768/140.png"),
    require("../../assets/images/28. Gallery.1366x768/141.png"),
    require("../../assets/images/28. Gallery.1366x768/142.png"),
    require("../../assets/images/28. Gallery.1366x768/143.png"),
    require("../../assets/images/28. Gallery.1366x768/144.png"),
    require("../../assets/images/28. Gallery.1366x768/145.png"),
    require("../../assets/images/28. Gallery.1366x768/146.png"),
    require("../../assets/images/28. Gallery.1366x768/147.png"),
    require("../../assets/images/28. Gallery.1366x768/148.png"),
    require("../../assets/images/28. Gallery.1366x768/149.png"),
    require("../../assets/images/28. Gallery.1366x768/150.png"),
    require("../../assets/images/28. Gallery.1366x768/151.png"),
    require("../../assets/images/28. Gallery.1366x768/152.png"),
    require("../../assets/images/28. Gallery.1366x768/153.png"),
    require("../../assets/images/28. Gallery.1366x768/154.png"),
    require("../../assets/images/28. Gallery.1366x768/155.png"),
    require("../../assets/images/28. Gallery.1366x768/156.png"),
    require("../../assets/images/28. Gallery.1366x768/157.png"),
    require("../../assets/images/28. Gallery.1366x768/158.png"),
    require("../../assets/images/28. Gallery.1366x768/159.png"),
    require("../../assets/images/28. Gallery.1366x768/160.png"),
    require("../../assets/images/28. Gallery.1366x768/161.png"),
    require("../../assets/images/28. Gallery.1366x768/162.png"),
    require("../../assets/images/28. Gallery.1366x768/163.png"),
    require("../../assets/images/28. Gallery.1366x768/164.png"),
    require("../../assets/images/28. Gallery.1366x768/165.png"),
    require("../../assets/images/28. Gallery.1366x768/166.png"),
    require("../../assets/images/28. Gallery.1366x768/167.png"),
    require("../../assets/images/28. Gallery.1366x768/168.png"),
    require("../../assets/images/28. Gallery.1366x768/169.png"),
    require("../../assets/images/28. Gallery.1366x768/170.png"),
    require("../../assets/images/28. Gallery.1366x768/171.png"),
    require("../../assets/images/28. Gallery.1366x768/172.png"),
    require("../../assets/images/28. Gallery.1366x768/173.png"),
    require("../../assets/images/28. Gallery.1366x768/174.png"),
    require("../../assets/images/28. Gallery.1366x768/175.png"),
    require("../../assets/images/28. Gallery.1366x768/176.png"),
    require("../../assets/images/28. Gallery.1366x768/177.png"),
    require("../../assets/images/28. Gallery.1366x768/178.png"),
    require("../../assets/images/28. Gallery.1366x768/179.png"),
    require("../../assets/images/28. Gallery.1366x768/180.png"),
    require("../../assets/images/28. Gallery.1366x768/181.png"),
    require("../../assets/images/28. Gallery.1366x768/182.png"),
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer ">
        {/* <Row xs={"12"} className="  m-0 p-0">
          <AboutusNavbar />
        </Row> */}
        <Row xs={"12"} className="  m-0 p-0 text">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            GALLERY
          </h1>
          <p
            className="poppins-Regular text-center"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Successful Installations of the Smart Valve <sup>TM</sup>, Smart
            Water Kit<sup> TM </sup>
            and Our Smart Scale Buster <sup> TM </sup>
          </p>
          <h3
            className={`poppins-SemiBold portfolioSmallTitle ${
              isDesktopOrLaptop && "px-5"
            } `}
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Our installations are performed by licensed plumbers and engineers.
            The delivery quality services and property due diligence before any
            task is performed. Contact us for more testimonial and Independent
            Studies.
          </h3>
        </Row>

        <Row className={`m-0 p-0 mt-5 ${isDesktopOrLaptop && "px-5"} `}>
          {galleryImages.map((items) => {
            return (
              <Col lg={3} md={4} sm={12} xs={12} className="text-center mt-1">
                <Image
                  src={items}
                  alt="image"
                  style={{
                    height: "45vh",
                    width: "40vh",
                    objectFit: "cover",
                  }}
                />
              </Col>
            );
          })}
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Gallery;
