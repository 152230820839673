import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p75 from "../../assets/images/18. Condominiums.1366x768/75.png";
import p79 from "../../assets/images/18. Condominiums.1366x768/79.png";
import castle from "../../assets/images/18. Condominiums.1366x768/castle.png";
import realty from "../../assets/images/18. Condominiums.1366x768/realty.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import { caseStudiesAlert, scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

const Condominiums = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Condominiums
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image
              fluid
              alt="image"
              src={p75}
              // className="img img-fluid"
            />
            <Button
              variant="dark"
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              On average, a condominium complex might use anywhere from 50,000
              to 200,000 gallons of water per day, but these figures can vary
              widely
            </p>

            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Technology Adoption Benefits :
              </h3>

              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Ability to claim 100% of available evaporation credits
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
        </Row>
        <Row className={`m-0 p-0 my-5 ${isDesktopOrLaptop && "px-5 "}`}>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={4}
          >
            <Image fluid src={castle} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link my-5"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Castle_Group] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={4}
          >
            <Image fluid src={realty} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Meadowbrook Condo Apts] Case Studies
            </Link>
          </Col>
        </Row>
        <Row xs={"12"} className={`${isDesktopOrLaptop && "px-5"}  m-0 text`}>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The water usage of a condominium can vary significantly depending on
            several factors, including the size of the complex, the number of
            units, the region's climate, and the water efficiency measures in
            place. On average, a condominium complex might use anywhere from
            50,000 to 200,000 gallons of water per day, but these figures can
            vary widely. For a more precise estimate of water usage, it's best
            to consult the specific water management companies and data for the
            condominium in question.
          </p>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Annually, this can amount to around 18 to 73 million gallons of
            water. Again, these figures are approximate and can vary
            significantly based on the factors mentioned earlier. It's essential
            for condominium management to track water usage and consider
            implementing water-saving measures to reduce consumption and costs
            while promoting sustainability.
          </p>
        </Row>
        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default Condominiums;
