import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import colorlogo from "../../assets/images/color-logo.png";
import { VscThreeBars } from "react-icons/vsc";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Dropdown,
  Row,
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";

const PortfolioNavbar = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    // alert(window.location.pathname);
  }, []);
  return (
    <Navbar
      // expand="lg"
      expand
      className="m-0 p-0 bg-body-light"
      collapseOnSelect
      // style={{ display:'flex',whiteSpace: "nowrap", overflowX: "auto", }}
    >
      <Container fluid className=" p-0 m-0 px-5 mx-3 ">
        <Navbar.Toggle // aria-controls="responsive-navbar-nav  "
          aria-controls="navbarScroll"
        />
        <Navbar.Collapse // id="responsive-navbar-nav"
          id="navbarScroll"
        >
          <Nav
            className="me-auto  "
            navbarScroll
            // activeKey={window.location.pathname}
          >
             <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/portfolio/waterconsumption"
              active={
                window.location.pathname === "/portfolio/waterconsumption"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              WATER CONSUMPTION
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to={"/portfolio/watertreatment"}
              active={window.location.pathname === "/portfolio/watertreatment"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              WATER TREATRMENT
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/portfolio/watermonitoring"
              active={window.location.pathname === "/portfolio/watermonitoring"}
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              WATER MONITORING
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/portfolio/essentailprogram"
              active={
                window.location.pathname === "/portfolio/essentailprogram" ||
                window.location.pathname ===
                  "/portfolio/essentailprogram/series1" ||
                window.location.pathname ===
                  "/portfolio/essentailprogram/series2" ||
                window.location.pathname ===
                  "/portfolio/essentailprogram/series3"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              ESSENTIAL PROGRAM
            </Nav.Link>
            <Nav.Link
              className="poppins-Regular subNavbaritem"
              as={Link}
              to="/portfolio/product&supplies"
              active={
                window.location.pathname === "/portfolio/product&supplies"
              }
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "10px" }}
            >
              PRODUCTS & CRITICAL SUPPLIES
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default PortfolioNavbar;
