import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Header from "../components/Header/Header";
import Footer from "../components/footers/Footer";
import { CiCircleChevUp } from "react-icons/ci";
import { FaCircleChevronUp } from "react-icons/fa6";
import { TbCircleChevronUp } from "react-icons/tb";
import { BiSolidChevronUpCircle } from "react-icons/bi";
import { FaChevronUp } from "react-icons/fa6";
import { Link } from "react-router-dom";

const MasterLayout = ({ children }) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 350) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Container fluid className="p-0 m-0">
      <Container
        fluid
        className="m-0 p-0 d-flex flex-row justify-content-end px-4"
        style={{ position: "fixed",
        top: "88%",}}
      >
        <Button
        size="lg"
          variant="light"
          className=""
          style={{
            borderRadius: "100%",
            borderColor: "#333333",
            borderWidth: "2",
            backgroundColor: "#FFFFFF",
            alignItems:'center',
            justifyContent: 'center',
            height:'55px',
            width:'55px'
          }}
          onClick={()=>{
            scrollToTop()
          }}
        >
          <FaChevronUp color={"#000000"} size={"15px"} className="" />
        </Button>
      </Container>
      {/* <Link
        onClick={scrollToTop}
        className="d-flex flex-row justify-content-end"
      >
        <CiCircleChevUp
          className="m-5"
          style={{ position: "fixed", top: "80%" }}
          color={
            !isScrolled && window.location.pathname == "/"
              ? "#FFFFFF"
              : "#000000"
          }
          size={"65px"}
        />
      </Link> */}
      <Row className="m-0">
        <Col className="p-0">
          <Header />
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0" style={{ marginTop: "85px" }}>
          {children}
        </Col>
      </Row>
      <Row className="m-0">
        <Col className="p-0">
          <Footer />
        </Col>
      </Row>
    </Container>
  );
};

export default MasterLayout;
