import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/waterTech.css";
import p16 from "../../assets/images/3. Water Treatment Pipes & Cooling Towers. 1366X768/16.png";
import p17 from "../../assets/images/3. Water Treatment Pipes & Cooling Towers. 1366X768/17.png";
import p18 from "../../assets/images/3. Water Treatment Pipes & Cooling Towers. 1366X768/18.png";
import p19 from "../../assets/images/3. Water Treatment Pipes & Cooling Towers. 1366X768/19.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

function WaterTreatment(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

 
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            Water Treatment: Pipes & Cooling Towers
          </h1>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "-40px" }
                  : { marginLeft: "-150px" }
              }
            >
              The Problem with “Water Lines”
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water lines are prone to scale build up. Scale is a common issue
              in water lines. It occurs when minerals in the water, such as
              calcium and magnesium, precipitate out and form deposits on the
              inside of pipes. Over time, scale build-up can lead to reduced
              water flow, increased pressure drops, and even blockages in pipes.
              In cooling systems, scale build-up in pipes reduces the efficiency
              of heat transfer. This means that chillers must work harder to
              achieve the desired cooling, resulting in higher energy
              consumption and operational costs.
              <br />
              <br />
              Furthermore, Scale build-up in the system increases blowdown
              frequency and the volume of discharged water. This, in turn,
              increases water usage and costs. In addition, it has an impact on
              the evaporation process: As scale accumulates in cooling towers,
              it reduces their efficiency. This results in increased water
              consumption due to more frequent top-ups and greater energy usage
              to maintain the desired cooling effect.
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image fluid src={p16} alt="Image"  />
          </Col>
        </Row>
        <Row className="m-0 my-4 p-0 ">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "0px" } : {}
            }
          >
            Explore the map to know hard water levels effects in your area
          </h3>
          <h3
            className="poppins-SemiBold portfolioSmallTitle blue"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "0px" } : {}
            }
          >
            Hardwater can be a nuance to your water related activities and be
            detrimental to your assets.
          </h3>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-0" : "p-0"
            }`}
          >
            <Image fluid src={p17} alt="Image" style={{height:'500px',width:'100%',objectFit:'cover'}} />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start  m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <b>Appliance Efficiency and Lifespan: </b>
              Hard water can lead to the build-up of scale (calcium and
              magnesium deposits) inside appliances that use water, such as
              water heaters, dishwashers, washing machines, and coffee makers.
              This build-up can reduce the efficiency of these appliances by
              insulating heating elements and clogging pipes, leading to higher
              energy consumption and, ultimately, shortening their lifespan.
              <br />
              <br />
              <b>Plumbing System Damage: </b>
              Over time, the minerals in hard water can deposit inside pipes,
              reducing water flow and increasing pressure. This can lead to
              leaks, bursts, and other plumbing issues. The scale build up can
              be especially problematic in older homes with narrower pipes.
              <br />
              <br />
              <b>Heating Costs: </b>
              In water heaters, scale acts as an insulator between the water and
              the heating element. This means it takes more energy to heat
              water, increasing energy costs. The efficiency of tankless water
              heaters can also be significantly affected by hard water.
              <br />
              <br />
              <b>Skin and Hair: </b>: While not directly a property asset, it's
              worth noting that hard water can have adverse effects on the skin
              and hair of occupants. It can leave skin feeling dry and hair
              looking dull, as the minerals in the water can strip moisture and
              leave a residue.
            </p>
          </Col>
        </Row>
        <Row className="m-0 mt-5 p-0 ">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "0px" } : {}
            }
          >
            The Solutions for Asset Protection: Water Treatment, & Water
            Recovery
          </h3>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "px-5" : "p-0"
            }`}
          >
            <Image fluid src={p18} alt="Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start  m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular mt-5"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Non-invasive method and technology. Designed for industrial,
              commercial, and municipal applications, the proprietary
              electrostatic technology works effectively and efficiently –
              without chemicals, power or moving parts. The hydrodynamic
              conditions present during operation force the precipitation of
              crystals in the water. This process:
              <Link
                className="link poppins-SemiBold"
                to={"/portfolio/essentailprogram/series3"}
              >
                READ MORE
              </Link>
            </p>
          </Col>
        </Row>
        <Row className="m-0 mt-5 p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile ? { fontSize: "16px" } : { marginLeft: "0px" }
              }
            >
              The Results
            </h3>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px", }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Clean water and remove 97% of scales.
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Efficient cooling tower applications
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                {">"}25% reduction in energy
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px", }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Water re-claim
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px", }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                <b>Zero operating costs</b> – requires no chemicals, magnets or
                electricity
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px", }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                {" "}
                <b>Safe, </b>chemical-free operation—no special handling or
                storage requirements
              </p>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-0" : "p-0"
            }`}
          >
            <Image fluid src={p19} alt="Image"  />
            <div className="tinyText " style={{maxWidth:'300px',width:'300px'}}>
            <p className="poppins-Regular mt-3 "  >
                A thickness of <span className="orange"> 0.635 mm </span> or
                <span className="orange"> 0.025” </span> of scale in hot or cold
                water increase energy expenses by
                <span className="orange"> 25% </span>{" "}
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default WaterTreatment;
