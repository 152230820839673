import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p120 from "../../../assets/images/27. Latest In Property Water Management.1366x768/120.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail3 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How Water Meter Inaccuracies, Increase Your Water Fees
              Significantly.
            </h3>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Have you ever washed your hands, take a shower or fill a cup of
              water under the faucet and wonder why there are so many bubbles?
              Quite frankly, you are not alone.
            </p>
            <Image
              // fluid
              src={p120}
              alt="image"
              style={{ height: "15%", width: "95%", objectFit: "cover" }}
            />
            <p
              className="poppins-Regular mt-1"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The fact is, air will always be in your water main, and over 40%
              in some cases. And, regardless of the type of water flow metering
              system used on your premises, the air volume is still measured,
              recorded and billed into your monthly water utility fees.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              So, where does this air volume comes from?
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Air may enter your pipelines in a combination of ways;
            </p>

            <ol
              className="m-0 p-0"
              style={{ listStyle: "inside", listStyleType: "number" }}
            >
              <li
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                At the source of the intake – at the supply’s main during
                pumping. The pumps generate bubbles during the inlet pumping
                cycles – where some of these bubbles collapse and die out.
                However, a significant amount of bubbles survives the pump’s
                pumping force and becomes “entrained air” that are transported
                in the pipelines – air is also directly pumped into the main to
                prevent cavitation.
              </li>
              <li
                className="poppins-Regular my-3"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Transport from the city. For water to be delivered on to your
                premises, the pump has to work under high pressure, which causes
                a great level of turbulence to be generated. Oxygen from the
                water molecules is shed during this transport process, seen as
                bubbles.
              </li>
              <li
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Emptying of the pipelines – results in a significant drop in
                pressure. The pressure drop and the volume of water will retract
                creating a void, filled by air – in cases of no back-flow
                valves. As a result of re-filling the line, this volume of air
                will flow onto your premises.
              </li>
            </ol>

            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Then why do my property manager or myself end up paying for air?
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Consequently, flow meters are unable to distinguish between air
              and water in the air-water mixture, and the volume read and
              recorded will represent your high water-fees.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How is it that my flow meter cannot differ air from water?
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Your commercial or residential property water usage is monitored
              using one of several types of water flow meters – with the most
              common being the traditional mechanical flow meter. The mechanical
              flow meters are made from moving parts – consisting of a propeller
              or turbine or some other rotating device, that rotates and then
              used to represent the volume of water used. When there is air
              present in the pipeline, the turbine rotates at a faster speed due
              to air speed and low friction (resistance). As a result, your
              water fees increase every time there are pockets of air.
              <br /> In the case of the ultrasonic meters, they don’t rely on
              moving parts to record water usage. Instead, readings are done
              using ultrasonic wave principles (Info on how this works is shown
              here) for full pipes and partially filled pipes.
              <br />
              <br />
              For full pipe water applications – it is assumed that the pipe is
              FULL, with no air pockets or entrained air. This, however is a
              wrong assumption by these working principles, as air at a high
              percentage is still trapped (entrained air), inside the pipelines,
              due to pumping and other water system transport phenomena.
              Therefore, these water meters are inefficient to measure
              accurately the “true” water volume usages. The only advantage to
              these meters and air is that they can detect when the pipe is not
              full and trigger a turn-off measuring. This is not ideal and is a
              big shortcoming of these meters, because whilst the pipe is not
              full – water is still being transported onto the premises – which
              are not recorded but may be investigated in the case of City
              utility audits.
              <br />
              <br />
              Ultrasonic meters also require that your pipe system should have
              air release valves placed at high points, or not to install these
              ultrasonic meters at a high point that could retain air pockets.
              This is because, entrained air bubbles inhibit the propagation of
              ultrasonic waves in the ultrasonic flow meters, resulting in
              malfunctions. Therefore, using this intelligent water valve will
              “transform” the air-water mixture into water which will improve
              water metering, and gives more accurate water utility fees.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How does I or my property managers stop paying for air?
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              When a flow regulator, such as the Smart Series 1 (Smart Water
              Valve) – the only patented technology of its kind, is installed
              just behind the flow meter, the air molecules become compressed
              before the air-water mixture reaches the propeller in the water
              flow meter. Under compression, the air volume is reduced to naught
              and the volume that was once the air is now filled with water
              (“transforming” the air-water mixture to water only). This volume
              of water is now able to pass the water flow meter air-free,
              slowing down the propeller rate – resulting in a smooth desirable
              laminar flow. This valve’s effect is triple fold for any property.
              Firstly, it creates a steady flow of water, which means that water
              pressure will not damage pipe fixtures on the property.
              <br />
              <br />
              Secondly, the valve also prevents back-flow of water – keeping the
              line charged with water. Thirdly, it reduces water fees.
              Significant air in a mechanical meter can also break the measuring
              elements causing error – leading to high erroneous water fees.
              Overall air should not be in your water line. It can cause
              corrosion, hydrogen sulphide build up, water hammer, discoloured
              water from the turbulence, and damage to some mechanical meters
              and fixtures.
              <br />
              <br />
              <u className="blue">Connect</u> with a competent team of engineers
              and plumbers to find out how we may be able to help reduce your
              water expenses.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail3;
