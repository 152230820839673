import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p129 from "../../../assets/images/27. Latest In Property Water Management.1366x768/129.png";
import p130 from "../../../assets/images/27. Latest In Property Water Management.1366x768/130.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail11 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Understanding How Your Water Flow Meter Works: Property Management
            </h3>
            <br />

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Sometimes, the very basic benchmarks are commonly overlooked, such
              as how a water flow meter really works. Understanding, several key
              features can save thousands of dollars if you know what to look
              for. We have put together a very short overview of how to read a
              water flow meter, as well as how the meter is oriented based on
              the local municipality requirements.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Placement
            </h3>
            <Image
              // fluid
              src={p129}
              alt="image"
              style={{ height: "15%", width: "95%", objectFit: "fill" }}
            />

            <p
              className="poppins-Regular mt-3"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The image of the water meter in the service box shows a typical
              water service for a commercial customer. Starting from the street
              side of the service, #1 is the district's service valve (to be
              manipulated ONLY by district technicians), #2 is the water meter
              (which records your water use), and #3 is the customer handle,
              installed so you can shut off the water to your home in case of an
              emergency.  The water meter is equipped with our Smart water
              monitor system for real time data acquisition and
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Reading
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The meter dial pictured is typical of most residential meters. The
              dial on the meter serving your property may look somewhat
              different, but they all work on the same principles.
            </p>

            <Image
              fluid
              src={p130}
              alt="image"
              className="align-self-center m-5"
            />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <i>This flow indicator triangle </i>(#1.) rotates whenever water
              flows through the meter. If the triangle turns when all the water
              is off on your property, you may have a leak, which should be
              investigated
              <br />
              <br />
              Each full revolution of the <i>register sweep hand </i>(#2.)
              indicates that one cubic foot of water (about 7.48 gallons) has
              passed through the meter. The markings at the outer edge of the
              dial indicate tenths and hundredths of one cubic foot. 
              <br />
              <br />
              The <i>water meter register </i>(#3.) is a lot like the mileage
              odometer on your car. The numbers keep a running total of all the
              water that has passed through the meter. How does the City read
              this meter?  The meter is read in HCF (Hundred Cubic Feet), the
              HCF is then converted to units of water.  One unit of water is 748
              gallons. For example, if your previous meter read was 1,325 and
              today's read is 1,332, you have used 7 units of water between
              reads.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail11;
