import React from "react";
import { Container } from "react-bootstrap";
import sliderVedio from "../../assets/vedios/24601830.mp4";

const CustomVideo = () => {
  return (
    <Container fluid className="m-0 p-0">
      <video
        height={"50%"}
        width={"100%"}
        autoPlay
        loop
        muted
        src={sliderVedio}
        type="video/mp4"
      />
    </Container>
  );
};

export default CustomVideo;
