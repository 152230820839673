import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p124 from "../../../assets/images/27. Latest In Property Water Management.1366x768/124.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail8 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Cap Rate Influencer : Lowering Water Costs Increases Asset
              Value
            </h3>
            <br />

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Spending unusually high prices on energy cost, what if this is
              related to water usages?
            </p>
            <Image
              // fluid
              src={p124}
              alt="image"
              style={{ height: "15%", width: "95%", objectFit: "cover" }}
            />
            <p
              className="poppins-Regular mt-3"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Enhancing Asset Value through Lowering Water Costs
              <br />
              <br />
              Enhancing Asset Value through Lowering Water Costs
              <br />
              <br />
              The value of income properties or investment real estate is
              determined by applying the Capitalization Rate (Cap Rate) to the
              property's Net Operating Income (NOI). For instance, a property
              generating $1,000,000 in Gross Income and having $900,000 in
              Operating Expenses would result in an NOI of $100,000.
              <br />
              <br />
              Different factors, such as asset classes, markets, and market
              conditions, contribute to varying Cap Rates.
              <br />
              <br />
              If the market is willing to pay $2,000,000 for the aforementioned
              property with $100,000 NOI, it indicates a 5 Cap Rate. By dividing
              $100,000 by 0.05, we arrive at $2,000,000. Similarly, if
              comparable properties in the market have a 7 Cap Rate, the
              property mentioned would be valued at $100,000 divided by 0.07,
              which equals $1,428,571.
              <br />
              <br />
              Increasing rents or reducing expenses leads to higher NOI, thus
              positively impacting the property's value.
              <br />
              <br />
              How Utility Bill Savings Influence Your Cap Rate
              <br />
              <br />
              For a property with a market Cap Rate of 5, a $1 decrease in water
              expenses would increase the asset value by $20 (calculated as $1
              divided by 0.05). Similarly, for a property with a 7 Cap Rate, the
              asset value would increase by $14.29.
              <br />
              <br />
              If you spend $10,000 per month or $120,000 per year on water
              expenses and manage to reduce it by 10%, you will add an extra
              $12,000 to your bottom line or NOI. This would immediately boost
              the market value of a property with a 5 Cap Rate by $240,000 or a
              7 Cap Rate by $171,429.
              <br />
              <br />
              Focusing on Increasing Your Asset's Value
              <br />
              <br />
              While some people associate this approach with being
              environmentally friendly ("green"), and others view it as reducing
              water usage, the main objective is not solely about those aspects.
              It revolves around increasing asset value, which is the primary
              goal for real estate investors, owners, and managers. The
              underlying principle is to buy low and sell high.
              <br />
              <br />
              Although calculating ROI and payback using investments and
              operational savings may generate discussions, property owners
              should keep the bigger picture in mind:
              <br />
              <br />
              "What impact will this have on my property's market value? What
              ROI can I expect from spending, let's say, $20,000 to increase the
              asset's market value by $171,000?"
              <br />
              <br />
              It's a remarkable return on investment that is hard to find
              elsewhere!
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail8;
