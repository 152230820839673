import React,{useState} from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import { Link } from "react-router-dom";
import P64 from "../../assets/images/P64.jpg";
import QuickApply from "../../components/quickApply/QuickApply";
import { useEffect } from "react";

const PortfolioSales = () => {
  const [show,setShow]=useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : { marginLeft: "0px" }
            }
          >
            CONTRACTED PORTFOLIO SALES-REP
          </h1>
        </Row>
        <Row className={`m-0 p-0 ${isDesktopOrLaptop && 'px-5'}  text`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            SALES REPRESENTATIVE DUTIES AND RESPONSIBILITIES
          </h3>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The duties and responsibilities of Sales Representatives typically
            revolve around networking with potential and current customers and
            demonstrating the product’s benefits. There duties and
            responsibilities needed to excel in this position include:
          </p>
          <ul className={`${isDesktopOrLaptop && 'px-5'} text`}>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Assisting customers as they shop for new products or services
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Making product suggestions according to the wants and needs of
              customers
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Locating prospective customers, generating them into leads, and
              eventually converting them to customers
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Meeting or exceeding weekly, monthly, and quarterly sales quotas
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Using strong research skills and deep product or industry
              knowledge to answer any questions about the product
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Preparing and submitting weekly sales or inventory reports to
              management
            </li>
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Keeping contact lists and following up with current customers to
              continue building relationships
            </li>
          </ul>
        </Row>
        <Button
          variant="dark"
          size="sm"
          className="poppins-Regular "
          style={{ marginLeft: "7%" }}
          onClick={()=>{
            setShow(true)
          }}
        >
          Quick Apply
        </Button>
      </Container>
      
      <QuickApply isVisible={show} hideModel={()=>{
        setShow(false)
      }} />
    </MasterLayout>
  );
};

export default PortfolioSales;
