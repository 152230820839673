import React, { useEffect } from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Image, Button, Table } from "react-bootstrap";
import "../../assets/css/waterTech.css";
import { Link, useNavigate } from "react-router-dom";

import p36 from "../../assets/images/9. Water Stewardship and Business Value. 1366x768/36.png";
import p37 from "../../assets/images/9. Water Stewardship and Business Value. 1366x768/37.png";
import { useMediaQuery } from "react-responsive";
import PortfolioNavbar from "../../components/navbars/PortfolioNavbar";
import { GiCheckMark } from "react-icons/gi";
import { scrollToTop } from "../../helper/HelperFunctions";
import CustomQuotes from "../../components/customQuotes/CustomQuotes";
import { useState } from "react";

function WaterStewardship(props) {
  const navigate=useNavigate()
  const [customModal,setCustomModal]=useState(false)
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

 
  useEffect(()=>{
    scrollToTop()
  },[])

  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            Water Stewardship and Business Value
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Are you looking to optimize your water use and reduce costs in your
            facility?
          </h3>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "px-5 py-4" : "p-4"
            }`}
          >
            <Image fluid src={p36} alt="First Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Then explore Water Solutions Technology comprehensive 11 steps,
              step-by-step guide to achieve efficient facility water management.
              To learn more about our vision, mission and objective please read
              more <Link className="link poppins-SemiBold">here.</Link>
            </p>
            <Button
              variant="dark"
              size="md"
              className={`poppins-Regular align-self-start ${
                isDesktopOrLaptop && "mx-3"
              } `}
              onClick={()=>{
                setCustomModal(true)
              }}
            >
              Discover
            </Button>
          </Col>
        </Row>
        <Row className="m-0 p-0 my-2 text">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            Manage & Optimize Water Use
          </h1>
          <h3
            className="poppins-SemiBold portfolioSmallTitle my-2"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "-12px" } : {}
            }
          >
            Water Solutions Technology 11 steps, step-by-step Guide for
            Effective Facility Water Management
          </h3>
        </Row>
        <Row className={`m-0 p-0   text ${isDesktopOrLaptop ? "px-5":''}`}>
          <Col xs={1}  className="m-0 p-0">
          <Image src={p37}  fluid  className="w-60 h-100" style={{ objectFit: 'cover' }} />
          </Col>
          <Col xs={11}  className="m-0 p-0" >
          <Table
            responsive
            bordered
            striped
            // hover
            // variant="primary"
            // variant="light"
            size={isDesktopOrLaptop ? "lg" : "sm"}
            className="smallText my-4  "
          >
            {/* <thead>
              <tr>
                <th className="poppins-SemiBold">1</th>
                <th className="poppins-SemiBold">2</th>
                <th className="poppins-SemiBold">3</th>
              </tr>
            </thead> */}
            <tbody>
              <tr  key={0} 
              // className="table-primary"
               >
                <td className="poppins-Regular" style={{ width: "10%" }}>Step 1:</td>
                <td className="poppins-Regular ">Water Use Audit</td>
                <td className="poppins-Regular">
                  -Begin by assessing your current water consumption patterns.
                  <br />
                  -Gain valuable insights into where your facility's water is
                  going.
                </td>
              </tr>
              <tr key={1} >
                <td className="poppins-Regular">Step 2:</td>
                <td className="poppins-Regular">Smart Technology Placement</td>
                <td className="poppins-Regular">
                  -Strategically install water meters, smart water valves and
                  smart monitoring throughout your facility.
                  <br />
                  -Ensure precise and accurate measurement of water usage.
                </td>
              </tr>
              <tr  >
                <td className="poppins-Regular">Step 3:</td>
                <td className="poppins-Regular">
                  Data Monitoring in Real-time
                </td>
                <td className="poppins-Regular">
                  -Regularly record water reading and analyse the data.
                  <br />
                  -Identify trends and unusual water usage patterns using the
                  Smart Water Monitoring <sup>TM</sup> kit.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 4:</td>
                <td className="poppins-Regular">
                  Identifying High Consumption Areas
                </td>
                <td className="poppins-Regular">
                  -Discover areas with excessive water use.
                  <br />
                  -Detect trends and anomalies that require attention.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 5:</td>
                <td className="poppins-Regular">
                  Unconvering water conservation Oppertunities
                </td>
                <td className="poppins-Regular">
                  -Find ways to save water.
                  <br />
                  -Address immediate concerns like leaks and inefficiencies.
                  <br />
                  -Optimize operational practices in areas such as cleaning,
                  laundry and kitchens.
                  <br />
                  -Explore engineering solutions for more complex issues.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 6:</td>
                <td className="poppins-Regular">Cost Assessment and ROI</td>
                <td className="poppins-Regular">
                  -Calculate the associated with water-saving oppertunities.
                  <br />
                  -Evaluate the potential return on investment.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 7:</td>
                <td className="poppins-Regular">Prioritization</td>
                <td className="poppins-Regular">
                  -Prioritize your water conservation initiative based on
                  factors like impact, cost-effectiveness and feasibility.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 8:</td>
                <td className="poppins-Regular">Develop a Phased plan</td>
                <td className="poppins-Regular">
                  -Create a phased water management plan.
                  <br />
                  -Ensure it aligns with your facility's budget and resource allocation.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 9:</td>
                <td className="poppins-Regular">Secure Funding</td>
                <td className="poppins-Regular">
                  -Seek financial support as needed to implement your plan.
                  <br />
                  -Adjust the plan according to available funding.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular" >Step 10:</td>
                <td className="poppins-Regular">Implemention</td>
                <td className="poppins-Regular">
                  -Put your water-saving plan into action.
                  <br />
                  -Ensure all measures are efficiently executed.
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular">Step 11:</td>
                <td className="poppins-Regular">Continuous Monitoring</td>
                <td className="poppins-Regular">
                  -Keep track of the resuts and progress.
                  <br />
                  -Document the success of your water conservation initiatives.
                </td>
              </tr>
            </tbody>
          </Table>
        
          </Col>
          <p
            className="poppins-Regular "
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            By following Water Solutions Technology structured guide, along with
            our team that has intimate knowledge with addressing water
            management, you can effectively manage water resources within your
            facility, reduce costs, and contribute to environmental
            sustainability. To take proactive steps towards optimized water
            management today get in touch with us at{" "}
            <u className="blue"> acc@watersolutech.com </u> or by filling out
            the quick contact form below.
          </p>
        </Row>
        <Container fluid className="m-0 p-0 px-5  d-flex  justify-content-end">
          <Button
            variant="dark"
            size="sm"
            className={` ${isDesktopOrLaptop && "m-3"}`}
            onClick={()=>{
              window.open("https://cmsws.alleimran.com/propertyOwner/signup")
            }}
          >
            Quick Contact
          </Button>
        </Container>
      </Container>
      <CustomQuotes isVisible={customModal} hideModel={()=>{
        setCustomModal(false)
      }} />
    </MasterLayout>
  );
}

export default WaterStewardship;
