import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Image,
  Row
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../assets/css/fontStyles.css";
import "../assets/css/waterTech.css";
import p1 from "../assets/images/1. Homepage.1.1366x768/1.png";
import p10 from "../assets/images/1. Homepage.1.1366x768/10.png";
import p11 from "../assets/images/1. Homepage.1.1366x768/11.png";
import p2 from "../assets/images/1. Homepage.1.1366x768/2.png";
import p3 from "../assets/images/1. Homepage.1.1366x768/3.png";
import p4 from "../assets/images/1. Homepage.1.1366x768/4.png";
import p5 from "../assets/images/1. Homepage.1.1366x768/5.png";
import p6 from "../assets/images/1. Homepage.1.1366x768/6.png";
import p9 from "../assets/images/1. Homepage.1.1366x768/9.png";
import keyexc from "../assets/images/1. Homepage.1.1366x768/keyexc.webp";

import CountUp from "react-countup";
import { AiOutlineStop } from "react-icons/ai";
import { BsSpeedometer2 } from "react-icons/bs";
import { GiCheckMark } from "react-icons/gi";
import { IoIosNotificationsOutline } from "react-icons/io";
import { IoArrowForwardCircleOutline, IoWaterOutline } from "react-icons/io5";
import { PiDropHalf } from "react-icons/pi";
import { SlDrop } from "react-icons/sl";
import CustomVideo from "../components/homeScreenComponents/CustomVideo";
import MasterLayout from "../layouts/MasterLayout";
// import ReactVisibilitySensor from "react-visibility-sensor";
import VisibilitySensor from "react-visibility-sensor";
import { scrollToTop } from "../helper/HelperFunctions";

const Home = () => {
 
  const [startCount, setStartCount] = useState(false);
  const [showSecondImage, setShowSecondImage] = useState(false);

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });

  useEffect(() => {
    scrollToTop();
    const handleScroll = () => {
      const isScrolled = window.scrollY > 2000;
      console.log(isScrolled);
      setShowSecondImage(isScrolled);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <MasterLayout>
      <div className="">
        <CustomVideo />
      </div>
      <Container
        fluid
        className={`m-0 ${isDesktopOrLaptop ? "pageContainer" : "p-0"} `}
      >
        <Row xs={12} className="m-0 p-0 my-5 ">
          <h1
            class="homeTitle poppins-SemiBold text-center my-3"
            style={isTabletOrMobile ? { fontSize: "18px" } : {}}
          >
            Strategic Water Stewardship: Conserving & Cutting Fees Upwards of
            30%
          </h1>
        </Row>
        <Row
          xs={12}
          className={`m-0 p-0  text  ${isDesktopOrLaptop ? "px-5" : "px-2"}`}
        >
          <h5
            className="poppins-SemiBold portfolioSmallTitle"
            style={{
              fontSize: isTabletOrMobile ? "15px" : "18px",
              color: "#555555",
              marginLeft: "0px",
            }}
          >
            ABOUT WATER SOLUTIONS TECHNOLOGY
          </h5>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Water Solutions Technology is a leader in the design and development
            of water management{" "}
            <span className="red"> strategy and approaches </span> for
            <span className="red"> modern water stewardship practices </span>.
            We create exceptional water and energy savings strategies with our
            patented technologies for efficient balance of consumptive and
            non-consumptive water usages.
            <b className="mediumBlack"> We are here to help you</b>
          </p>
          <h6
            className="poppins-SemiBold mt-3 text-dark portfolioSmallTitle"
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            (No. 1 in Property Water Management Choice, South Florida, New York
            – and Nationwide)
          </h6>
        </Row>
        <Row xs={12} className="m-0 p-5 ">
          <h1
            className="homeTitle poppins-SemiBold text-center my-5"
            style={isTabletOrMobile ? { fontSize: "18px" } : {}}
          >
            Partnering with customers to solve their greatest challenges.
          </h1>
          <Col
            xs={12}
            md={12}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0"
          >
            <Card
              style={{ width: "20rem", borderRadius: "2rem" }}
              className="py-4 d-flex flex-column text  align-items-center  border-secondary "
            >
              <PiDropHalf size={"50"} />

              <Card.Body className="p-0 m-0 mt-4">
                <Card.Subtitle className=" my-1 poppins-Regular text-muted text-center">
                  Water
                </Card.Subtitle>
                <Card.Title
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Measurement
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0 "
          >
            <Card
              style={{ width: "20rem", borderRadius: "2rem" }}
              className="py-4 d-flex flex-column text align-items-center  border-secondary "
            >
              <IoWaterOutline size={"50"} />

              <Card.Body className="p-0 m-0 mt-4 ">
                <Card.Subtitle className=" my-1 poppins-Regular text-muted text-center">
                  Water
                </Card.Subtitle>
                <Card.Title
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Efficiency & Conservation
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0"
          >
            <Card
              style={{ width: "20rem", borderRadius: "2rem" }}
              className="py-4 d-flex flex-column text align-items-center  border-secondary "
            >
              <SlDrop size={"50"} />

              <Card.Body className="p-0 m-0 mt-4">
                <Card.Subtitle className=" my-1 poppins-Regular text-muted text-center">
                  Water
                </Card.Subtitle>
                <Card.Title
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Quality
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h1
              className="portfolioTitle poppins-SemiBold"
              style={
                isTabletOrMobile
                  ? { fontSize: "22px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Water Fees are an Inevitable Expense
            </h1>
            <h3
              className="poppins-SemiBold portfolioSmallTitle blue"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Conserving water, is conserves energy.
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water and sewer fees accounts for up to 30% of most operational
              costs. Water meters are a utility's cash register, and the
              solution begins with measurement integrity at each water meter.
              What if you could reduce these fees by a 3rd?
            </p>
          </Col>
          <Col
            lg={6}
            sm={12}
            md={6}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <Image fluid src={p1} alt="image" />
            <h6
              className="poppins-SemiBold portfolioTinyTitle "
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              Water and sewer fees continues to climb and is expected to reach a
              6.37% increment.
            </h6>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h1
              className="portfolioTitle poppins-SemiBold"
              style={
                isTabletOrMobile
                  ? { fontSize: "22px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              New Generation Patented & Approved Water Technologies
            </h1>
            <h3
              className="poppins-SemiBold portfolioSmallTitle blue"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              To Reduce Energy Usage, Water Wastage & Water Fees
            </h3>

            <p
              className="poppins-Regular mt-4"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              US Patented and Engineered{" "}
            </p>
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              Zero Maintenance for all our Equipment{" "}
            </p>
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              Short Payback Period, with Fast ROI (12-24 months){" "}
            </p>
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              90-Day Guaranteed – all your money back*{" "}
            </p>
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              Green Building Enhancement (Asset Value){" "}
            </p>
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              NSF Approved{" "}
            </p>
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              <GiCheckMark color="orange" style={{ marginRight: "15px" }} />
              10 Year Warranty
            </p>
            <Link
              className="link text poppins-SemiBold mx-4"
              style={{ textDecoration: "underline" }}
            >
              READ MORE
            </Link>
          </Col>
          <Col
            lg={6}
            sm={12}
            md={6}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <Image fluid src={showSecondImage ? p2 : p3} alt="image" />
          </Col>
        </Row>
        <Row className="m-0 p-5  ">
          <h1
            className="homeTitle poppins-SemiBold text-center my-5"
            style={isTabletOrMobile ? { fontSize: "18px" } : {}}
          >
            Partnering with customers to solve their greatest challenges.
          </h1>

          <Col
            xs={12}
            md={4}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0 "
          >
            <Card
              style={{
                width:isDesktopOrLaptop? "20rem":'14rem',
                boxShadow: " 0 5px 15px rgba(0, 0, 0, 0.1)",
              }}
              className="py-4 d-flex flex-column text align-items-center   "
            >
              <Card.Body className="p-0 m-0  ">
                <Card.Subtitle
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Gallons Of Water Saved
                </Card.Subtitle>
                <Card.Title
                  className="poppins-SemiBold portfolioTitle text-center blue mt-4"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  <VisibilitySensor
                    partialVisibility
                    onChange={(visible) => {
                      setStartCount(visible);
                    }}
                    offset={{ bottom: 200 }}
                  >
                    <CountUp
                      start={-1}
                      // end={1.1}
                      end={startCount ? 1.1 : 0}
                      duration={3}
                      decimals={1}
                      suffix="million +"
                    />
                  </VisibilitySensor>
                </Card.Title>
                <Card.Subtitle
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  in 2022
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0  "
          >
            <Card
              style={{
                width:isDesktopOrLaptop? "20rem":'14rem',
                boxShadow: " 0 5px 15px rgba(0, 0, 0, 0.1)",
              }}
              className="py-4 d-flex flex-column text align-items-center   "
            >
              <Card.Body className="p-0 m-0  ">
                <Card.Subtitle
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Smart Technologies
                </Card.Subtitle>
                <Card.Title
                  className="poppins-SemiBold portfolioTitle text-center blue mt-4"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  <VisibilitySensor
                    partialVisibility
                    onChange={(visible) => {
                      setStartCount(visible);
                    }}
                    offset={{ bottom: 200 }}
                  >
                    <CountUp
                      start={1}
                      // end={20}
                      end={startCount ? 20 : 0}
                      duration={5}
                      suffix="K +"
                   
                    />
                  </VisibilitySensor>
                </Card.Title>
                <Card.Subtitle
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  installed to date
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={4}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0 "
          >
            <Card
              style={{
                width:isDesktopOrLaptop? "20rem":'14rem',
                boxShadow: " 0 5px 15px rgba(0, 0, 0, 0.1)",
              }}
              className="py-4 d-flex flex-column text align-items-center   "
            >
              <Card.Body className="p-0 m-0  ">
                <Card.Subtitle
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Water Fees Saved
                </Card.Subtitle>
                <Card.Title
                  className="poppins-SemiBold portfolioTitle text-center blue mt-4"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  <VisibilitySensor
                    partialVisibility
                    onChange={(visible) => {
                      setStartCount(visible);
                    }}
                    offset={{ bottom: 200 }}
                  >
                    <CountUp
                      start={-1}
                      // end={21.3}
                      end={startCount ? 21.3 : 0}
                      duration={5}
                      decimals={1}
                      suffix="%"
                  
                    />
                  </VisibilitySensor>
                </Card.Title>
                <Card.Subtitle
                  className="poppins-SemiBold portfolioSmallTitle text-center"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "14px", marginLeft: "0px" }
                      : {
                          marginLeft: "0px",
                        }
                  }
                >
                  Annually Averaged
                </Card.Subtitle>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h1
              class="portfolioTitle poppins-SemiBold text-center"
              style={
                isTabletOrMobile
                  ? { fontSize: "22px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Real world Improvement with Water Solutions Technology
            </h1>
            <h3
              className="poppins-SemiBold portfolioSmallTitle blue"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Sharing our knowledge and experience to clients’ benefit
            </h3>

            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              With our step-by-step unique approach, we hold your hands. This is
              how we treat our clients and partners no matter if you are great
              or small. We deep dive into your gaols and help set and generate
              new ones as it relates to savings, energy and water in your
              portfolio and budget.
            </p>

            <Link
              to={"/caseStudies/hospitality"}
              className="link portfolioTinyTitle poppins-SemiBold my-3"
            >
              READ MORE ON CASE STUDIES RESULTS
              <IoArrowForwardCircleOutline size={"30"} color="#333333" />
            </Link>
          </Col>
          <Col
            lg={6}
            sm={12}
            md={6}
            className={`portfolio d-flex flex-column justify-content-center align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h6
              className="poppins-Regular portfolioTinyTitle mx-3  "
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              Effortlessly conserving water, is saving money, and the
              environment
            </h6>
            <Image fluid src={p4} alt="image" />
            <br />
            <p
              className="poppins-Regular mx-4"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Data shows that assets that integrate sustainable platforms
              average 11% greater net operating income and 6.2% occupancy more
              than properties that overlook sustainability.
              <br />
              <br />
              "Green" buildings are in demand because they reduce operating
              costs, encourage better health and wellness, boost productivity,
              and lead to greater tenant satisfaction.{" "}
              <Link className="link poppins-SemiBold">READ MORE</Link>
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-5  ">
          <h1
            class="portfolioTitle poppins-SemiBold text-center "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Important Building Water Metrics Managed by Water Solutions
            Technology
          </h1>

          <Col
            xs={12}
            md={12}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center mb-4 mb-lg-0 "
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Water <span className="blue"> Monitoring </span>
                <IoIosNotificationsOutline color="orange" size={"30"} />
              </Card.Title>
              <Card.Img
                alt="image"
                src={p9}
                style={{
                  height: isTabletOrMobile ? "120px" : "180px",
                  width: isTabletOrMobile ? "200px" : "300px",
                  borderRadius:'0px',
                }}
              />
              <Card.Body className="p-0 m-0 mt-2 px-3 text d-flex flex-column align-self-start ">
                <Card.Text
                  className="poppins-Regular "
                  style={
                    isDesktopOrLaptop
                      ? { fontSize: "16px" }
                      : { fontSize: "14px" }
                  }
                >
                  Detecting a leak at the time of the leak is good, but
                  predicting the leak before it happens is the way forward.
                </Card.Text>
                <Card.Link
                  as={Link}
                  to={"/portfolio/watermonitoring"}
                  className="link poppins-SemiBold portfolioTinyTitle "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  READ MORE
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center  mb-4 mb-lg-0 "
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Water <span className="orange"> Consumption </span>
                <BsSpeedometer2 color="orange" size={"30"} />
              </Card.Title>
              <Card.Img
                alt="image"
                src={p10}
                style={{
                  height: isTabletOrMobile ? "120px" : "180px",
                  width: isTabletOrMobile ? "200px" : "300px",
                  borderRadius:'0px',
                }}
              />
              <Card.Body className="p-0 m-0 mt-2 px-3 text d-flex flex-column align-self-start ">
                <Card.Text
                  className="poppins-Regular "
                  style={
                    isDesktopOrLaptop
                      ? { fontSize: "16px" }
                      : { fontSize: "14px" }
                  }
                >
                  Knowing your yearly gallons is a good indication of what your
                  usage is, but predicting what activity in your building
                  consumes how much and at what times, is the way of the future.
                </Card.Text>
                <Card.Link
                  as={Link}
                  to={"/portfolio/waterconsumption"}
                  className="link poppins-SemiBold portfolioTinyTitle "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  READ MORE
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col
            xs={12}
            md={12}
            lg={4}
            className="m-0 p-0 d-flex flex-column align-items-center  mb-4 mb-lg-0 "
          >
            <Card
              style={{
                width: "22rem",
                height: "100%",
                border: isDesktopOrLaptop && "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Title
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Water <span className="blue"> Treatment </span>
                <AiOutlineStop color="orange" size={"30"} />
              </Card.Title>
              <Card.Img
                alt="image"
                src={p11}
                style={{
                  height: isTabletOrMobile ? "120px" : "180px",
                  width: isTabletOrMobile ? "260px" : "380px",
                  borderRadius:'0px',
                  overflow:'hidden'
                }}
              />
              <Card.Body className="p-0 m-0 mt-2 px-3 text d-flex flex-column align-self-start ">
                <Card.Text
                  className="poppins-Regular "
                  style={
                    isDesktopOrLaptop
                      ? { fontSize: "16px" }
                      : { fontSize: "14px" }
                  }
                >
                  Using patented non-invasive technologies (Electronic
                  Treatment) to remove ions that cause scaling, will increase
                  the life of plumbing fixtures, with no downtime for pipe
                  replacement.
                </Card.Text>
                <Card.Link
                  as={Link}
                  to={"/portfolio/watertreatment"}
                  className="link poppins-SemiBold portfolioTinyTitle "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  READ MORE
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold text-center my-5"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            A few selected satisfaction - there very words!
          </h1>
          <Col
            lg={4}
            xs={12}
            md={12}
            className={`smallText m-0  ${isDesktopOrLaptop ? "p-3" : "p-3"}`}
          >
            <h6
              className={`poppins-SemiBold portfolioTinyTitle text-center text-dark my-3 `}
            >
              “After installing the smart valves we are seeing savings of 20%. “
            </h6>
            <p
              className="poppins-Regular  text-center "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Peter – Energy Committee 77/99 Harbour Square, Toronto
            </p>
          </Col>
          <Col
            lg={4}
            xs={12}
            md={12}
            className={`smallText m-0  ${
              isDesktopOrLaptop ? "p-0 px-2" : "p-3"
            }`}
          >
            <h6 className="poppins-SemiBold portfolioTinyTitle text-center text-dark">
              “It’s a capital cost which lowers expenses thus increasing cash
              flow, and Net Operating Income. In my business a higher NOI
              increases the value of my property. Thus far, the purchase of the
              valve has proven to be a very good return on the investment.”
            </h6>
            <p
              className="poppins-Regular  text-center"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Smart Valve User – Manager, The Villas of White Rock.
            </p>
            <h6 className="poppins-SemiBold portfolioTinyTitle text-center orange mt-5">
              While we understand you may want to use our technologies, they may
              not be for everyone !
            </h6>
          </Col>
          <Col
            lg={4}
            xs={12}
            md={12}
            className={`smallText m-0  ${
              isDesktopOrLaptop ? "px-5 py-3" : "p-3"
            }`}
          >
            <h6 className="poppins-SemiBold portfolioTinyTitle text-center text-dark mt-3">
              “In two years since installed the savings in water are averaging
              23%”
            </h6>
            <p
              className="poppins-Regular  text-center  my-3"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Bob – Manager Grand Central, Florida.{" "}
            </p>
            <h6 className="poppins-Medium portfolioTinyTitle text-center text-dark mt-5">
              Find our if you qualify
            </h6>
          </Col>
        </Row>
        <Row xs={12} className=" m-0 p-0 mt-5 ">
          <Col xs={12} md={12} lg={4} className="m-0 p-0">
            <Link
              to="/caseStudies/hospitality"
              className="link poppins-Medium d-flex flex-column align-items-center mb-2 mb-lg-0"
            >
              <u style={{ fontSize: isDesktopOrLaptop ? "16px" : "14px" }}>
                READ MORE ON CASE STUDIES RESULTS
                <IoArrowForwardCircleOutline
                  size={"30"}
                  color="#333333"
                  className="mx-1"
                />
              </u>
            </Link>
          </Col>
          <Col xs={12} md={12} lg={4} className="m-0 p-0">
            <Link
              to="/caseStudies/hospitality"
              className="link poppins-Medium d-flex flex-column align-items-center mb-2 mb-lg-0"
            >
              <u style={{ fontSize: isDesktopOrLaptop ? "16px" : "14px" }}>
                IT’S NOT FOR EVERYONE: FIND OUT MORE
                <IoArrowForwardCircleOutline
                  size={"30"}
                  color="#333333"
                  className="mx-1"
                />
              </u>
            </Link>
          </Col>
          <Col xs={12} md={12} lg={4} className="m-0 p-0">
            <Link
              to="/caseStudies/hospitality"
              className="link poppins-Medium d-flex flex-column align-items-center mb-2 mb-lg-0 "
            >
              <u style={{ fontSize: isDesktopOrLaptop ? "16px" : "14px" }}>
                FOR OUR NO UPFRONT COST PROGRAMME
                <IoArrowForwardCircleOutline
                  size={"30"}
                  color="#333333"
                  className="mx-1"
                />
              </u>
            </Link>
          </Col>
        </Row>
        <Row className=" p-0 m-0 mt-5 px-3 d-flex flex-row justify-content-around ">
          <Col
            md={5}
            className="m-0 p-0 d-flex flex-column align-items-center  "
          >
            <Card
              style={{
                width: isDesktopOrLaptop ? "32rem" : "24rem",
                border: "none",
              }}
              className="py-2 px-1 d-flex flex-column  align-items-center"
            >
              <Card.Img
                alt="image"
                src={p5}
                style={{
                  height: isTabletOrMobile ? "200px" : "400px",
                  width: isTabletOrMobile ? "220px" : "450px",
                }}
              />
              <Card.Body className="p-0 m-0 text d-flex flex-column align-self-center  ">
                <Card.Title
                  className="poppins-SemiBold portfolioSubTitle text-center"
                  style={
                    isTabletOrMobile
                      ? {
                          fontSize: "16px",
                          marginLeft: "0px",
                          maxWidth: "220px",
                        }
                      : { marginLeft: "0px", maxWidth: "450px" }
                  }
                >
                  Buildings are Adopting Our "Ideal" Sustainable Technology
                  Strategies
                </Card.Title>
              </Card.Body>
            </Card>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center  "
            md="auto"
            xs={6}
          >
            <Card
              style={{
                width: isDesktopOrLaptop ? "20rem" : "12rem",
                border: "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Img
                alt="image"
                src={p6}
                style={{
                  height: isTabletOrMobile ? "100px" : "180px",
                  width: isTabletOrMobile ? "150px" : "285px",
                }}
              />
              <Card.Body className="p-0 m-0 mt-2 px-2 text d-flex flex-column align-self-start ">
                <Card.Text
                  className="poppins-Regular "
                  style={{ fontSize: "14px" }}
                >
                  Working with asset owners and are targeting specific
                  environmental efficiency for property and building
                  enhancements – portfolio-wide capital projects…
                </Card.Text>
                <Card.Link
                  as={Link}
                  className="link poppins-SemiBold portfolioTinyTitle "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  READ MORE
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col
            md="auto"
            className="m-0 p-0 d-flex flex-column align-items-center  "
            xs={6}
          >
            <Card
              style={{
                width: isDesktopOrLaptop ? "20rem" : "12rem",
                border: "none",
              }}
              className="p-2 d-flex flex-column  align-items-center   "
            >
              <Card.Img
                alt="image"
                src={keyexc}
                style={{
                  height: isTabletOrMobile ? "100px" : "180px",
                  width: isTabletOrMobile ? "150px" : "285px",
                }}
              />
              <Card.Body className="p-0 m-0 mt-2 px-2 text d-flex flex-column align-self-start  ">
                <Card.Text
                  className="poppins-Regular "
                  style={{ fontSize: "14px" }}
                >
                  The end-users (Tenants and Guests) of our asset owners are
                  targeting specific buildings & properties that encourage
                  better health and wellness, boost productivity, and lead to
                  greater environmental satisfaction during stays and work
                  hours…
                </Card.Text>
                <Card.Link
                  as={Link}
                  className="link poppins-SemiBold portfolioTinyTitle "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  READ MORE
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>

          <Container className="m-0 p-0 px-5 d-flex flex-column align-items-end ">
            <Link
              to="/"
              className="link poppins-Medium d-flex flex-row align-self-end "
            >
              <u>
                OTHER TECHS
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </u>
            </Link>
          </Container>
        </Row>
        <Row className="m-0 p-0 mt-5 text">
          <h1
            class="portfolioTitle poppins-SemiBold text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            “The Zero to Low Upfront Cost”
          </h1>
          <Container className="m-0 p-0 px-5">
            <h3
              className="poppins-SemiBold portfolioSmallTitle  my-3"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <span className="blue"> Yes - {"     "}</span>
              <span className="orange"> {"  "}you read right.</span> Join our
              new No and Low upfront cost Smart Water Tech Program
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Find out if you are qualified for our national and global savings
              program with no upfront cost. A benefit for your property
              management strategy. With this option, you will have the benefit
              to increase revenue, while focusing on what you do best. Our
              solution may be suited for multiple properties at once.
            </p>
            <Link to="/costsaving" className="link poppins-Medium ">
              <u>
                See How it Works
                <IoArrowForwardCircleOutline
                  size={"30"}
                  color="#333333"
                  className="mx-2"
                />
              </u>
            </Link>
            <h1
              class="homeTitle poppins-SemiBold text-center my-5"
              style={isTabletOrMobile ? { fontSize: "22px" } : {}}
            >
              Protecting your assets, precision engineered for your properties
            </h1>
          </Container>
        </Row>
        <Container
          fluid
          className="m-0 p-0 d-flex flex-column align-items-center my-4"
        >
          <Button variant="dark" className="poppins-Regular">
            Clients Similar to You
          </Button>
        </Container>
      </Container>
    </MasterLayout>
  );
};
export default Home;
