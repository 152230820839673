import React from "react";
import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Home from "./screens/Home";
import Login from "./screens/login/Login";
import WaterTreatment from "./screens/portfolio/WaterTreatment";
import Hospitality from "./screens/caseStudies/Hospitality";
import WaterConsumption from "./screens/portfolio/WaterConsumption";
import WaterMonitoring from "./screens/portfolio/WaterMonitoring.";
import Manufacturing from "./screens/caseStudies/Manufacturing";
import ServiceStation from "./screens/caseStudies/ServiceStation";
import Restaurants from "./screens/caseStudies/Restaurants";
import OfficeBuilding from "./screens/caseStudies/OfficeBuilding";
import Condominiums from "./screens/caseStudies/Condominiums";
import SuperMarket from "./screens/caseStudies/SuperMarket";
import Others from "./screens/caseStudies/Others";
import Consulting from "./screens/workWithus/Consulting";
import EssentailProgram from "./screens/portfolio/EssentailProgram";
import ProductandSupplies from "./screens/portfolio/ProductandSupplies";
import OurProducts from "./screens/workWithus/OurProducts";
import Plumber from "./screens/workWithus/Plumber";
import Webinars from "./screens/webinars/Webinars";
import Events from "./screens/events/Events";
import Careers from "./screens/careers/Careers";
import PropertyOwners from "./screens/workWithus/PropertyOwners";
import Matters from "./screens/workWithus/Matters";
import Location from "./screens/aboutUs/Location";
import DataAnalyst from "./screens/careers/DataAnalyst";
import Draftsman from "./screens/careers/Draftsman";
import PortfolioSales from "./screens/careers/PortfolioSales";
import Gallery from "./screens/aboutUs/Gallery";
import Resources from "./screens/aboutUs/Resources";
import GovServices from "./screens/aboutUs/GovServices";
import BlogDetail1 from "./screens/aboutUs/blogPages/BlogDetail1";
import Success from "./screens/success/Success";
import Error from "./screens/errors/Error";
import BlogDetail2 from "./screens/aboutUs/blogPages/BlogDetail2";
import BlogDetail3 from "./screens/aboutUs/blogPages/BlogDetail3";
import BlogDetail4 from "./screens/aboutUs/blogPages/BlogDetail4";
import BlogDetail5 from "./screens/aboutUs/blogPages/BlogDetail5";
import BlogDetail6 from "./screens/aboutUs/blogPages/BlogDetail6";
import BlogDetail7 from "./screens/aboutUs/blogPages/BlogDetail7";
import GolfCourse from "./screens/caseStudies/GolfCourse";
import History from "./screens/portfolio/History";
import WaterStewardship from "./screens/portfolio/WaterStewardship";
import ProgramSeries1 from "./screens/portfolio/ProgramSeries1";
import ProgramSeries2 from "./screens/portfolio/ProgramSeries2";
import ProgramSeries3 from "./screens/portfolio/ProgramSeries3";
import PrivatePolicy from "./screens/privatePolicy/PrivatePolicy";
import Multifamily from "./screens/caseStudies/Multifamily";
import SmartValveFAQS from "./screens/faqs/SmartValveFAQS";
import SmartWaterMonitorFAQS from "./screens/faqs/SmartWaterMonitorFAQS";
import ScaleBusterFAQS from "./screens/faqs/ScaleBusterFAQS";
import WaterSavingFAQS from "./screens/faqs/WaterSavingFAQS";
import Founder from "./screens/founder/Founder";
import Agent from "./screens/agent/Agent";
import CostSaving from "./screens/costSavingProgram/CostSaving";
import BlogDetail8 from "./screens/aboutUs/blogPages/BlogDetail8";
import BlogDetail9 from "./screens/aboutUs/blogPages/BlogDetail9";
import BlogDetail10 from "./screens/aboutUs/blogPages/BlogDetail10";
import BlogDetail11 from "./screens/aboutUs/blogPages/BlogDetail11";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />

      {/*//////////////////////// Portfolio ////////////////////////*/}
      <Route path="/portfolio/watertreatment" element={<WaterTreatment />} />
      <Route
        path="/portfolio/waterconsumption"
        element={<WaterConsumption />}
      />
      <Route path="/portfolio/watermonitoring" element={<WaterMonitoring />} />
      <Route
        path="/portfolio/essentailprogram"
        element={<EssentailProgram />}
      />
        <Route
        path="/portfolio/essentailprogram/series1"
        element={<ProgramSeries1 />}
      />
      <Route
        path="/portfolio/essentailprogram/series2"
        element={<ProgramSeries2 />}
      />
      <Route
        path="/portfolio/essentailprogram/series3"
        element={<ProgramSeries3 />}
      />
      <Route
        path="/portfolio/product&supplies"
        element={<ProductandSupplies />}
      />
      <Route path="/portfolio/history" element={<History />} />
      <Route
        path="/portfolio/waterstewardship"
        element={<WaterStewardship />}
      />
      <Route
        path="/faqs/smartwatermonitorfaqs"
        element={<SmartWaterMonitorFAQS />}
      />
      <Route path="/faqs/smartvalvefaqs" element={<SmartValveFAQS />} />
      <Route path="/faqs/scalebusterfaqs" element={<ScaleBusterFAQS />} />
      <Route path="/faqs/watersavingfaqs" element={<WaterSavingFAQS />} />

      {/*//////////////////////// CaseStudies ////////////////////////*/}
      <Route path="/caseStudies/hospitality" element={<Hospitality />} />
      <Route path="/caseStudies/manufacturing" element={<Manufacturing />} />
      <Route path="/caseStudies/multifamily" element={<Multifamily />} />
      <Route path="/caseStudies/servicestation" element={<ServiceStation />} />
      <Route path="/caseStudies/restaurants" element={<Restaurants />} />
      <Route path="/caseStudies/officebuilding" element={<OfficeBuilding />} />
      <Route path="/caseStudies/condominiums" element={<Condominiums />} />
      <Route path="/caseStudies/supermarket" element={<SuperMarket />} />
      <Route path="/caseStudies/golfcourse" element={<GolfCourse />} />
      <Route path="/caseStudies/others" element={<Others />} />

      {/*//////////////////////// Workwith us ////////////////////////*/}
      <Route path="/workWithus/consulting" element={<Consulting />} />
      <Route path="/workWithus/ourproducts" element={<OurProducts />} />
      <Route path="/workWithus/plumber" element={<Plumber />} />
      <Route path="/workWithus/propertyowners" element={<PropertyOwners />} />
      <Route path="/workWithus/matters" element={<Matters />} />
      {/*//////////////////////// About us ////////////////////////*/}

      <Route path="/aboutUs/location" element={<Location />} />
      <Route path="/aboutUs/govservices" element={<GovServices />} />
      <Route path="/aboutUs/gallery" element={<Gallery />} />
      <Route path="/aboutUs/resources" element={<Resources />} />
      <Route path="/aboutUs/resources/blogdetail1" element={<BlogDetail1 />} />
      <Route path="/aboutUs/resources/blogdetail2" element={<BlogDetail2 />} />
      <Route path="/aboutUs/resources/blogdetail3" element={<BlogDetail3 />} />
      <Route path="/aboutUs/resources/blogdetail4" element={<BlogDetail4 />} />
      <Route path="/aboutUs/resources/blogdetail5" element={<BlogDetail5 />} />
      <Route path="/aboutUs/resources/blogdetail6" element={<BlogDetail6 />} />
      <Route path="/aboutUs/resources/blogdetail7" element={<BlogDetail7 />} />
      <Route path="/aboutUs/resources/blogdetail8" element={<BlogDetail8 />} />
      <Route path="/aboutUs/resources/blogdetail9" element={<BlogDetail9 />} />
      <Route path="/aboutUs/resources/blogdetail10" element={<BlogDetail10 />} />
      <Route path="/aboutUs/resources/blogdetail11" element={<BlogDetail11 />} />

      {/*//////////////////////// Careers ////////////////////////*/}
      <Route path="/careers" element={<Careers />} />
      <Route path="/careers/dataanalyst" element={<DataAnalyst />} />
      <Route path="/careers/draftsman" element={<Draftsman />} />
      <Route path="/careers/portfoliosales" element={<PortfolioSales />} />

      <Route path="/webinars" element={<Webinars />} />
      <Route path="/events" element={<Events />} />
      <Route path="/privatepolicy" element={<PrivatePolicy />} />
      <Route path="/founder" element={<Founder />} />
      <Route path="/agent" element={<Agent />} />
      <Route path="/costsaving" element={<CostSaving />} />


      <Route path="/success" element={<Success />} />
      <Route path="/error" element={<Error />} />
    </Routes>
  );
}

export default App;
