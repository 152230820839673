import React, { useContext, useEffect, useState } from "react";
import { Form, Modal, Row, Col, Button, Alert } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import AppContext from "../../context/AppContext";
import axios from "axios";
import { json } from "react-router-dom";

const QuickConnect = ({ isVisible, hideModel }) => {
  const { baseUrl } = useContext(AppContext);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [formValidate, setFormValidate] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");
  const [portfolio, setPortfolio] = useState([]);
  const [otherPortfolio, setOtherPortfolio] = useState("");
  const [portfolioList, setPortfolioList] = useState([
    { id: 1, label: "Manufacturing", value: "Manufacturing" },
    {
      id: 2,
      label: "Hospitality & Senior Living",
      value: "Hospitality & Senior Living",
    },
    {
      id: 3,
      label: "Commerical & Agriculture",
      value: "Commerical & Agriculture",
    },
    {
      id: 4,
      label: "Service Stations",
      value: "Service Stations",
    },
    {
      id: 5,
      label: "Others",
      value: "Others",
    },
  ]);
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    var errors = {};
    const exists = portfolio.includes("Others");
    if (exists) {
      if (!otherPortfolio) {
        errors.otherPortfolio = "enter your own portfolio";
      }
    } else {
      setOtherPortfolio("");
    }
    if (portfolio.length < 0) {
      errors.portfolio = "portfolio is required";
    }
    if (!email) {
      errors.email = "email is required";
    }
    setErrorMessages(errors);
    if (Object.keys(errors).length == 0) {
      submitForm();
    } else {
      setFormValidate(true);
    }
  };
  const clearModal=()=>{
    setName('')
    setEmail('')
    setMessage('')
    setNumber('')
    setOtherPortfolio('')
    setPortfolio([])
  }
  const submitForm = () => {
    const formData = new FormData();

    formData.append("name", `${name}`);
    formData.append("phoneNumber", `${number}`);
    formData.append("email", `${email}`);
    formData.append("message", message);
    formData.append("otherPortfolio", `${otherPortfolio}`);
    formData.append("portfolio", JSON.stringify(portfolio));
    
    axios({
      method: "post",
      url: `${baseUrl}/auth/subscriber/signup`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data.save) {
          console.log(response.data.newSubscriber);
          // setTimeout(() => {
            setShowAlert(true);
          // }, 1000);
          setTimeout(() => {
            setShowAlert(false);
            hideModel();
          }, 3000);
        }
        clearModal()
      })
      .catch((error) => {
        console.log(error);
        hideModel();
      });
  };
  // useEffect(() => {
  //   if (portfolio === "Others") {
  //     setIsDisabled(false);
  //   }else{
  //     setIsDisabled(true)
  //   }
  // }, [portfolio]);
  return (
    <Modal
      centered
      show={isVisible}
      onHide={hideModel}
      size={isDesktopOrLaptop ? "md" : "sm"}
    >
      <Modal.Header closeButton>
        <Modal.Title>Choose your portfolio</Modal.Title>
      </Modal.Header>
      {showAlert && (
        <Alert variant="success">Form submitted successfully!</Alert>
      )}
      <Modal.Body>
        <Form
          noValidate
          validated={formValidate}
          onSubmit={handleSubmit}
        >
          <Row xs={12} className="m-0 p-0">
            {errorMessages.portfolio ? (
              <p className="text-danger poppins-Regular">
                {errorMessages.portfolio}
              </p>
            ) : null}

            {portfolioList.map((item) => {
              return item.id < 5 ? (
                <Col className="m-0 p-0" lg={6} md={6} xs={12} key={item.id}>
                  <Form.Check
                    className="poppins-Regular"
                    type={"checkbox"}
                    id={item.id}
                    label={item.label}
                    value={item.value}
                    // checked={portfolio === item.value}
                    style={{ fontSize: "14px" }}
                    onChange={(event) => {
                      console.log(event.target.checked);
                      console.log(event.target.value);
                      // setPortfolio(event.target.value);
                      if (event.target.checked) {
                        setPortfolio((oldPortfoios) => [
                          ...oldPortfoios,
                          event.target.value,
                        ]);
                      }
                      else{
                        setPortfolio(portfolio.filter(item => item !== event.target.value))
                      }
                    }}
                  />
                </Col>
              ) : (
                <Row lg={12} xs={12} className="m-0 p-0  " key={item.id}>
                  <Col xs={3} lg={3} className="m-0 p-0" key={item.id}>
                    <Form.Check
                      className="poppins-Regular mt-2"
                      type={"checkbox"}
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      // checked={portfolio === "Others"}
                      style={{ fontSize: "14px" }}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        console.log(event.target.value);
                        if (event.target.checked) {
                          setPortfolio((oldPortfoios) => [
                            ...oldPortfoios,
                            event.target.value,
                          ]);
                        }
                        else{
                          setPortfolio(portfolio.filter(item => item !== event.target.value))
                        }
                      }}
                    />
                  </Col>
                  <Col xs={"9"} lg={9} className="m-0 p-0">
                    <Form.Control
                      // disabled={isDisabled}
                      className="placeholderStyle "
                      id="inlineFormInput"
                      placeholder=""
                      value={otherPortfolio}
                      onChange={(e) => setOtherPortfolio(e.target.value)}
                      style={{ backgroundColor: "white" }}
                    />
                    {errorMessages.otherPortfolio ? (
                      <p className="text-danger poppins-Regular">
                        {errorMessages.otherPortfolio}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              );
            })}
          </Row>
          <Form.Group
            className="my-3 poppins-Regular"
            controlId="exampleForm.ControlInput1"
          >
            <Form.Control
              className="placeholderStyle"
              type="text"
              placeholder="Enter your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput2"
          >
            <Form.Control
              className="mt-2 mb-2 placeholderStyle"
              type="text"
              placeholder="Enter your Number"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput3"
          >
            <Form.Control
              as="textarea"
              rows={3}
              className="mt-2 mb-2 placeholderStyle"
              type="text"
              placeholder="Enter your Message"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
          </Form.Group>
          <Form.Group
            className="mb-3 poppins-Regular"
            controlId="exampleForm.ControlInput4"
          >
            <Form.Control
              className="placeholderStyle"
              type="email"
              placeholder="Enter your Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            {errorMessages.email ? (
              <p className="text-danger poppins-Regular">
                {errorMessages.email}
              </p>
            ) : null}
          </Form.Group>
          <div className="d-grid mt-2 mb-3">
            <Button
              type="submit"
              variant="primary"
              size="md"
              onClick={() => {
                // handleSubmit();
              }}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default QuickConnect;
