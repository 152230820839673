import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Col, Row, Image, Stack, Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";
import p186 from "../../assets/images/30. Webinars on Demand. 1366x768/186.png";
import p187 from "../../assets/images/30. Webinars on Demand. 1366x768/187.png";
import p188 from "../../assets/images/30. Webinars on Demand. 1366x768/188.png";
import { IoCaretForwardCircle } from "react-icons/io5";
import { Link } from "react-router-dom";
import WebinarCard from "../../components/webinars/WebinarCard";

const Webinars = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={`m-0 ${isDesktopOrLaptop && "pageContainer"} `}
      >
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile ? { fontSize: "22px", marginTop: "4%" } : {}
            }
          >
            WEBINARS ON DEMAND{" "}
          </h1>
        </Row>

        <Row className={` m-0  ${isDesktopOrLaptop ? "px-5" : "px-4"}`}>
          <Col lg={4} md={6} sm={12} xs={12}>
            {/* <iframe
              width={isDesktopOrLaptop ? "375px" : "310px"}
              height="230px"
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
            ></iframe> */}

            <WebinarCard
              title={"The Smart Valves Program for Plumbers"}
              image={p186}
              to={"https://www.youtube.com/embed/tgbNymZ7vqY"}
            />
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            {/* <iframe
              width={isDesktopOrLaptop ? "375px" : "310px"}
              height="230px"
              src="https://www.youtube.com/embed/szvt1vD0Uug"
            ></iframe> */}
            <WebinarCard
              title={
                "The Property Owner/Manager Carbon Credit Strategy with Water Technologies"
              }
              image={p187}
              to={"https://www.youtube.com/embed/szvt1vD0Uug"}
            />
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            {/* <iframe
              width={isDesktopOrLaptop ? "375px" : "310px"}
              height="230px"
              src="https://www.youtube.com/embed/tgbNymZ7vqY"
            ></iframe> */}
            <WebinarCard
              title={"The Smart Valves Program for Plumbers"}
              image={p188}
              to={"https://www.youtube.com/embed/tgbNymZ7vqY"}
            />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Webinars;
