import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import { Container, Row, Col, Button, Image } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import { Link, useNavigate } from "react-router-dom";
import p189 from "../../assets/images/31. Career. 1366x768/189.png";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

const Careers = () => {
  const navigate = useNavigate();
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

 
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile ? { fontSize: "22px", marginTop: "4%",marginLeft:'-12px' } : {}
            }
          >
            Careers
          </h1>
        </Row>
        <Row className="m-0 p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={` d-flex flex-column justify-content-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <div className="d-flex flex-row justify-content-between">
              <h3
                className=" poppins-SemiBold portfolioSmallTitle"
                style={
                  isTabletOrMobile
                    ? { fontSize: "16px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                PART-TIME DATA ANALYST
              </h3>
              <Button
                variant="dark"
                className="poppins-Regular"
                size="sm"
                onClick={() => {
                  navigate("/careers/dataanalyst");
                }}
              >
                Requirements
              </Button>
            </div>
            <div className="d-flex flex-row justify-content-between my-5">
              <h3
                className=" poppins-SemiBold portfolioSmallTitle"
                style={
                  isTabletOrMobile
                    ? { fontSize: "16px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                PART-TIME DRAFTSMAN
              </h3>
              <Button
                variant="dark"
                size="sm"
                className="poppins-Regular"
                onClick={() => {
                  navigate("/careers/draftsman");
                }}
              >
                Requirements
              </Button>
            </div>
            <div className="d-flex flex-row justify-content-between">
              <h3
                className=" poppins-SemiBold portfolioSmallTitle"
                style={
                  isTabletOrMobile
                    ? { fontSize: "16px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                CONTRACTED PORTFOLIO SALES-REP{" "}
              </h3>
              <Button
                variant="dark"
                className="poppins-Regular"
                size="sm"
                onClick={() => {
                  navigate("/careers/portfoliosales");
                }}
              >
                Requirements
              </Button>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex  m-0 ${
              isDesktopOrLaptop ? "py-5 px-0" : "p-0 mt-5 "
            }`}
          >
            <Image fluid src={p189}
              alt="Image" />
           
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default Careers;
