import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p127 from "../../../assets/images/27. Latest In Property Water Management.1366x768/127.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail4 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        {" "}
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Why The Cost To Install The Smart Water Valve Propel NOI, And Is
              Won Back On ROI
            </h3>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              As a property owner or manager, how many times have you look to
              chop your utility fees to start crunching back on your net
              operating income (NOI)? Countless times…
            </p>
            <Image
            className="mb-5"
              src={p127}
              alt="image"
              style={{ height: "15%", width: "95%", objectFit: "fill" }}
            />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              As a property management company, property owner, home association
              committee or new building developed, digging deeper into your
              premises utilities is always a natural place to start to increase
              efficiency and improve your Net operating income (NOI). After all,
              utility fees represent {" "}
              <u className="blue">
                10-30% of a property’s (building’s) operating budget
              </u>
               – inclusive of your water charges. By bringing down water costs
              and by using intelligent water technologies, you can improve
              premises water management expenditure and add value, through
              revenue generation. However, it is difficult to generate revenue
              from a water management investment if you don’t have exposure to
              the right technologies available to save on water and importantly,
              water fees – thereby securing the best return on your investment
              (ROI), especially in cases when you have to give an account to
              someone else.
              <br />
              <br />
              With Water Solutions Technology’s unique water management team
              device and technologies, you can consolidate all your premises
              water need using a single device and service. Make the most of
              analysed historical water fees, detailed billing system audits and
              sustainable technology recommendations (water fee saving valves).
              This will save your Engineering and Pluming team from managing
              difficult pipe replacement, shut down times and establish
              worthwhile saving opportunities. 
              <br />
              <br />
              Let’s review the keyways ROI can be gained and impact your NOI
              using the new services and unique technology available in water
              management right now.
            </p>

            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              How can you improve your asset value ?
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Uncover a solid starting point to begin establishing savings on
              water fees, by historical tracked and analysed data. Capture when
              you have used the largest volume of water that reflects the
              highest fees, and we will implement water management strategies to
              help you cut that cost. We will also communicate with the City or
              water provider for a refund if you were billed incorrectly.
              Lowering water utility costs results in predictable increases in
              NOI, which you can then reinvest in your property to increase
              asset value. A <u className="blue">recent study</u> by the U.S.
              Environmental Protection Agency, shows that 35,000 buildings that
              adopted the benchmarking approach to energy performance save
              energy and reduce costs. The financial benefit of this
              benchmarking showed that a utility savings of 2.4% in a 500,000
              square foot building for three consecutive years would translate
              to a cumulative energy cost savings of approximately $120,000, and
              an increase in asset value of over $1 million. The water
              profitable valve that Water Solutions Technology offers can save
              up to 40% on the water fees each month. A 
              <u className="blue">case study</u> done using the valve to lower
              water fees on a mid to high-rise building translates to savings of
              over $100,000, in a single year, which further propelled the asset
              value to a very lucrative sum. The valve also pays for itself
              under a certain number of months (12-16) – an example of the valve
              system with a very short ROI.
            </p>

            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              Less Maintenance
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Intelligent management system smart water valve will allow you to,
              cost-effectively reduce the problems associated with pipe fixtures
              due to extreme incoming water pressures, and reduce water usage by
              supplying water at the right flow rates through all pipe systems
              This single valve delivering these benefits is also maintenance
              free. Because it is far cheaper to manage (labour costs) and
              maintain (repair costs, $0 ), in the long run outweighs the cost
              to install a properly integrated water management system, with
              confidence.
              <br />
              <br />
              Do you want to Lower your water fees, to improve your net
              operating income (NOI)? Contact the 
              <u className="blue">Water Solutions Technology team</u> to arrange
              a free no-obligation consultation with our Water Engineering and
              Audit Specialists.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail4;
