import React, { useState, useContext } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  Image,
  InputGroup,
  Row,
  Stack,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/waterTech.css";
import "../../assets/css/fontStyles.css";
import logo from "../../assets/images/color-logo.png";
import WSLogo from "../../assets/images/Logo_watersolutions_technology.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import axios from "axios";
import AppContext from "../../context/AppContext";
import { useEffect } from "react";

const Footer = () => {
  const { baseUrl } = useContext(AppContext);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [formValidate, setFormValidate] = useState(false);
  const [email, setEmail] = useState("");
  const [portfolio, setPortfolio] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [otherPortfolio, setOtherPortfolio] = useState("");
  const [portfolioList, setPortfolioList] = useState([
    { id: 1, label: "Manufacturing", value: "Manufacturing" },
    {
      id: 2,
      label: "Hospitality & Senior Living",
      value: "Hospitality & Senior Living",
    },
    {
      id: 3,
      label: "Commerical & Agriculture",
      value: "Commerical & Agriculture",
    },
    {
      id: 4,
      label: "Service Stations",
      value: "Service Stations",
    },
    {
      id: 5,
      label: "Others",
      value: "Others",
    },
  ]);
  const [errorMessages, setErrorMessages] = useState({});
  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    var errors = {};
    const exists = portfolio.includes("Others");
    if (exists) {
      if (!otherPortfolio) {
        errors.otherPortfolio = "enter your own portfolio";
      }
    }
    if (portfolio.length < 0) {
      errors.portfolio = "portfolio is required";
    }
    if (!email) {
      errors.email = "email is required";
    }
    setErrorMessages(errors);
    if (Object.keys(errors).length == 0) {
      submitForm();
    } else {
      setFormValidate(true);
    }
  };
  const submitForm = () => {
    const formData = new FormData();
    formData.append("otherPortfolio", `${otherPortfolio}`);
    formData.append("portfolio", JSON.stringify(portfolio));
    formData.append("email", `${email}`);
    axios({
      method: "post",
      url: `${baseUrl}/auth/subscriber/signup`,
      headers: { "Content-Type": "multipart/form-data" },
      data: formData,
    })
      .then((response) => {
        console.log(response);
        console.log(response.data);
        if (response.data.save) {
          console.log(response.data.newSubscriber);
          setEmail("");
          setPortfolio([]);
          setOtherPortfolio("");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // useEffect(() => {
  //   if (portfolio === "Others") {
  //     setIsDisabled(false);
  //   } else {
  //     setIsDisabled(true);
  //   }
  // }, [portfolio]);
  return (
    <Container fluid className="pt-0 p-5 m-0">
      <br />
      <br />
      <br />
      <br />
      <hr />
      <Row className="mt-5">
        <Col xs={2} style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logo}
            style={{
              maxHeight: isTabletOrMobile ? "20px" : "40px",
              maxWidth: isTabletOrMobile ? "30px" : "50px",
              objectFit: "cover",
            }}
          />
        </Col>
        <Col xs={2} style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="poppins-SemiBold black"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "16px",
            }}
          >
            About Us
          </p>
          <Link
            onClick={scrollToTop}
            to="/aboutUs/resources"
            className="poppins-Light link "
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Resources & Blogs</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/aboutUs/govservices"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Servicing</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/aboutUs/location"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>How it Started</p>
          </Link>
        </Col>
        <Col xs={2} style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="poppins-SemiBold black"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "16px",
            }}
          >
            Portfolio
          </p>
          <Link
            onClick={scrollToTop}
            to="/portfolio/watertreatment"
            className="poppins-Light link "
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Water Treatment</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/portfolio/waterconsumption"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Water Consumption</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/portfolio/watermonitoring"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Water Monitoring</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/portfolio/essentailprogram"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Smart Approach Programme</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/portfolio/product&supplies"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Products & Essential Supplies</p>
          </Link>
        </Col>
        <Col xs={2} style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="poppins-SemiBold black"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "16px",
            }}
          >
            Case Studies
          </p>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/hospitality"
            className="poppins-Light link "
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Hospitality</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/manufacturing"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Manufacturing</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/servicestation"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Service Stations</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/restaurants"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Restaurants</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/officebuilding"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Office Buildings</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/condominiums"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Condominiums</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/caseStudies/supermarket"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Supermarkets</p>
          </Link>
        </Col>
        <Col xs={2} style={{ display: "flex", flexDirection: "column" }}>
          <p
            className="poppins-SemiBold black"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "16px",
            }}
          >
            Work With Us
          </p>
          <Link
            onClick={scrollToTop}
            to="/workWithus/consulting"
            className="poppins-Light link "
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Consulting</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/workWithus/ourproducts"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Our Products</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/workWithus/propertyowners"
            className="poppins-Light link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Property Management Groups</p>
          </Link>
        </Col>
        <Col xs={2} style={{ display: "flex", flexDirection: "column" }}>
          <Link
            onClick={scrollToTop}
            to="/webinars"
            className="poppins-SemiBold link "
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Webinars</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/events"
            className="poppins-SemiBold link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Events</p>
          </Link>
          <Link
            onClick={scrollToTop}
            to="/login"
            className="poppins-SemiBold link"
            style={{
              fontSize: isTabletOrMobile ? "6px" : "14px",
            }}
          >
            <p>Login</p>
          </Link>
        </Col>
      </Row>
      <hr className="gray mb-5 mt-5" />
      <Row>
        <Col
          className="m-0 p-0 tinyText"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img
            src={WSLogo}
            style={{
              maxHeight: isTabletOrMobile ? "70px" : "100px",
              maxWidth: isTabletOrMobile ? "120px" : "200px",
              objectFit: "cover",
            }}
          />
          <p
            className="poppins-SemiBold px-4 text-dark"
            style={{
              fontSize: isTabletOrMobile ? "8px" : "12px",
            }}
          >
            Main Office
            <span className="poppins-Regular">
              <br />
              Broward Country
              <br />
              Building 9,
              <br />
              Cocoplum Circle,
              <br />
              Coconut Creek , Florida ,33063.
              <br />
            </span>
            Tel:954,508,3877
          </p>

          <br />

          <h4
            className="poppins-SemiBold px-4 text-dark"
            style={{
              fontSize: isTabletOrMobile ? "8px" : "12px",
            }}
          >
            All Rights Reserved. Water Solutions Technology
          </h4>
        </Col>
        <Col className="p-0 m-0 d-flex align-items-end justify-content-center">
          <Link
            to={"/privatepolicy"}
            className="link poppins-SemiBold text-center mb-3"
            style={{ fontSize: isTabletOrMobile && "8px" }}
          >
            <u>Private Policy</u>
          </Link>
        </Col>
        <Col className="m-0 p-0 ">
          <Form  noValidate
        validated={formValidate}
        onSubmit={handleSubmit}>
            <Form.Text
              className="poppins-SemiBold mediumBlack"
              style={{
                fontSize: isTabletOrMobile ? "8px" : "10px",
              }}
            >
              KEEP IN TOUCH
            </Form.Text>
            <br />
            <Form.Text
              className="poppins-Regular mediumBlack"
              style={{
                fontSize: isTabletOrMobile ? "8px" : "10px",
              }}
            >
              Choose your portfolio
            </Form.Text>
            <Row xs={12} className="m-0 p-0">
              {portfolioList.map((item) => {
                return item.id < 5 ? (
                  <Col className="m-0 p-0" lg={6} md={6} xs={12} key={item.id}>
                    <Form.Check
                      className="poppins-Regular"
                      type={"checkbox"}
                      id={item.id}
                      label={item.label}
                      value={item.value}
                      // checked={portfolio === item.value}
                      style={{ fontSize: "14px" }}
                      onChange={(event) => {
                        console.log(event.target.checked);
                        console.log(event.target.value);
                        setPortfolio((oldPortfoios) => [
                          ...oldPortfoios,
                          event.target.value,
                        ]);
                      }}
                    />
                  </Col>
                ) : (
                  <Row lg={12} xs={12} className="m-0 p-0  " key={item.id}>
                    <Col xs={3} lg={3} className="m-0 p-0" key={item.id}>
                      <Form.Check
                        className="poppins-Regular mt-2"
                        type={"checkbox"}
                        id={item.id}
                        label={item.label}
                        value={item.value}
                        // checked={portfolio === "Others"}
                        style={{ fontSize: "14px" }}
                        onChange={(event) => {
                          console.log(event.target.checked);
                          console.log(event.target.value);
                          setPortfolio((oldPortfoios) => [
                            ...oldPortfoios,
                            event.target.value,
                          ]);
                        }}
                      />
                    </Col>
                    <Col xs={"8"} lg={7} className="m-0 p-0">
                      <Form.Control
                        type="text"
                        size="sm"
                        // disabled={isDisabled}
                        className="placeholderStyle "
                        id="inlineFormInput"
                        placeholder=""
                        value={otherPortfolio}
                        onChange={(e) => setOtherPortfolio(e.target.value)}
                        style={{ backgroundColor: "white", borderRadius: "0" }}
                      />
                    {errorMessages.otherPortfolio ? (
                    <p className="text-danger poppins-Regular ">
                      {errorMessages.otherPortfolio}
                    </p>
                  ) : null}
                    </Col>
                  </Row>
                );
              })}
            </Row>
            {errorMessages.portfolio ? (
              <p className="text-danger poppins-Regular">
                {errorMessages.portfolio}
              </p>
            ) : null}

            {/* <Stack direction="horizontal" gap={4} className="mt-3">
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={`default-1`}
                label={`Manufacturing`}
                style={{ fontSize: isTabletOrMobile ? "12px" : "14px" }}
              />
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={`default-2`}
                label={`Hospitalty & Senior Living`}
                style={{ fontSize: isTabletOrMobile ? "12px" : "14px" }}
              />
            </Stack>
            <Stack direction="horizontal" gap={3}>
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={`default-3`}
                label={`Service Stations`}
                style={{ fontSize: isTabletOrMobile ? "12px" : "14px" }}
              />
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={`default-4`}
                label={`Commercial & Agriculture`}
                style={{ fontSize: isTabletOrMobile ? "12px" : "14px" }}
              />
            </Stack>
            <Stack direction="horizontal" gap={5}>
              <Form.Check
                className="poppins-Regular"
                type={"checkbox"}
                id={`default-5`}
                label={`Other`}
                style={{ fontSize: isTabletOrMobile ? "12px" : "14px" }}
              />
              <Form.Control
                size="sm"
                className="placeholderStyle poppins-Regular "
                type="default"
                placeholder=""
                style={{ borderRadius: "0", width: "60%" }}
              />
            </Stack> */}
            <InputGroup size="sm" className=" my-3" style={{ width: "70%" }}>
              <Form.Group
                className="poppins-Regular"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Control
                size="sm"
                aria-label="Email Address"
                className="placeholderStyle poppins-Regular"
                type="email"
                placeholder="Email Address"
                style={{ borderRadius: "0" }}
                required
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />
              </Form.Group>
              

              <Button
              type="submit"
                size="sm"
                variant="dark"
                className="poppins-Regular"
                style={{ borderRadius: "0" }}
                onClick={() => {
                  // handleSubmit();
                }}
              >
                Submit
              </Button>
            </InputGroup>
            {errorMessages.email ? (
              <p
                className="text-danger poppins-Regular"
                style={{ fontSize: isDesktopOrLaptop ? "12px" : "10px" }}
              >
                {errorMessages.email}
              </p>
            ) : null}
          </Form>
          <br />
          <p
            className="poppins-Regular mediumBlack "
            style={{
              fontSize: isTabletOrMobile ? "5px" : "8px",
              textAlign: "justify",
            }}
          >
            BY SUBMITTING THIS FORM, YOU ARE CONSENTING TO RECEIVE MARKETING
            EMAILS FROM: WATER SOLUTIONS TECHNOLOGY, BROWARD COUNTY FLORIDA,
            33063 US, WATERSOLUTECH.COM AND THESMARTVALVES.COM.YOU CAN REVOKE
            YOUR CONSENT TO RECEIVE EMAILS ANY TIME BY USING THE UNSUBSCRIBE
            LINK, FOUND AT THE BOTTOM OF EVERY EMAIL.
          </p>
        </Col>
      </Row>
      <br />
      <br />
    </Container>
  );
};

export default Footer;
