import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import {
  Badge,
  Button,
  Col,
  Container,
  Image,
  Row,
  Stack,
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/waterTech.css";

import p102 from "../../assets/images/25. SUSTAIBABILITY MATTERS 1366x768/102.png";
import p103 from "../../assets/images/25. SUSTAIBABILITY MATTERS 1366x768/103.png";
import environmental from "../../assets/images/25. SUSTAIBABILITY MATTERS 1366x768/environmental.png";
import governance from "../../assets/images/25. SUSTAIBABILITY MATTERS 1366x768/governance.png";
import WorkwithusNavbar from "../../components/navbars/WorkwithusNavbar";
import { useState } from "react";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

const Matters = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const [show, setShow] = useState(false);

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            SPECIALITY GROUPS
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            SUSTAIBABILITY MATTERS !
          </h3>
        </Row>
        <Row
          className={`m-0 p-0 mt-4 text ${isDesktopOrLaptop && "px-5"}`}
          style={{ paddingLeft: "6%", paddingRight: "6%" }}
        >
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Sustainability teams can now boost their companies corporate
            sustainability goals through our{" "}
            <span className="poppins-SemiBold blue">tailored solutions</span>.
            We provide solutions related to your sustainability and ESG goals -
            through water & energy-related water consumption
          </p>
          <Col className="m-0 p-0  " lg={6} xs={12}>
            <Image fluid src={p102} className=" mt-5" alt="image" />
          </Col>
          <Col className="m-0 p-0   " lg={6} xs={12}>
            <Stack gap={2}>
              <Image
                src={environmental}
                alt="image"
                style={{
                  height: "220px",
                  width: "300px",
                  objectFit: "contain",
                }}
              />
              <Image
                src={governance}
                alt="image"
                style={{
                  height: "220px",
                  width: "300px",
                  objectFit: "contain",
                }}
              />
            </Stack>
          </Col>
        </Row>
        <Row className={`m-0 p-0 mt-5 ${isDesktopOrLaptop && "px-5"}  text`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            In Q4 of 2020, ESG and sustainability interests by CFOs in S&P 500
            companies, grew by over 300%, reported by WSJ.
          </h3>
          <p
            className="poppins-Regular my-3"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Chief financial officers are embracing sustainability measures as a
            way to attract new investors, lower their companies’ borrowing costs
            and cut operating expenses{" "}
            <span className="poppins-SemiBold blue">(WSJ, 2020)</span>​
          </p>
        </Row>

        <Row className={`m-0 p-0 ${isDesktopOrLaptop && "px-5  "}`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle text-center my-5"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Water and Energy related to water use, ESG and Sustainability
            Solutions
          </h3>
          <Col lg={3} xs={12} className="m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "19%",
                  paddingRight: "19%",
                  // paddingLeft: "15%",
                  // paddingRight: "15%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                REPORTS-CUSTOMISED
              </Badge>
            </h3>
          </Col>
          <Col lg={3} xs={12} className="  m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  // paddingLeft: "13%",
                  // paddingRight: "13%",
                  paddingLeft: "15%",
                  paddingRight: "15%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                DASHBOARD-CUSTOMISED
              </Badge>
            </h3>
          </Col>
          <Col lg={3} xs={12} className="m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: isDesktopOrLaptop ? "22%" : "21%",
                  paddingRight: isDesktopOrLaptop ? "22%" : "21%",
                  // paddingLeft: "15%",
                  // paddingRight: "15%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                TAILORED-STRATEGY
              </Badge>
            </h3>
          </Col>
          <Col lg={3} xs={12} className="m-0 p-0 text-center ">
            <h3
              className="poppins-Regular portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              <Badge
                bg="gray"
                text="dark"
                className=" py-4"
                style={{
                  paddingLeft: "18%",
                  paddingRight: "18%",
                  borderRadius: "0%",
                  backgroundColor: "#E6E6E6",
                }}
              >
                LEED RE-CERTIFICATION
              </Badge>
            </h3>
          </Col>
        </Row>

        <Row className={`m-0 p-0 mt-5  text ${isDesktopOrLaptop && "px-5"}`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Operators remain in real-time dialogue with buildings
          </h3>
          <p
            className="poppins-Regular my-3 "
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Internet of things (IoT), not only have the potential to locate
            leaks and excessive energy use, but also let operators remain in
            real-time dialogue with buildings to ensure that water and energy
            are being used efficiently. Lauren Hodges, director of
            communications at Energy Star for Commercial Buildings & Industrial
            Plants at the <u> US Environmental Protection Agency</u>, says “that these
            efficiencies continues to be the easiest and most cost-effective way
            to reduce a building’s utility bills and greenhouse gas emissions,
            and buildings can reinvest their savings into larger capital
            upgrades.”​
          </p>
          <Container fluid className="m-0 p-5">
          <Image  src={p103} fluid className=" mb-5" alt="image" />
          </Container>
        </Row>
        <div className="d-flex justify-content-end mx-5">
          <Button
            variant="dark"
            size="sm"
            className="mx-5 "
            onClick={() => {
              setShow(true);
            }}
          >
            Submit Quick Contact Form
          </Button>
        </div>
        <Row className={`m-0 p-0  text ${isDesktopOrLaptop && "px-5"}`}>
          <h3
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Did you know ?
          </h3>
          <p
            className="poppins-Regular my-3 "
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Going green can increase the property value of an old office
            building by up to $800K, according to the{" "}
            <u>Urban Land Institute</u>. Still there are reasons to make
            investment in sustainable technologies.
          </p>
        </Row>
      </Container>
      <QuickConnect
        isVisible={show}
        hideModel={() => {
          setShow(false);
        }}
      />
    </MasterLayout>
  );
};

export default Matters;
