import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Figure,
  Row
} from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link, useNavigate } from "react-router-dom";
import "../../assets/css/waterTech.css";
import p104 from "../../assets/images/26. About Us. 1366x768/104.png";
import p105 from "../../assets/images/26. About Us. 1366x768/105.png";
import logo from "../../assets/images/26. About Us. 1366x768/logo.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout.js";

const Location = () => {
  const navigate=useNavigate()
  const [show,setShow]=useState(false)
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])

  return (
    <MasterLayout>
      <Container
        fluid
        className="m-0 p-5 mt-5"
        style={{
          // width: window.innerWidth,
          maxWidth: "100%",
          height: window.innerHeight / 1.5,
          // height: '80wh',
          // maxWidth:'100vw',
          backgroundImage: `url(${p104})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <h1
          class="portfolioTitle poppins-SemiBold"
          style={
            isTabletOrMobile
              ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
              : {}
          }
        >
          About Us
        </h1>
        <h3
          className="poppins-SemiBold portfolioSubTitle text-center"
          style={
            isTabletOrMobile
              ? { fontSize: "16px", marginLeft: "0px" }
              : { marginLeft: "0px" }
          }
        >
          Proven Water Technologies, Serving Solutions Globally
        </h3>
        <Row className={`m-0  ${isDesktopOrLaptop ? " p-5" : "pt-5 p-0"}`}>
          <Col lg={8} md={8} xs={6} className="m-0 p-0 pt-5 smallText ">
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              Company Offerings : Water Solutions Technology | Flow Dynamics
              <br /> Product Flagship :The Smart Valve
              <br /> Websites: www.The SmartValveS.com | www.watersolutech.com
              <br /> HQ: Florida, United States
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={6}
            className={`m-0 p-0  smallText ${isDesktopOrLaptop && "px-5"}`}
          >
            <p
              className="poppins-Regular mb-3"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              We are Seeking:
              <br />
              <br />
              Plumbers & Plumber Groups
              <br /> Property Management Companies
              <br /> Sustainable Groups
              <br /> Established Cost Savings Companies
              <br /> Consultancy Groups
            </p>
            <Link className="link poppins-Regular blue" to={'/agent'}>
              Easy account setup
            </Link>
          </Col>
        </Row>
      </Container>
      <Container fluid className="m-0 pageContainer">
        <Row className="m-0  p-0 ">
          <h3
            className="poppins-SemiBold portfolioSubTitle blue"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            How It Began
          </h3>
          <h3
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Setting the Stage for an Expected Outcome
          </h3>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Emerging from a commitment to assist organizations in
              strengthening their financial foundations and advancing
              sustainability, Water Solutions Technology is dedicated to
              optimizing performance and nurturing circumstances where all
              parties flourish. We make a profound impact by methodically
              delivering inventive solutions through trusted partnerships.
              <br />
              <br />
              We dedicate our time and expertise, at no cost to you, to gain a
              comprehensive understanding of your organization. As a result, we
              provide a range of solutions to help you achieve your objectives
              and overcome your challenges. Each interaction with us is an
              opportunity for collaboration, advice, strategic planning, and
              effective problem-solving for our clients.
            </p>
            <Button variant="dark" size="sm" className="align-self-start mt-5" onClick={()=>{
              setShow(true)
            }}>
              Discover our Services
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-3" : "p-4"
            }`}
          >
            <Figure>
              <Figure.Image
              fluid
                //  height={isDesktopOrLaptop ? "400px" : "160px"}
                //  width={isDesktopOrLaptop ? "550px" : "300px"}
                alt="171x180"
                src={p105}
              />
              <Figure.Caption className="poppins-Regular blue my-3">
                Meet some of our team: we are a mixed environment, with
                scientists, engineers, project managers, designers, financial
                gurus, data scientists and PhD’s.
              </Figure.Caption>
            </Figure>
            <Button variant="dark" size="sm" className="align-self-start " onClick={()=>{
              navigate('/founder')
            }}>
              The Founder Script
            </Button>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h3
            className="poppins-Medium portfolioSubTitle text-center my-5"
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Our Core Values
          </h3>
          <Col
            md={4}
            lg={4}
            xs={6}
            className="d-flex flex-column align-items-center smallText"
          >
            <img
              src={logo}
              style={{
                maxHeight: isTabletOrMobile ? "30%" : "30%",
                maxWidth: "50%",
              }}
            />
            <h3
              className="poppins-SemiBold portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              BELIVE IN YAHUA
            </h3>
            <p
              className="poppins-Regular text-center "
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "60%" }
                  : { maxWidth: "60%" }
              }
            >
              We believe in the one true Almighty, that has designed you in his
              purpose - and if you keep in his ways you will never fail.
            </p>
          </Col>
          <Col
            md={4}
            lg={4}
            xs={6}
            className="d-flex flex-column align-items-center smallText"
          >
            <img
              src={logo}
              style={{
                maxHeight: isTabletOrMobile ? "30%" : "30%",
                maxWidth: "50%",
              }}
            />
            <h3
              className="poppins-SemiBold portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              REMEMBER OTHERS
            </h3>
            <p
              className="poppins-Regular text-center "
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "60%" }
                  : { maxWidth: "60%" }
              }
            >
              We can only truly thrive, when we treat others kindly
            </p>
          </Col>
          <Col
            md={4}
            lg={4}
            xs={6}
            className="d-flex flex-column align-items-center smallText"
          >
            <img
              src={logo}
              style={{
                maxHeight: isTabletOrMobile ? "30%" : "30%",
                maxWidth: "50%",
              }}
            />
            <h3
              className="poppins-SemiBold portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              KEEP GROWING
            </h3>
            <p
              className="poppins-Regular text-center "
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "60%" }
                  : { maxWidth: "60%" }
              }
            >
              Be brave and courageous, make mistakes, and keep learning
            </p>
          </Col>
          <Col
            md={4}
            lg={4}
            xs={6}
            className="d-flex flex-column align-items-center smallText"
          >
            <img
              src={logo}
              style={{
                maxHeight: isTabletOrMobile ? "30%" : "30%",
                maxWidth: "50%",
              }}
            />
            <h3
              className="poppins-SemiBold portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              SPEAK THE TRUTH
            </h3>
            <p
              className="poppins-Regular text-center "
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "60%" }
                  : { maxWidth: "60%" }
              }
            >
              Cardor, honesty and openness, help us to continuously improve
              (grow).
            </p>
          </Col>
          <Col
            md={4}
            lg={4}
            xs={6}
            className="d-flex flex-column align-items-center smallText"
          >
            <img
              src={logo}
              style={{
                maxHeight: isTabletOrMobile ? "30%" : "30%",
                maxWidth: "50%",
              }}
            />
            <h3
              className="poppins-SemiBold portfolioSmallTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              BE A TEAM PLAYER
            </h3>
            <p
              className="poppins-Regular text-center "
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "60%" }
                  : { maxWidth: "60%" }
              }
            >
              Working as a team has so much benefits in learning and growing
              together
            </p>
          </Col>
        </Row>
      </Container>
      <QuickConnect isVisible={show} hideModel={()=>{
        setShow(false)
      }} />
    </MasterLayout>
  );
};

export default Location;
