import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { IoArrowForwardCircleOutline } from "react-icons/io5";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/waterTech.css";
import p12 from "../../assets/images/2. Water Meter Consumption Accuracy. 1366x768/12.png";
import p13 from "../../assets/images/2. Water Meter Consumption Accuracy. 1366x768/13.png";
import p14 from "../../assets/images/2. Water Meter Consumption Accuracy. 1366x768/14.png";
import p15 from "../../assets/images/2. Water Meter Consumption Accuracy. 1366x768/15.png";
import { scrollToTop } from "../../helper/HelperFunctions";
import MasterLayout from "../../layouts/MasterLayout";

function WaterConsumption(props) {
  const [showSecondImage, setShowSecondImage] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  
  useEffect(() => {
   scrollToTop()
   const handleScroll = () => {
    const isScrolled = window.scrollY > 1000;
    console.log(isScrolled);
    setShowSecondImage(isScrolled);
  };
  window.addEventListener("scroll", handleScroll);
  return () => {
    window.removeEventListener("scroll", handleScroll);
  };
    
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0 ">
          <h1
            class="portfolioTitle poppins-SemiBold"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "-12px" }
                : {}
            }
          >
            Water Meter Consumption Accuracy
          </h1>
        </Row>
        <Row className="m-0  p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "-75px" }
              }
            >
              The Problem with “Water Flow Meters”
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water meters are unable to differentiate between water and air;
              they are essentially fluid meters, engineered to measure both
              liquids and gases as they pass through the register – propeller
              system (including the mechanical flow sensor). This is why you may
              be paying up to 35% or more in water and sewer fees than you
              should. Regardless of your city, water utility companies often
              overcharge due to inaccurate consumption management, putting the
              onus of usage control on the user.
              <br />
              <br />
              Learn why ultrasonic and other meters are also affected by this
              pressurized air phenomena.{" "}
              <Link
                className="link poppins-SemiBold"
                to={"/portfolio/essentailprogram/series1"}
              >
                READ MORE
              </Link>
            </p>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image fluid src={p12} alt="First Image" />
            <h3
              className="poppins-SemiBold portfolioTinyTitle my-2"
              style={
                isTabletOrMobile
                  ? { fontSize: "12px", maxWidth: "300px" }
                  : { maxWidth: "500px" }
              }
            >
              Water meters are a utility's cash register, the solution begins
              with measurement integrity at each water meter
            </h3>
          </Col>
        </Row>
        <Row className="m-0 mt-3 p-0 ">
          <h3
            className="poppins-SemiBold portfolioSubTitle"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "0px" } : {}
            }
          >
            The Solutions to Meter Inaccuracy
          </h3>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column justify-content-center align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image fluid src={p13} alt="First Image" />
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start  m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular "
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Meter accuracy is the most influential variable on your water
              billing statements. However, using Water Solutions Technology
              <sup> TM</sup>
              patented{" "}
              <span className="blue">
                {" "}
                Venturi designed smart technology (The Smart Flow Management
                Device <sup>TM</sup> ){" "}
              </span>{" "}
              to modulate pressure differential, upstream and downstream of your
              water meter, its accuracy is consistent every time.
              <br />
              <br />
              The technology is installed on the user's side of the water supply
              line, downstream from the water meter. Using our patented system,
              it ensures a consistent flow of water through the water flow
              meter. This safeguards the user's water system.. Our smart
              technology offers the solution for that control.{" "}
              <Link
                className="link poppins-SemiBold"
                to={"/portfolio/essentailprogram/series1"}
              >
                READ MORE
              </Link>
            </p>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle align-self-start "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              The Results
            </h3>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px", }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Reduction in water fees upwards of 30%
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
              Reduction in sewer fees as a function of water fees
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
              Reduction in water consumption (3-6%)
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",}}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
              Safeguard from sudden fluctuations
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
              Safeguard from sudden unregulated flow
              </p>
            </div>
            <div className="m-0 p-0 d-flex flex-row ">
              <div>
                <GiCheckMark
                  color="orange"
                  size={"18px"}
                  style={{ marginRight: "15px",  }}
                />
              </div>
              <p
                className="poppins-Regular "
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
              Safeguard from excessive oxygen
              </p>
            </div>
            <Link
              className="link text poppins-SemiBold mx-4"
              to={"/portfolio/essentailprogram/series1"}
            >
              READ MORE
            </Link>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className=" d-flex flex-column justify-content-center align-items-center "
          >
            <Image fluid src={showSecondImage ? p14 : p15} alt="Image" />
            <h3
              className="poppins-SemiBold portfolioTinyTitle my-2"
              style={{ fontSize: isDesktopOrLaptop ? "14px" : "12px" }}
            >
              Most customers experience 15-25% in monthly savings
            </h3>
          </Col>
          <Container
            fluid
            className={`my-5 d-flex flex-row justify-content-between ${
              isDesktopOrLaptop && "px-5"
            } `}
          >
            <Link to={"/portfolio/history"} className="link">
              <h4
                className=" portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                How Does This Technology Compare
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
            <Link to={"/portfolio/history"} className="link">
              <h4
                className=" portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                History of the Technology
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
            <Link to={"/portfolio/essentailprogram/series1"} className="link">
              <h4
                className="  portfolioTinyTitle poppins-SemiBold"
                style={isTabletOrMobile ? { fontSize: "12px" } : {}}
              >
                READ MORE
                <IoArrowForwardCircleOutline size={"30"} color="#333333" />
              </h4>
            </Link>
          </Container>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default WaterConsumption;
