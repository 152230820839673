import React from "react";
import MasterLayout from "../../layouts/MasterLayout";
import {
  Container,
  Row,
  Col,
  Image,
  Table,
  Button,
  Badge,
} from "react-bootstrap";
import "../../assets/css/waterTech.css";
import { Link } from "react-router-dom";
import P11 from "../../assets/images/P11.png";
import P29 from "../../assets/images/P29.png";
import P17 from "../../assets/images/P17.png";
import { useMediaQuery } from "react-responsive";
import PortfolioNavbar from "../../components/navbars/PortfolioNavbar";
import { GiCheckMark } from "react-icons/gi";
import { useEffect } from "react";
import { scrollToTop } from "../../helper/HelperFunctions";

function CostSaving(props) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="m-0 pageContainer">
        <Row className=" m-0 p-0">
          <h1
            class="portfolioTitle poppins-SemiBold text-center"
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            The Smart Property Cost Savings Program
          </h1>
          <h3
            className="poppins-SemiBold portfolioSubTitle  my-3"
            style={
              isTabletOrMobile ? { fontSize: "18px", marginLeft: "-12px" } : {}
            }
          >
            The Smart Flow Management | The Smart Water Metrics | The Smart
            Water Treatment
          </h3>
        </Row>
        <Row className="m-0  p-0 mt-5 ">
          <h3
            className="poppins-SemiBold portfolioSmallTitle orange"
            style={
              isTabletOrMobile ? { fontSize: "14px", marginLeft: "-10px" } : {}
            }
          >
            Zero and Low Upfront Cost: The Flow Management Approach{" "}
            <sup>TM</sup>
          </h3>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <div className="d-flex flex-row ">
              <h4>
                <Badge bg="dark" className="circleBadge m-0 p-0  mt-3 mx-3 ">
                  1
                </Badge>
              </h4>
              <div>
                <h3
                  className="poppins-SemiBold portfolioSubTitle blue "
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : { marginLeft: "0px" }
                  }
                >
                  How it works
                </h3>
                <p
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  We anticipate savings exceeding 15% and will apply a service
                  and installation fee, which may involve additional plumbing
                  based on a site inspection and installation requirements.
                  Afterward, a minimal rental fee will be incurred. You will not
                  be charged for the Smart Valve <sup>TM</sup> technology itself.
                </p>
              </div>
            </div>
            <div className="d-flex flex-row ">
              <h4>
                <Badge bg="dark" className="circleBadge m-0 p-0  mt-3 mx-3 ">
                  2
                </Badge>
              </h4>
              <div>
                <h3
                  className="poppins-SemiBold portfolioSubTitle blue"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : { marginLeft: "0px" }
                  }
                >
                  An example:
                </h3>
                <p
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  As an example, let's consider a scenario where your monthly
                  water bill totals $3,000 (before taxes and fees) before
                  installing the Flow Management Technology™. With the
                  introduction of this technology, we expect a minimum savings
                  of 15%. As a result, your adjusted water bill will be $2,550
                  or possibly even lower. Regarding the service and rental fees,
                  they will be 7% of the new water bill ($1,700) or 5.9% of the
                  original bill. In total, your net savings will be $181 per
                  month or $2,172 annually, accounting for any potential
                  increases in water prices.
                </p>
              </div>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <div className="d-flex flex-row ">
              <h4>
                <Badge bg="dark" className="circleBadge m-0 p-0  mt-3 mx-3 ">
                  3
                </Badge>
              </h4>
              <div>
                <h3
                  className="poppins-SemiBold portfolioSubTitle blue"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : { marginLeft: "0px" }
                  }
                >
                  What we do once you sign up
                </h3>
                <p
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  We initiate the process with a deep-dive evaluation to
                  identify ideal candidates. This allows you to focus on what
                  you do best while we collaborate with you to achieve your
                  established goals or create new, measurable, and impactful
                  ones.
                  <Link className="link poppins-SemiBold"> Read More</Link>
                </p>
              </div>
            </div>
            <div className="d-flex flex-row">
              <h4>
                <Badge bg="dark" className="circleBadge m-0 p-0  mt-3 mx-3 ">
                  4
                </Badge>
              </h4>
              <div>
                <h3
                  className="poppins-SemiBold portfolioSubTitle blue"
                  style={
                    isTabletOrMobile
                      ? { fontSize: "16px", marginLeft: "0px" }
                      : { marginLeft: "0px" }
                  }
                >
                  If Saving don’t Meet or Exceed 15%
                </h3>
                <p
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  If you fail to meet or exceed our minimum guaranteed savings
                  of 15%, we will, upon your request, remove the Flow Management
                  Technology ™ at our expense, without imposing any penalties or
                  fees. Alternatively, we can adjust your payment so that it
                  never exceeds 50% of your savings. This option allows you to
                  decide based on the return on investment, which may make
                  economic sense regardless of the level of savings.
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className={`m-0 p-0 ${isDesktopOrLaptop && "px-5"}`}>
          <Table
            responsive
            bordered
            hover
            variant="light"
            size={isDesktopOrLaptop ? "lg" : "sm"}
            className="smallText"
          >
            <thead>
              <tr className="table-dark ">
                <th className="poppins-SemiBold text-center ">Old Water Fees</th>
                <th className="poppins-SemiBold text-center">New Water Fees</th>
                <th className="poppins-SemiBold text-center" style={{width:'30%',}}>
                  Monthly Payment Value (percentage)*
                </th>
                <th className="poppins-SemiBold text-center">Original Fee percentage</th>
                <th className="poppins-SemiBold text-center">Installation Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="poppins-Regular text-center">$600-$1199.8</td>
                <td className="poppins-Regular text-center">$500-%999</td>
                <td className="poppins-Regular text-center">8%</td>
                <td className="poppins-Regular text-center">6.80%</td>
                <td
                  rowSpan={4}
                  className="poppins-Regular text-center"
                  style={{ maxWidth: "50px" }}
                >
                  Heighly variable due to Materials type, excavations, digging
                  and other facility structure required
                </td>
              </tr>
              <tr>
                <td className="poppins-Regular text-center">$1200-$2398.8</td>
                <td className="poppins-Regular text-center">$1000-%1999</td>
                <td className="poppins-Regular text-center">7%</td>
                <td className="poppins-Regular text-center">5.95%%</td>
              </tr>
              <tr>
                <td className="poppins-Regular text-center">$2400-$5998.8</td>
                <td className="poppins-Regular text-center">$2000-%4999</td>
                <td className="poppins-Regular text-center">6%</td>
                <td className="poppins-Regular text-center">5.10%</td>
              </tr>
              <tr>
                <td className="poppins-Regular text-center">{">"}$6000</td>
                <td className="poppins-Regular text-center">{">"}$5000</td>
                <td className="poppins-Regular text-center">5%</td>
                <td className="poppins-Regular text-center">4.25%</td>
              </tr>
            </tbody>
          </Table>
          <Container
            fluid
            className={`m-0 p-0 px-3 mt-3 d-flex  justify-content-between ${
              isDesktopOrLaptop ? "flex-row " : "flex-column "
            }`}
          >
            <h6
              className="poppins-SemiBold portfolioSmallTitle blogCardText"
              style={
                isTabletOrMobile
                  ? { fontSize: "14px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              *conditions may apply
            </h6>
            <Button variant="dark" size="sm" onClick={()=>{
              window.open("https://cmsws.alleimran.com/propertyOwner/signup")
            }}>
              Submit Quick Contact
            </Button>
          </Container>
        </Row>
        <Row className={`m-0 p-0 mt-5  text ${isDesktopOrLaptop && "px-5"}`}>
          <h3
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Zero and Low Upfront Cost:{" "}
            <span className="orange"> The Smart Water Monitor</span> and
            <span className="orange"> The Smart Water Treatment</span>
            <sup>TM</sup>
          </h3>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            For the low to no upfront cost program, please contact our technical
            sales team at: acc@watersolutech.com. *Installation and shipping may
            be reduced
          </p>
        </Row>
      </Container>
    </MasterLayout>
  );
}

export default CostSaving;
