import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p58 from "../../assets/images/12. Case Studies - Hospitality - Hotels & Resorts.1366x768/58.png";
import p59 from "../../assets/images/12. Case Studies - Hospitality - Hotels & Resorts.1366x768/59.png";
import p60 from "../../assets/images/12. Case Studies - Hospitality - Hotels & Resorts.1366x768/60.png";
import p61 from "../../assets/images/12. Case Studies - Hospitality - Hotels & Resorts.1366x768/61.png";
import p62 from "../../assets/images/12. Case Studies - Hospitality - Hotels & Resorts.1366x768/62.png";
import p63 from "../../assets/images/12. Case Studies - Hospitality - Hotels & Resorts.1366x768/63.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { caseStudiesAlert } from "../../helper/HelperFunctions";

const Hospitality = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Hotels & Resorts | Multifamily Living | Senior Living Homes
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            // className={`d-flex flex-column  align-items-center m-0 p-0 `}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image fluid src={p58} alt="image" />
            <Button
              variant="dark"
              // style={{ marginLeft:isDesktopOrLaptop && "20px" }}
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              On average, hotels and resorts consume varying amounts of water
              depending on their size, facilities, and occupancy rates. However,
              studies suggest that Hotels and resorts typically consume around
              100-400 gallons of water per room per day. This includes water
              used for guest rooms, laundry, food service, landscaping, and
              other amenities.
            </p>
            <Link
              className="link poppins-SemiBold align-self-start "
              onClick={() => {}}
              style={{ textDecoration: "underline" }}
            >
              Learn more about water usages in hotels
            </Link>
            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Technology Adoption Benefits :
              </h3>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%){" "}
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Ability to claim 100% of available evaporation credits
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
        </Row>
        <Row className={`m-0 p-0 my-5 ${isDesktopOrLaptop && "px-5 "}`}>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p59} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Sandals] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p60} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [AD1 Globle] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p61} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [IHG] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p62} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Even Hotal] Case Studies
            </Link>
          </Col>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center "
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p63} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Pacifica] Case Studies
            </Link>
          </Col>
        </Row>
        <Row xs={"12"} className={"p-0 m-0 "}>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              To provide a general overview, let's consider the water usage per
              room per day as a common metric.
            </p>
            <dl>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Water Usage by Hotels and Resorts:
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Hotels and resorts typically consume around 100-400 gallons of
                water per room per day. This includes water used for guest
                rooms, laundry, food service, landscaping, and other amenities.
                Considering an average occupancy rate of 60%, a hotel with 100
                rooms would consume approximately 6.57 million gallons of water
                per year. Larger hotels or resorts with more rooms and higher
                occupancy rates would have correspondingly higher water usage.
              </dd>

              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Impact on Profitability:
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Water expenses constitute a significant portion of operational
                costs for hotels and resorts. Higher water consumption leads to
                increased water bills, impacting the bottom line. Reducing water
                usage through efficient practices can contribute to cost savings
                and improve profitability. Excessive water usage can lead to
                wear and tear on plumbing systems, requiring frequent
                maintenance and repairs. These additional expenses affect
                profitability and increase operational overhead. Sustainable
                practices, including responsible water management, are
                increasingly important to guests. Hotels and resorts that
                prioritize water conservation and efficiency may attract
                environmentally conscious guests, leading to positive brand
                image and potential revenue growth.
              </dd>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Impact on the Environment:
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Excessive water consumption by hotels and resorts places
                additional strain on local water resources, especially in
                regions prone to water scarcity or drought. It can exacerbate
                water stress in the surrounding community and impact the
                availability of water for other essential needs. Furthermore,
                water treatment and distribution require energy. Increased water
                usage indirectly contributes to higher energy consumption and
                associated carbon emissions, further impacting the environment.
                <br />
                <br />
                In destinations with fragile ecosystems, excessive water use can
                deplete local water sources, harm aquatic ecosystems, and
                disrupt the natural balance of water availability, affecting
                local flora and fauna
              </dd>
            </dl>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <dl>
              <dt
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Addressing the Impact:
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                To mitigate the impact of water usage on profitability and the
                environment, hotels and resorts can adopt several measures:
              </dd>
              <ol>
                <li
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  Water-Efficient Fixtures: Install water-saving devices such as
                  low-flow faucets, showerheads, and toilets to reduce
                  consumption without compromising guest comfort.
                </li>
                <li
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  Leak Detection and Repair: Implement regular maintenance
                  programs to identify and repair leaks promptly, minimizing
                  water wastage.
                </li>
                <li
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  Guest Education: Promote water conservation practices among
                  guests, such as reusing towels and taking shorter showers,
                  through signage, information cards, and communication.
                </li>
                <li
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  Water Recycling and Reuse: Implement systems to treat and
                  reuse water for non-potable purposes, such as landscaping or
                  toilet flushing.
                </li>
                <li
                  className="poppins-Regular"
                  style={isTabletOrMobile ? { fontSize: "14px" } : {}}
                >
                  Water Monitoring and Benchmarking: Regularly monitor water
                  usage, set targets for reduction, and compare performance
                  against industry benchmarks to identify areas for improvement.
                </li>
              </ol>

              <p
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                By implementing these strategies with Water Solution’s
                Technology proven strategy,, hotels and resorts can reduce their
                water footprint, decrease operational costs, enhance
                profitability, and contribute to the preservation of the
                environment and local water resources.
              </p>
            </dl>
            <Button
              variant="dark"
              // style={{ marginLeft:isDesktopOrLaptop && "20px" }}
              className=" my-3   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
        </Row>
        <Row xs={"12"} className={"p-0 m-0 "}>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-start m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle "
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "-12px" }
                  : { marginLeft: "0px" }
              }
            >
              Multifamily Living & Senior Living Homes
            </h3>
            <dl>
              <dt
                className="poppins-Regular my-3"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                Addressing the Impact:
              </dt>
              <dd
                className="poppins-Regular"
                style={isTabletOrMobile ? { fontSize: "14px" } : {}}
              >
                In apartment complexes that are high rise (2-3 stories high),
                relying on city water pressure without auxiliary pumps or
                holding tanks is common. By implementing the smart water
                management solutions strategies with Water Solution’s
                Technology, Multifamily and Senior Living Homes can reduce their
                water footprint, decrease operational costs, enhance
                profitability, and contribute to the preservation of the
                environment and local water resources.
              </dd>
              <Link
                className="link poppins-SemiBold"
                to={"/caseStudies/multifamily"}
              >
                READ MORE
              </Link>
            </dl>
          </Col>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default Hospitality;
