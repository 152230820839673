import React, { useEffect } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import p114 from "../../../assets/images/27. Latest In Property Water Management.1366x768/114.png";
import SideBlogComponent from "../../../components/blogs/SideBlogComponent";
import MasterLayout from "../../../layouts/MasterLayout";

const BlogDetail10 = () => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container
        fluid
        className={` m-0 ${isDesktopOrLaptop && "pageContainer"}`}
      >
        <Row xs={"12"} className="  m-0 p-0 mt-5 mb-3">
          <h1
            class="portfolioTitle poppins-SemiBold "
            style={
              isTabletOrMobile
                ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
                : {}
            }
          >
            Latest In Property Water Management
          </h1>
        </Row>
        <Row className="m-0 p-0">
          <Col
            lg={8}
            md={8}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              10 Steps to Advance Water Efficiency: Property Management
            </h3>
            <br />
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Facility managers have adopted water-saving practices, such as
              low-flow fixtures and faucets, to conserve water in their
              buildings. Despite these efforts, rising water utility rates
              across the country have led to increased costs for many. This is
              due to the need for water utilities to upgrade aging
              infrastructure. For example, in Chicago, water costs surged by 25%
              in 2012 and increased by 15% annually from 2013 to 2015. In
              response, facility managers are seeking innovative ways to improve
              water efficiency.
            </p>
            <Image
              fluid
              src={p114}
              alt="image"
              // style={{ height: "15%", width: "95%", objectFit: "cover" }}
            />
            <p
              className="poppins-Regular mt-1"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              To address this, we've outlined ten steps to enhance water
              management and reduce costs. The order in which these steps are
              implemented varies depending on facility type and water usage. For
              instance, the proportion of water used for HVAC systems differs
              significantly among facilities. Office buildings allocate 28% to
              HVAC, while restaurants use just 1%. Schools attribute 20% of
              water use to HVAC, and hotels, motels, and hospitals allocate 15%
              and 13% to heating and cooling, respectively.
            </p>
            <br />
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              1. Equipment
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              When dealing with equipment that's reached the end of its lifespan
              and consumes significant water quantities, the simplest course of
              action is replacement. James E. Dipping, the Vice President and
              Plumbing Studio Leader at Environmental Systems Design, recommends
              that if your facility houses a commercial kitchen or laundry, you
              should consider investing in efficient equipment designed to
              conserve both energy and water.
              <br />
              <br />
              Take water-cooled ice makers, for instance; they typically use
              around 100 gallons of water to cool the condenser for every 100
              pounds of ice they produce, in addition to 80 to 90 gallons used
              in the ice-making process. In contrast, Energy Star air-cooled
              machines don't waste this extra 100 gallons per ice batch.
              <br />
              <br />
              As Mark Burton, President of Technical Services, of Water
              Solutions Technology LLC, points out, if your ice machine is eight
              years old or more, it's likely responsible for substantial water
              wastage.
              <br />
              <br />
              In a similar vein, replacing old commercial dishwashers and
              laundry equipment with WaterSense products is an effective water
              conservation strategy for hospitals, hotels, motels, and
              restaurants.
              <br />
              <br />
              The U.S. Environmental Protection Agency's WaterSense program
              reports that upgrading from an older commercial dishwasher to an
              Energy Star model can lead to savings of over $200 on water bills,
              thanks to a reduction of 52,000 gallons in annual water usage,
              along with an additional $900 saved on energy costs.
              <br />
              <br />
              In the case of new construction and extensive renovations, Dipping
              suggests the incorporation of hot water recirculating systems.
              Dipping points out that presently, if the hot water source is more
              than 100 feet away from the fixture, it necessitates a
              recirculating system. When plumbing designers strictly adhere to
              this code, it could result in 100 feet of water stagnating within
              the piping. This situation leads to the unnecessary drainage of
              this water to achieve the desired temperature at the fixture. As a
              solution, Dipping favours placing recirculating systems as close
              to the fixture as possible.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              2. Leak Detection
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water leaks pose a significant challenge for facility managers in
              their efforts to oversee and regulate water consumption on their
              premises. According to data from New York City’s Department of
              Environmental Protection, even a minor toilet leak can lead to the
              wastage of 30 gallons of water per day, incurring a daily cost of
              forty cents. In the case of a moderate leak, amounting to 250
              gallons daily, the estimated daily expense would rise to $3.30. In
              situations where the fill valve remains open, a substantial leak
              can result in the squandering of 4,000 gallons of water daily,
              with a daily cost of $53. These statistics make it evident that
              overlooking even a single running toilet could negate a
              significant portion of the water conservation savings achieved.
              <br />
              <br />
              Jenny Carney, Vice President of Built Ecology/WSP, underlines the
              critical importance of leak detection when considering water
              costs. Effective leak detection demands ongoing monitoring, as
              highlighted by Clifford, who provides the example of a
              one-gallon-per-minute leak, which accumulates to a staggering
              1,140 gallons of water wasted each day. Clifford also shares the
              story of a hotel manager who was alarmed by a $12,000 water bill.
              During Clifford’s inspection, it was discovered that a segment of
              the hotel’s non-submetered irrigation system was damaged, leading
              to a whirlpool of water streaming into an eight-inch drain.
              Astonishingly, resolving this issue required a mere $4 investment
              for glue.” Our{" "}
              <span className="blue">
                Smart Water Monitor <sup>TM</sup> with WaterEye <sup>TM</sup>
              </span>
              would be ideal to detect leaks at the more granular scale down to
              half-gallon-per-minute.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              3. Training for Personnel and Building Occupants
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              To enhance leak detection efforts, facility managers should
              broaden their approach beyond just the maintenance team. The next
              step involves incorporating housekeeping and other personnel who
              can promptly notify building operators about plumbing leaks. This
              necessitates ensuring that all staff members are well-informed
              about whom to contact when a leak is observed.
              <br />
              <br />
              Building occupants can also be enlisted by placing a simple sign
              in areas like restrooms, instructing them to inform any employee
              or the front desk upon spotting a dripping faucet or a running
              toilet.
              <br />
              <br />
              As an example, one school district provided training to its
              janitorial staff and vice principals, instructing them to report
              all water leaks by dialing a specific number. According to
              Hoffman, in the first year of implementing this program, they
              managed to save $700,000 on utility bills.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              4. Metering and submetering
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water metering and submetering are effective methods for
              monitoring water usage and detecting leaks in both newly
              constructed and existing buildings. Hoffman highlights that both
              major building codes already include provisions for submetering.
              Ideally, water submetering should offer valuable insights into
              cooling tower, irrigation, and hot water consumption.
              <br />
              <br />
              Hoffman suggests that water metering is most efficient when
              seamlessly integrated into the building management system,
              allowing its data to be displayed on the facility operator's
              dashboard alongside other relevant data.
              <br />
              <br />
              While this may sound straightforward, Hoffman notes that several
              irrigation metering systems utilize proprietary protocols.
              Consequently, an interface may be necessary to communicate with
              systems like BACnet, leading to increased metering costs.
              <br />
              <br />
              Carney adds that at least one water metering company is promoting
              a smart meter that employs a wireless mesh open radio protocol, a
              technology widely used by Internet of Things manufacturers. These
              water submeters are battery-powered and transmit data to connected
              transceivers. Due to their cost-effective installation, wireless
              mesh smart water meters are a practical choice for both existing
              buildings and new constructions. In addition, our{" "}
              <span className="blue">
                {" "}
                Smart Water Valve <sup> TM</sup>{" "}
              </span>
              can generate a new-income to offset the capital investment
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              5. Water Audits
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Water audits are recognized as a crucial step in guiding facility
              managers on the most appropriate actions and their prioritization.
              It's important to note that, unlike energy audits, water audits
              currently lack standardization and a fixed protocol. Carney
              clarifies this by stating, "Energy audits adhere to standardized
              procedures, while water audits, although vital, are a relatively
              recent development without a universally accepted protocol.
              Nevertheless, various organizations are actively working to
              establish standardized practices for water audits."
              <br />
              <br />
              For the time being, Carney highlights a valuable resource for
              initiating water audits, the South Florida Water Management
              District's guide titled "Water Efficiency and Self-Conducted Water
              Audits at Commercial and Institutional Facilities: A Guide for
              Facility Managers." This guide serves as a useful starting point
              for facility managers looking to conduct water audits.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              6. Setting Sustainability Objectives through Comparison &
              Benchmarking
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Conducting a water audit plays a vital role in identifying water
              consumption patterns within a facility and allows for the
              establishment of sustainability benchmarks. For instance,
              Snohomish County in Washington State has committed to a 20 percent
              reduction in water usage by 2020.
              <br />
              <br />
              To effectively manage their 1.5 million square feet of facilities,
              the Office of Energy & Sustainability at Snohomish County opted
              for a software platform. This system automatically imports utility
              bills, subsequently gauging and comparing performance across their
              38 buildings. With the assistance of annual water benchmarking
              tools, the county can prioritize its retrofitting initiatives.
              According to the most recent Sustainable Operations Action Plan
              report, Snohomish County has successfully conserved over 2 million
              gallons of water.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              7. Maintenance of Cooling Towers
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              In commercial and institutional buildings exceeding 10,000 square
              feet, cooling towers are commonly integrated into the HVAC
              systems. According to data from the San Jose Environmental
              Services Department, the water consumed by HVAC systems in
              schools, hospitals, and office buildings amounts to 20 percent, 13
              percent, and 28 percent of their total water use, respectively.
              <br />
              <br />
              Facility managers must ensure the regular maintenance of cooling
              towers, focusing on cleanliness and minimizing scale build-up,
              advises Dipping (
              <span className="blue">
                visit our zero chemical, no maintenance smart scale buster{" "}
                <sup>TM</sup>
              </span>
              ).
              <br />
              <br />
              Regarding the operation of cooling towers and their associated
              piping systems, WaterSense at Work: Best Management Practices for
              Commercial and Institutional Facilities emphasizes the importance
              of avoiding leaks or overflows. However, these towers are equipped
              with an overflow drain as a precaution in the event of a
              malfunction leading to overflow. It's worth noting that most green
              building codes mandate the inclusion of overflow alarms.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              8. Enhancing Irrigation Systems
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Various options are at your disposal to enhance irrigation
              systems. For new installations, drip irrigation is a notably
              water-efficient choice compared to conventional sprinkler systems.
              Facility managers who don't want to overhaul their current systems
              can opt to upgrade their landscape irrigation using rain detection
              devices or soil moisture sensors.
              <br />
              <br />
              In the case of new installations, Dipping recommends the
              utilization of indigenous plant species, drip irrigation, and the
              inclusion of rain sensors. He advises integrating smart features
              into the irrigation system, allowing it to trigger an alert on the
              building automation system whenever the water meter detects flow
              during periods when the irrigation system should be inactive. An
              example of this approach is Texas Wesleyan University in Fort
              Worth, which is in the process of enhancing its landscape
              irrigation system by incorporating new intelligent controls that
              respond to weather conditions.
              <br />
              <br />
              Another instance is the City of Eden Prairie, Minn., which
              recognized that its watering needs for 23 parks, sports fields,
              and facilities were consuming 45 million gallons of water. The
              city made the decision to convert several athletic fields to an
              irrigation controller equipped with soil moisture sensors in 2008.
              By 2010, this measure led to an 8-million-gallon reduction in
              water use, resulting in savings of $29,000 for the city.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              9. Rainwater Collection
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Rainwater harvesting offers a sustainable alternative to relying
              solely on municipal water for irrigation and select indoor
              purposes. However, pursuing this option necessitates thorough
              research in coordination with local water, environmental, or
              development authorities.
              <br />
              <br />
              For existing buildings, implementing rainwater capture is a
              feasible undertaking, according to Dipping. He recommends
              primarily repurposing harvested rainwater for irrigation.
              <br />
              <br />
              In new construction projects, rainwater can also be employed for
              toilet flushing by incorporating the necessary piping during the
              construction phase, as highlighted by Dipping.
              <br />
              <br />
              As an illustration, in Portland, Oregon, facility managers can
              initiate rainwater harvesting without the need for a city building
              permit, as long as the collected rainwater is utilized for
              gravity-fed outdoor irrigation and the system operates
              independently of the municipal water supply. Additional
              requirements may include installing backflow prevention measures,
              securing permits for any electrical pumps installed, and obtaining
              a grading permit for underground pipe installation.
            </p>
            <h3
              className="poppins-SemiBold portfolioSubTitle"
              style={
                isTabletOrMobile
                  ? { fontSize: "16px", marginLeft: "0px" }
                  : { marginLeft: "0px" }
              }
            >
              10. Greywater and Reclaimed Water Utilization
            </h3>
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Greywater, as defined by California’s Uniform Plumbing Code,
              refers to untreated wastewater that has not been in contact with
              toilet waste. Greywater holds potential for applications like
              irrigation or non-potable building uses, including toilet and
              urinal flushing. However, the widespread adoption of greywater
              faces challenges due to the varying regulations imposed by state
              and local governments. These regulations define what qualifies as
              greywater and dictate its permissible uses.
              <br />
              <br />
              In regions where local authorities permit water recycling,
              reclaimed water is proving to be a valuable resource. An excellent
              example is Levi’s Stadium, home to the San Francisco 49ers, which
              is a versatile sports and entertainment complex that employs
              reclaimed water for both potable and non-potable purposes.
              Recycled water serves various roles, including landscape
              irrigation and flushing toilets and urinals.
              <br />
              <br />
              Rita Tatum, a contributing editor for Building Operating
              Management, brings over 30 years of experience in covering
              facility design and technology.
            </p>
          </Col>
          <Col
            lg={4}
            md={4}
            xs={12}
            className={`text d-flex flex-column align-items-start m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-4"
            }`}
          >
            <SideBlogComponent />
          </Col>
        </Row>
      </Container>
    </MasterLayout>
  );
};

export default BlogDetail10;
