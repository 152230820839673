import React from "react";
import { Card, Container, Image, Stack } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import { IoCaretForwardCircle } from "react-icons/io5";

const EventCard = ({ title, to }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <Card
      className="m-0 p-0"
      style={{
        border: "none",
        borderRadius: "0px",
        backgroundColor: "rgba(255,0,0,0)",
      }}
    >
      <Card.Body className="m-0 p-0">
        <iframe
          width={isDesktopOrLaptop ? "350px" : "310px"}
          height="230px"
          src={to}
        ></iframe>
        <Card.Title
          className="poppins-SemiBold portfolioTinyTitle "
          style={isTabletOrMobile ? { fontSize: "12px" } : {}}
        >
          {title}
        </Card.Title>
      </Card.Body>
    </Card>
  );
};
export default EventCard;
