import React from "react";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import MasterLayout from "../../layouts/MasterLayout";
import { useEffect } from "react";

const ScaleBusterFAQS = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <h1
          class="portfolioTitle poppins-SemiBold text-center"
          style={
            isTabletOrMobile
              ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
              : { marginLeft: "0px" }
          }
        >
          Frequently Asked Questions (FAQ)
        </h1>
        <Container fluid className={`m-0 p-0  mt-5 text ${isDesktopOrLaptop && 'px-5'}`}>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Does the ION ScaleBuster® comply with water regulations?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The manufacturers have taken every step to ensure that ION
            ScaleBuster® meets the most stringent codes of practice for
            materials and their use in potable water. The product is certified
            by WRAS in the UK, ACS in France, NSF in North America and the TUV
            GS for Europe and has been tested to a pressure of nearly 15,000
            PSI.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Will ION ScaleBuster® affect water pressure and flow rates?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The internal configuration of ION ScaleBuster® is designed to create
            turbulence. However, this causes minimum pressure drop and is
            unlikely to affect water flow.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Who can access the Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Access to the Smart Water Monitor TM with WaterEye TM portal is by
            invitation only. Please contact the system administrator at your
            enterprise for an invitation to join.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: How do I create an account in the Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Once the system administrator at your enterprise has added you as an
            authorized user, the system will send you an invitation via email to
            begin the account creation process.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: What if I've forgotten my username and/or password?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Your username will always be the email address you used to create
            your account. To reset your password, simply click the "Forgot your
            password?" link on the login page.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Is the Smart Water Monitor TM with WaterEye TM secure?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Yes, we have ensured security for your peace of mind. The Smart
            Water Monitor TM with WaterEye TM is hosted securely with Microsoft
            Azure, which also manages the user authentication (Microsoft Azure
            B2C), and Amazon AWS.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Where is the data stored? Is it secure?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Data collected by The Smart Water Monitor TM with WaterEye TM are
            stored and analyzed on Water Solutions’ Technology cloud server,
            which is hosted securely by Microsoft Azure/Amazon AWS.
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: Can I access the Portal from anywhere?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Yes, you can access the Portal from any internet-capable device from
            any internet-available location.
          </p>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: How much does it cost for me to access the Portal?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Subscription to Smart Water Monitor TM with WaterEye TM is managed
            at the enterprise level. Please contact your Water Solution's
            Technology representative for detailed pricing information.
          </p>
        </Container>
      </Container>
    </MasterLayout>
  );
};
export default ScaleBusterFAQS;
