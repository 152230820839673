import React from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Card } from "react-bootstrap";

const BlogCard = ({ imageUri, title, date, to }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  return (
    <Card
      className=" d-flex flex-column text align-items-center p-2 my-2"
      style={{
        width: isDesktopOrLaptop ? "20rem" : "9rem",
        backgroundColor: "rgba(255,0,0,0)",border:'none'
      }}
    >
      <Card.Subtitle
        className="mx-1 my-1 poppins-Regular text-muted align-self-start"
        style={{ fontSize: isDesktopOrLaptop ? "14px" : "12px" }}
      >
        {date}
      </Card.Subtitle>
      <Card.Link as={Link} to={to}>
        <Card.Img
          src={imageUri}
          style={{
            height: isTabletOrMobile ? "80px" : "170px",
            width: isTabletOrMobile ? "120px" : "300px",
            borderRadius: "0",
          }}
        />
      </Card.Link>
      <Card.Body className="p-0 m-0 my-1">
        <Card.Text
          className="poppins-SemiBold portfolioTinyTitle blogCardText"
          style={
            isTabletOrMobile
              ? {
                  fontSize: "12px",
                  marginLeft: "0px",
                  color: "#333333",
                  maxWidth: "120px",
                }
              : {
                  marginLeft: "0px",
                  maxWidth: "300px",
                  color: "#333333",
                }
          }
        >
          {title}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default BlogCard;
