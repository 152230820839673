import React from "react";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import MasterLayout from "../../layouts/MasterLayout";
import { useEffect } from "react";

const WaterSavingFAQS = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(()=>{
    scrollToTop()
  },[])
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <h1
          class="portfolioTitle poppins-SemiBold text-center"
          style={
            isTabletOrMobile
              ? { fontSize: "22px", marginTop: "4%", marginLeft: "0px" }
              : { marginLeft: "0px" }
          }
        >
          Frequently Asked Questions (FAQ)
        </h1>
        <Container fluid className={`m-0 p-0  mt-5 text ${isDesktopOrLaptop && 'px-5'}`}>
          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
           Q:  What is the Smart Water Savings Program?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The Smart Water Savings Program is a low to no upfront cost to implement the smart water technologies. An audit is conducted to identify ideal candidates and the appropriate technology is implemented to meet the expectations/objectives for the property. This is at low to no cost to you. 
          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
          Q: What technologies are provided with the smart water savings program?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            All our technologies can be provided, but this is primarily determined, by the water management strategy. A single, unit or combination can be used in each case. 

          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
           Q: What are the advantages of  the Smart Water Savings Program?

          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The main advantage is that it will allow you to focus on your primary operations, without any significant capital commitment. 

          </p>

          <h4
            className="poppins-SemiBold portfolioSmallTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "14px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            Q: How long is the term?
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
           You can cancel at any time, once we are duly notified under the conditions of the agreement
          </p>
        </Container>
      </Container>
    </MasterLayout>
  );
};
export default WaterSavingFAQS;
