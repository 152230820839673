import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import MasterLayout from "../../layouts/MasterLayout";

const PrivatePolicy = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        <h1
          class="homeTitle poppins-SemiBold text-center"
          style={
            isTabletOrMobile
              ? { fontSize: "16px", marginTop: "4%", marginLeft: "0px" }
              : { marginLeft: "0px" }
          }
        >
          **Privacy Policy for Water Solutions Technology and Affiliates**
        </h1>
        <Container
          fluid
          className={`m-0 p-0  mt-5 text ${isDesktopOrLaptop && "px-5"}`}
        >
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            1. Introduction**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Water Solutions Technology and its affiliates (referred to as "we,"
            "us," or "our") are committed to protecting your privacy and
            ensuring the security of your personal information. This Privacy
            Policy outlines how we collect, use, disclose, and protect your
            information when you visit our website and use our services related
            to water monitoring, water delivery/purification/treatment, and
            water consumption cost analysis (collectively referred to as
            "Services").
            <br />
            <br />
            By accessing or using our Services, you consent to the practices
            described in this Privacy Policy. Please take the time to read and
            understand this policy to make informed decisions regarding your
            personal information.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            2. Information We Collect**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            We may collect the following types of information:
            <br />
            <br />
            <b>
              <span className="mediumBlack">
                2.1. **Personal Information**:
              </span>
            </b>
            This includes information that can be used to identify you, such as
            your name, email address, phone number, and billing information.
            <br />
            <br />
            <b>
              <span className="mediumBlack">2.2. **Usage Information**:</span>
            </b>
            We collect data about how you interact with our website and
            Services, including your IP address, browser type, device
            information, and the pages you visit.
            <br />
            <br />
            <b>
              <span className="mediumBlack">
                2.3. **Location Information**:
              </span>
            </b>
            With your consent, we may collect information about your approximate
            location to provide location-based services.
            <br />
            <br />
            <b>
              <span className="mediumBlack">2.4. **Payment Information**:</span>
            </b>
            When you make a purchase, we collect payment information, such as
            credit card details or other payment methods.
            <br />
            <br />
            <b>
              <span className="mediumBlack">2.5. **User Content**: </span>
            </b>
            Any content, feedback, or information you voluntarily submit to us,
            such as reviews or comments.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            3. How We Use Your Information**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            We use your information for the following purposes:
            <br />
            <br />
            <b>
              <span className="mediumBlack">3.1. **Providing Services**:</span>
            </b>
            To provide, maintain, and improve our Services, including water
            monitoring, delivery, purification, treatment, and cost analysis.
            <br />
            <br />
            <b>
              <span className="mediumBlack">3.2. **Communication**: </span>
            </b>
            To communicate with you regarding our Services, including responding
            to inquiries, providing updates, and sending service-related
            notifications.
            <br />
            <br />
            <b>
              <span className="mediumBlack">3.3. **Marketing**:</span>
            </b>
            With your consent, we may send you promotional materials about our
            Services and affiliate products, but you can opt out at any time.
            <br />
            <br />
            <b>
              <span className="mediumBlack">3.4. **Analytics**: </span>
            </b>
            To analyze and monitor the usage of our website and Services to
            improve our offerings and user experience.
            <br />
            <br />
            <b>
              <span className="mediumBlack">3.5. **Security**: </span>
            </b>
            To protect the security and integrity of our website and Services,
            including detecting and preventing fraud or unauthorized access.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            4. Sharing Your Information**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            We may share your information under the following circumstances:
            <br />
            <br />
            <b>
              <span className="mediumBlack">4.1. **Affiliates**:</span>
            </b>
            We may share your information with our affiliates for the purpose of
            providing and improving our Services.
            <br />
            <br />
            <b>
              <span className="mediumBlack">4.2. **Service Providers**: </span>
            </b>
            We may engage third-party service providers to assist us with
            various aspects of our business, including payment processing,
            analytics, and marketing. These service providers will have access
            to your information as necessary to perform their functions.
            <br />
            <br />
            <b>
              <span className="mediumBlack">4.3. **Legal Compliance**: </span>
            </b>
            We may disclose your information when required by law or in response
            to valid legal requests, such as court orders or subpoenas.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            5. Your Choices**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            You have certain rights regarding your personal information:
            <br />
            <br />
            <b>
              <span className="mediumBlack">
                5.1. **Access and Correction**:
              </span>
            </b>
            You can request access to and correction of your personal
            information by contacting us.
            <br />
            <br />
            <b>
              <span className="mediumBlack">5.2. **Opt-Out**: </span>
            </b>
            You can opt out of receiving marketing communications from us by
            following the instructions provided in those communications.
            <br />
            <br />
            <b>
              <span className="mediumBlack">5.3. **Data Deletion**: </span>
            </b>
            You can request the deletion of your account and associated data by
            contacting us.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            6. Security**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            We take reasonable measures to protect your information from
            unauthorized access, use, or disclosure. However, no data
            transmission over the internet is entirely secure, so we cannot
            guarantee the absolute security of your information.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            7. Changes to this Privacy Policy**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or for other operational, legal, or
            regulatory reasons. We will notify you of any material changes by
            posting the updated Privacy Policy on our website.
          </p>

          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile
                ? { fontSize: "16px", marginLeft: "0px" }
                : { marginLeft: "0px" }
            }
          >
            8. Contact Us**
          </h4>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            If you have any questions or concerns about this Privacy Policy or
            our data practices, please contact us at [acc@watersolutech.com or
            acc@thesmartvalves.com].
            <br />
            <br />
            By using our Services, you acknowledge that you have read and
            understood this Privacy Policy and consent to the collection, use,
            and sharing of your information as described herein
          </p>
        </Container>
      </Container>
    </MasterLayout>
  );
};
export default PrivatePolicy;
