import React, { useEffect, useState } from "react";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import { GiCheckMark } from "react-icons/gi";
import { useMediaQuery } from "react-responsive";
import "../../assets/css/fontStyles.css";
import "../../assets/css/waterTech.css";
import p71 from "../../assets/images/16. Restaurants. 1366x768/71.png";
import p72 from "../../assets/images/16. Restaurants. 1366x768/72.png";
import QuickConnect from "../../components/quickConnect/QuickConnect";
import MasterLayout from "../../layouts/MasterLayout";
import { caseStudiesAlert } from "../../helper/HelperFunctions";
import { Link } from "react-router-dom";

const Restaurants = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <MasterLayout>
      <Container fluid className="pageContainer m-0">
        {/* <Row xs={"12"} className="m-0 p-0 ">
          <CaseStudiesNavbar />
        </Row> */}
        <Row xs={"12"} className="m-0 p-0 mt-4 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle "
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Restaurants{" "}
          </h4>
          <Col
            lg={6}
            sm={12}
            md={6}
            xs={12}
            className={`portfolio d-flex flex-column  align-items-center m-0 ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <Image fluid src={p71} alt="image" />
            <Button
              variant="dark"
              // style={{ marginLeft: "40px" }}
              className=" my-4   align-self-start"
              onClick={() => {
                handleShow();
              }}
            >
              Quick Connect
            </Button>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            xs={12}
            className={`portfolio d-flex flex-column align-items-center m-0  ${
              isDesktopOrLaptop ? "p-5" : "p-0"
            }`}
          >
            <p
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              The exact amount of water used in a restaurant can vary
              significantly depending on factors such as the size of the
              establishment, the number of seats, and the volume of customers
              served. Estimates for water usage in restaurants generally range
              from 5 to 15 gallons of water per seat per day. For example, a
              small restaurant with 50 seats may use anywhere from 250 to 750
              gallons of water per day.
            </p>

            <div className="smallText align-self-start justify-content-center align-items-start  p-3">
              <h3
                className="poppins-SemiBold portfolioSmallTitle "
                style={
                  isTabletOrMobile
                    ? { fontSize: "14px", marginLeft: "0px" }
                    : { marginLeft: "0px" }
                }
              >
                Technology Adoption Benefits :
              </h3>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Short payback period (12-24 months)*
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Fast ROI ({">"}80%)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  85% reduction in chemical for scale treatment
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on energy fee (equipment specific*)
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  25% Saving on water fee
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  6% Saving on consumption
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Ability to claim 100% of available evaporation credits
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Real-time water management
                </p>
              </div>
              <div className="m-0 p-0 d-flex flex-row ">
                <div>
                  <GiCheckMark
                    color="orange"
                    size={"14px"}
                    style={{ marginRight: "15px" }}
                    className="mb-2"
                  />
                </div>
                <p
                  className="poppins-Regular  "
                  style={isTabletOrMobile ? { fontSize: "12px" } : {}}
                >
                  Set and meet ESG gaols related to water and water linked
                  energy
                </p>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="m-0 p-0 ">
          <h4
            className="poppins-SemiBold portfolioSubTitle mt-5"
            style={
              isTabletOrMobile ? { fontSize: "16px", marginLeft: "-12px" } : {}
            }
          >
            Selected Case Studies (Water Meter Accuracy Adoption)
          </h4>
        </Row>
        <Row className={`m-0 p-0 my-5 ${isDesktopOrLaptop && "px-5 "}`}>
          <Col
            className="m-0 p-0  d-flex flex-column align-items-center"
            xs={6}
            md={6}
            lg={3}
          >
            <Image fluid src={p72} alt="image" />
            <Link
              onClick={caseStudiesAlert}
              className="poppins-SemiBold portfolioTinyTitle link my-0"
              style={isTabletOrMobile ? { fontSize: "12px" } : {}}
            >
              [Denny's Crop] Case Studies
            </Link>
          </Col>
        </Row>
        <Row xs={"12"} className={`${isDesktopOrLaptop && "px-5"}  m-0 text`}>
          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            Water usage in restaurants can vary depending on the size of the
            establishment, the type of cuisine, and the specific operations
            involved. Here are some common areas where water is used in
            restaurants:
          </p>
          <ol className="text px-4  ">
            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Food Preparation: Water is used for various food preparation
              activities, including washing fruits and vegetables, cleaning meat
              and seafood, and rinsing cooking utensils and equipment.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Cooking and Kitchen Operations: Water is essential for cooking
              processes such as boiling, steaming, and blanching. It is also
              used for filling pots, pans, and steamers. Additionally, water is
              needed for cleaning kitchen surfaces, countertops, and cutting
              boards.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Dishwashing: Restaurants require water for washing dishes,
              utensils, glassware, and cooking equipment. This includes both
              manual washing and the use of dishwashing machines.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Cleaning and Sanitation: Water is used for general cleaning and
              sanitizing purposes throughout the restaurant, including wiping
              tables, mopping floors, and cleaning restrooms.
            </li>
            <br />

            <li
              className="poppins-Regular"
              style={isTabletOrMobile ? { fontSize: "14px" } : {}}
            >
              Beverage Service: Water is utilized for various beverage-related
              activities, such as filling glasses, brewing coffee and tea, and
              ice production.
            </li>
          </ol>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The exact amount of water used in a restaurant can vary
            significantly depending on factors such as the size of the
            establishment, the number of seats, and the volume of customers
            served. Estimates for water usage in restaurants generally range
            from 5 to 15 gallons of water per seat per day. For example, a small
            restaurant with 50 seats may use anywhere from 250 to 750 gallons of
            water per day.
          </p>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            It's important to note that water conservation measures, such as the
            use of low-flow faucets, aerators, and efficient dishwashing
            equipment, can help reduce water consumption in restaurants.
            Additionally, regular maintenance and leak detection can help
            identify and address any water wastage issues.
          </p>

          <p
            className="poppins-Regular"
            style={isTabletOrMobile ? { fontSize: "14px" } : {}}
          >
            The actual water usage in a restaurant will depend on several
            factors specific to that establishment. Implementing water-saving
            practices and technologies can help restaurants reduce their water
            consumption and promote sustainability in their operations.
          </p>
        </Row>

        <QuickConnect isVisible={show} hideModel={handleClose} />
      </Container>
    </MasterLayout>
  );
};

export default Restaurants;
